$baseUrl: "/";
@import 'theme';
.container {
    &:global(.Toastify__toast-container--top-center),
    &:global(.Toastify__toast-container--bottom-center) {
        max-width: max-content;
        width: 100%;
    }
}

.Notification {
    pointer-events: auto;
    box-shadow:
        0 0 0 1px rgba(16, 22, 26, 0.1),
        0 2px 4px rgba(16, 22, 26, 0.2),
        0 8px 24px rgba(16, 22, 26, 0.2);
    border-radius: 3px;
    line-height: 16px;
    min-height: auto;
    padding: $sru-grid * 1.5;
    font-size: 100%;
}

:global(.Toastify__toast) {
    color: $sru-white;
}

:global(.Toastify__toast--success) {
    background: $sru-salem;
}
:global(.Toastify__toast--warning) {
    background: $sru-brandy_punch;
}
:global(.Toastify__toast--error) {
    background: $sru-punch;
}

$toastify-info: #3498db;

:global(.Toastify__toast--info) {
    background: $toastify-info;
}
:global(.Toastify__close-button) {
    color: $sru-white;
}

.progress {
    height: 2px;
    background-color: rgba(255, 255, 255, 0.3);
}

.body {
    padding-right: $sru-grid * 1.5;
    min-width: 300px;
    max-width: 500px;
}
