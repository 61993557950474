$baseUrl: "/";
@import '_utils';

.layout {
  display: grid;
  grid-template-columns: 5fr 4fr;
  grid-template-areas: "form player" "timeline timeline";
  grid-gap: $grid * 4;
}

.player {
  grid-area: player;
}

.form {
  grid-area: form;
}

.timeline {
  grid-area: timeline;
}
