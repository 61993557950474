$baseUrl: "/";
@import "_utils";
@include block(VideoSearch) {
    padding: 15px 20px 30px;
    background: $color_background-grey;
    &__container {
      display: flex;
    }
    & &__button {
        line-height: 10px;
        left: -1px;
        margin-right: 10px;
    }
    &__order button {
        width: 32px;
        height: 32px;
    }
    &__button-disable-next-video {
        margin-left: auto;
    }
}
@include block(NextVideoFiltersButton) {
    margin-left: 10px;
}
