$baseUrl: "/";
@import '_utils';
@import '_config';

.content {
    width: 600px;
    min-height: 200px;
    height: calc(80vh - 274px);
    max-height: 300px;
}

.dropzone {
    height: 100%;
    margin-bottom: $grid;
}

.checkboxContainer {
    padding-top: 0;
    display: flex;
    align-items: center;
    font-size: $font_size_regular;
}

.checkbox {
    input {
        margin-right: 10px;
    }
}


.options{
  padding-top: 0;  
  padding-bottom: $grid * 3 ;  
}
