$baseUrl: "/";
@import "_config";
@import "_utils";

@include block(stream-preview) {
    display: inline-block;
    position: absolute;
    left: 0; bottom: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s;
    z-index: 105;

    & .jw-controls,
    & .jw-controls-backdrop,
    & .svp-age-limit-label {
        display: none;
    }

    @include element(player) {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    @include modifier(state-paused) {
        & .jw-preview {
            display: none;
        }
    }

    @include element(error) {
        fill: red;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.8;
        color: $color_white;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    @include modifier(state-load_error) {
        background: rgba(0, 0, 0, 0.4);

        .svp-player-error {
            display: none;
        }

        & + .af_asset-preview__indicator {
            display: none;
        }
    }

    @include element(preview) {
        display: grid;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        z-index: 108;
        position: absolute;
        top: 0;
        left: 0;
    }
}
