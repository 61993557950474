$baseUrl: "/";
@import 'theme';

.overlay {
    overflow-y: auto;
    background-color: rgba(16, 22, 26, 0.7);
    width: 100%;
    position: fixed;
    top: 0;
    height: 100vh;
    display: grid;
    z-index: 101;
}

.dialog {
    background: $sru-white;
    padding: 0;
    border-radius: $sru-grid * 0.5;
    margin: $sru-grid * 2 auto;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 4px 8px rgba(16, 22, 26, 0.2), 0 18px 46px 6px rgba(16, 22, 26, 0.2);
    align-self: center;
    max-width: 600px;
    height: max-content;
    width: 100%;
}

.heading {
    font-weight: 600;
    line-height: 28px;
    color: $sru-ui-w100;
    font-size: 23px;
    &::first-letter {
        text-transform: uppercase;
    }
}

.section {
    padding: $sru-grid * 4;

    &:first-child {
        border-radius: $sru-grid * 0.5 $sru-grid * 0.5 0 0;
    }
    &:last-child {
        border-radius: 0 0 $sru-grid * 0.5 $sru-grid * 0.5;
    }

    &--darker {
        background: $sru-catskill-white;
    }
    &--flexRight {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
    }
    &--flexRight > * {
        margin-left: $sru-grid * 2;
    }
}
