$baseUrl: "/";
@import '_utils';

.container {
    margin-bottom: $grid * 2;
}

.list {
    display: flex;
    gap: $grid;
    flex-wrap: wrap;
}

.loader {
    border: 1px dashed $color_mystic2;
    border-radius: $grid;
    background-color: $color_alabaster;
    aspect-ratio: 1;
    min-height: 110px;
    display: grid;
    justify-content: center;
    align-content: center;
}
