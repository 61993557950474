$baseUrl: "/";
@import '_config';

.container {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas: 'label info' 'input input';

    &:hover {
        .hideContainer {
            visibility: visible;
        }
    }
}

.hideContainer {
    visibility: hidden;
    grid-area: info;
    padding-left: $grid;
    font-size: 13px;
    justify-self: end;
    cursor: pointer;

    &:hover {
        color: #1c67b3;
    }
}
.children {
    grid-area: input;
}
