$baseUrl: "/";
@import '_config';
@import '_utils';

.video-card-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: $grid * 0.5;
}
