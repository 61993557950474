$baseUrl: "/";
@import "_config";

.container {
    background-color: $color-geyser;
    display: grid;
    grid-row-gap: 1px;
}

.item {
    position: relative;
    background-color: $color_background-grey;
    display: grid;
    grid-template-columns: 30% auto max-content;
    align-items: baseline;
    justify-content: space-between;
    padding: $grid 0;

    a:hover {
        color: $color_medium-blue;
    }

    &.loading .buttons {
        opacity: 0;
    }
}

.spinner {
    text-align: right;
    margin-right: 24px;
}
