$baseUrl: "/";
@import "_config";

.list {
    display: block;
    margin-bottom: $grid;
}

.add {
    font-weight: $font-weight_semibold;
    color: $color_dark-grey;
    font-size: $font-size_medium;

    &:hover {
        color: lighten($color_dark-grey, 8%);
        cursor: pointer;
    }
}

.add:hover .addText {
    text-decoration: underline;
}
