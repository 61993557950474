@import "_config";

/// Extract the base block name (with prefix) and it's pseudo classes for a given selector.
///
/// @param {String} $selector - selector for which to extract the block class name
/// @return {(String, String)} the resulting base block class name and it's pseudo classes
///
/// @example
///   getBlockName(".p_block")
///   getBlockName(".p_block .p_block__element")
///   getBlockName(".p_block .p_block__element--e-modifier")
///   getBlockName(".p_block.p_block--modifier")
///   getBlockName(".p_block.p_block--modifier .p_block__element")
///   getBlockName(".p_block.p_block--modifier .p_block__element--e-modifier")
///   // .p_block
///
/// @example
///   getBlockName(".p_block:hover")
///   getBlockName(".p_block:hover .p_block__element")
///   getBlockName(".p_block:hover .p_block__element--e-modifier")
///   getBlockName(".p_block.p_block--modifier:hover")
///   getBlockName(".p_block:hover.p_block--modifier")
///   // (.p_block, :hover)
@function getBlockName($selector) {
    $max: str-length($selector);
    // first occurence of a modifier name (or default to high value)
    $modifier: str-index($selector, "--") or $max + 1;
    // first occurence of a descendant selector
    $descendant: str-index($selector, " ") or $max + 1;
    // first occurence of a class selector (for the block modifier)
    $dot: str-index(str-slice($selector, 2), ".") or $max;
    // first occurence of a pseudo class
    $pseudo: str-index($selector, ":") or $max + 1;
    // choose a delimiter that occurs first in the selector
    // keep in mind $dot is not decreased since the value was found in a shorter, sliced substring
    $end: min($modifier - 1, $descendant - 1, $dot, $pseudo - 1, $max);
    // find the end for the pseudo classes
    $potential: ($modifier, $descendant, $dot);
    $pseudoEnd: $max;
    @for $i from 1 through length($potential) {
        $value: nth($potential, $i);
        $pseudoEnd: if($value > $pseudo and $value < $pseudoEnd, $value, $pseudoEnd);
    }

    $class: str-slice($selector, 1, $end);
    $pseudoClasses: str-slice($selector, $pseudo, $pseudoEnd);

    $result: ($class, $pseudoClasses);

    @return $result;
}

/// Extract the block's modifier (with prefix) for a given selector.
///
/// @param {String} $selector - selector for which to extract the modifier
/// @return {String} the resulting block modifier
///
/// @example
///   getBlockModifier(".p_block")
///   getBlockModifier(".p_block .p_block__element")
///   getBlockModifier(".p_block .p_block__element--e-modifier")
///   // null
///
/// @example
///   getBlockModifier(".p_block.p_block--modifier")
///   getBlockModifier(".p_block.p_block--modifier .p_block__element")
///   getBlockModifier(".p_block.p_block--modifier .p_block__element--e-modifier")
///   // --modifier
@function getBlockModifier($selector) {
    $max: str-length($selector);
    // first occurence of a modifier name
    $modifier: str-index($selector, "--");
    // first occurence of a descendant selector
    $space: str-index($selector, " ") or $max + 1;
    // first occurence of a pseudo class
    $pseudo: str-index($selector, ":") or $max + 1;
    // trim the selector to represent no more than the block-related class names
    // $end: $space and $space - 1 or str-length($selector);
    $end: min($space - 1, $pseudo - 1, $max);

    // if a modifier exists, and it is the block's modifier (element's modifier would be > $end)
    // then slice out the modifier's name
    $result: $modifier and $modifier < $end and str-slice($selector, $modifier, $end);

    @return $result;
}

/// Extract the element's name (with prefix) for a given selector.
///
/// @param {String} $selector - selector for which to extract the element's name
/// @return {String} the resulting element name
///
/// @example
///   getElementName(".p_block")
///   getElementName(".p_block.p_block--modifier")
///   // null
///
/// @example
///   getElementName(".p_block .p_block__element")
///   getElementName(".p_block .p_block__element--e-modifier")
///   getElementName(".p_block.p_block--modifier .p_block__element")
///   getElementName(".p_block.p_block--modifier .p_block__element--e-modifier")
///   // __element
@function getElementName($selector) {
    // first occurence of an element's name
    $element: str-index($selector, "__");
    // if an element name has been found, then find it's length
    // (either till the begining of the element's modifier, or the end of the selector)
    $end: $element and str-index(str-slice($selector, $element), ".") or str-length($selector);

    // if an element name has been found, slice it out
    $result: $element and str-slice($selector, $element, $element + $end - 2);

    @return $result;
}

/// Generate a set of rules with a BEM block name.
///
/// @require $global-prefix requires a defined global variable with a vendor prefix
/// @content
///
/// @param {String} $name - block name
/// @output set of rules for a BEM block
///
/// @example
///   @include block(block-name) { ... }
///   // .p_block-name { ... }
///
/// @example
///   @include block(block-name) { &:hover { ... } }
///   // .p_block-name:hover { ... }
@mixin block($name) {
    $selector: #{$global-prefix}_#{$name};

    @at-root .#{$selector} {
        @content;
    }
}

/// Generate a proper selector for a given BEM element name
///
/// @content
///
/// @param {String} $name - element name
/// @param {Bool} $atRoot - whether the generate selector should omit the block ancestor (useful in some cases)
/// @output set of rules for a BEM element
///
/// @example
///   @include block(block) {
///     @include element(element-a) { ... }
///     @include modifier(modifier) {
///       @include element(element-b { ... }
///     }
///   }
///   // .p_block .p_block__element-a { ... }
///   // .p_block.p_block--modifier .p_block__element-b { ... }
@mixin element($name, $atRoot: false) {
    $path: #{&};

    $block: getBlockName($path);
    $blockName: nth($block, 1);
    $blockPseudo: nth($block, 2);
    $modifier: getBlockModifier($path);
    // full block modifier class
    $combined: $modifier and "#{$blockName}#{$modifier}";

    $ancestor: #{$blockName}#{$combined}#{$blockPseudo};
    $element: "#{$blockName}__#{$name}";
    // if the element selector should be generated without the block ancestor, ommit it
    $selector: if($atRoot, $element, "#{$ancestor} #{$element}");

    @at-root #{$selector} {
        @content;
    }
}

/// Generate a set of rules for a BEM block or element with a modifier. Accepts multiple modifiers.
/// It generates selectors that contain both the base class, as well as the base with the modifier name appended
/// in order to provide a mechanism to counter rules from pseudo-classes overriding rules from modifiers.
///
/// @content
///
/// @param {String} $name - modifier name
/// @output set of rules for a BEM block or element with a modifier
///
/// @example
///   @include block(block) { ...
///     @include element(element-a) { ...
///       @include modifier(modifier-a) { ... }
///       @include modifier(a b) { ... }
///     }
///     @include modifier(c d) { ... }
///     @include modifier(modifier) { ...gg
///       @include element(element-b) { ...
///         @include modifier(modifier-b) { ... }
///       }
///     }
///   }
/// // .p_block { ... }
/// // .p_block .p_block__element-a { ... }
/// // .p_block .p_block__element-a.p_block__element-a--modifier-a { ... }
/// // .p_block .p_block__element-a.p_block__element-a--a.p_block__element-a--b { ... }
/// // .p_block.p_block--c.p_block--d { ... }
/// // .p_block.p_block--modifier { ... }
/// // .p_block.p_block--modifier .p_block__element-b { ... }
/// // .p_block.p_block--modifier .p_block__element-b.p_block__element-b--modifier-b { ... }
@mixin modifier($modifiers) {
    $path: #{&};

    $block: getBlockName($path);
    $blockName: nth($block, 1);
    $blockPseudo: nth($block, 2);
    $modifier: getBlockModifier($path);
    $element: getElementName($path);
    // full element's class
    $combined: #{$blockName}#{$element};

    // generate multiple modifier class selectors
    $names: "";
    @each $name in $modifiers {
        $new: if($element,
            #{$combined}--#{$name},
            #{$blockName}--#{$name}
        );
        $names: "#{$names}#{$new}";
    }

    $selector: if($element,
        /* when dealing with an element  */
        #{$blockName}#{$modifier and "#{$blockName}#{$modifier}"}#{$blockPseudo} #{$combined}#{$names},
        /* when dealing with a block */
        #{$blockName}#{$names}#{$blockPseudo}
    );

    @at-root #{$selector} {
        @content;
    }
}
