$baseUrl: "/";
.container {
    display: grid;
    align-items: end;
    grid-area: adsPlacement;
}

.migrateButton {
    button:not(:last-of-type) {
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
    }
}
