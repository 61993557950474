$baseUrl: "/";
@import '_utils';

.playlist {
    font-size: 13px;
    border: 0;
}
.container {
    position: relative;
    &--loading {
        pointer-events: none;
    }
    &:hover {
        background-color: $color_alabaster2;
        .playlist {
            background-color: $color_alabaster2;
        }
    }
}

.container:hover {
    padding-right: 110px;
    .overlay {
        visibility: visible;
        pointer-events: auto;
    }
}

.overlay {
    background-color: $color_white;
    transition: background-color 0.2s ease-out;
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    right: 10px;
    top: 45px;
}
