$baseUrl: "/";
@import '_utils';

.warning {
    background-color: $color_sunflower;

    &:hover {
        background-color: $sru-ronchi;
    }
}
