$baseUrl: "/";
@import '_config';
@import '_utils';

.container {
    grid-area: menu;
    display: grid;
    align-items: center;
}

.burger {
    padding: 14px 16px;
    svg {
        transform: scale(1.9);
    }
    @include media('>tablet') {
        display: none;
    }
}

.links {
    @include media('<=tablet') {
        box-shadow: 0px 0px 3px $color_black;
        padding-top: $grid * 3;
        background-color: $color_dark-grey;
        position: fixed;
        width: 200px;
        z-index: 5;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        transition: transform 200ms linear, opacity 200ms linear;
        transform: translateX(100%);
        will-change: transform, opacity;
    }
    @include media('>tablet') {
        padding-left: $grid;
        transform: none;
    }

    a {
        text-align: center;
        box-sizing: content-box;
        padding: $grid;
        display: block;
        text-transform: uppercase;
        line-height: $grid * 3;
        font-size: $font-size_big;
        font-weight: $font-weight_semibold;
        height: $grid * 3;
        color: $color_white;
        @include media('>tablet') {
            padding: 0 $grid * 2;
            height: $grid * 3;
            color: $color_black;
            display: inline-block;
            border-radius: $grid * 0.25;
        }
    }
}

.links--visible {
    @include media('<=tablet') {
        transform: translateX(0%);
        opacity: 1;
    }
}

a.link--active {
    text-decoration: none;
    color: $color_white;
    background: $color_dark-blue;
}
