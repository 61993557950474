$baseUrl: "/";
@import '_utils';

.items {
    padding: 20px 0;
}

.waveform {
    grid-area: primary;
    z-index: 10;
}
