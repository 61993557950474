$baseUrl: "/";
@import '_config';

.form {
    display: grid;
    grid-template-columns: minmax(auto, 700px);
    grid-row-gap: $grid * 4;
}

.button {
    margin-top: $grid * 2;
    justify-self: end;
}
