$baseUrl: "/";
@import '_config';
.value {
    white-space: pre-wrap;
    word-break: break-all;
    display: block;
    position: relative;
    margin-right: 20px;
    .wrap {
        border-bottom: 1px dashed transparent;
        display: inline-block;
        line-height: 20px;
    }
    svg {
        margin: 0 0 0 4px;
        color: transparent;
    }
    input {
        position: absolute;
        background: transparent;
        top: 0;
        left: 0;
        width: 100%;
        min-width: 30px;
        word-spacing: 0;
    }
    &--editing {
        display: inline-block;
        input {
            white-space: nowrap;
            top: 2px;
        }
        .wrap {
            border-bottom: 1px dashed $color_dark-grey;
            height: 21px;
            min-width: 10px;
            line-height: 17px;
        }
        .row {
            visibility: hidden;
            display: block;
            overflow: hidden;
            height: 18px;
        }
        .pencil-icon {
            position: absolute;
            top: 2px;
            right: -20px;
            color: $color_dark-grey;
        }
        &.value--error .pencil-icon {
            color: $color_dark-red;
        }
        &.value--error .wrap {
            border-color: $color_dark-red;
        }
    }
    &--highlighted {
        border: 2px solid $color_yellow;
        padding: 3px;
    }
    &:hover:not(&--editing) {
        .row {
            border-bottom: 1px dashed $color_silver2;
        }
        .pencil-icon {
            color: $color_silver2;
            position: relative;
            top: -1px;
        }
    }
    .checkmark {
        display: none;
        color: $color_salem;
        position: relative;
        top: -2px;
    }
    &--success .checkmark {
        display: inline;
        animation-fill-mode: forwards;
        animation-name: disappear;
        animation-duration: 200ms;
        animation-delay: 500ms;
    }
}

@keyframes disappear {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
