$baseUrl: "/";
@import '_config';

.container {
    display: grid;
    grid-auto-flow: row;
    align-items: center;
    gap: $grid * 2;
}

.inputsRow {
    display: grid;
    grid-template-columns: 1fr 1fr max-content;
    gap: $grid;
}

.addButton {
    justify-self: right;
}

.noProperties {
    padding: $grid;
    background: $color_alabaster;
    border: 1px solid $color_mercury;
    text-align: center;
}

.triggerButtonContainer {
    display: flex;
    align-items: center;
    gap: $grid * 2;
}

.metadataNumber {
    font-size: $font-size_medium;
    font-weight: $font-weight_regular;
}
