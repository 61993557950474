$baseUrl: "/";
@import "_config";

.container {
    font-size: $font-size_small;
    color: $color-tundora;
    position: relative;
    display: grid;
    align-items: center;
    grid-template: {
        areas: "heading caption" "progress progress";
        columns: 1fr max-content;
    }
    grid-column-gap: $grid * 2;
}
