$baseUrl: "/";
@import '_utils';

.items {
    --items-background-color: #{$color_ui-c10};

    width: 100%;
    box-sizing: content-box;
    grid-area: primary;
    position: relative;
    background-size: 0 auto, auto;
    background-repeat: no-repeat, no-repeat;
    background-image:
        linear-gradient(0deg, var(--items-background-color) 0%, var(--items-background-color) 100%),
        repeating-linear-gradient(-45deg,
            $color_concrete,
            $color_concrete 10px,
            $color_alabaster2 10px,
            $color_alabaster2 20px,
        );
}
