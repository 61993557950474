$baseUrl: "/";
@import 'theme';
.Connector--row {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    & > *:not(:last-child),
    & > *:not(:last-child) input {
        margin-right: 0px;
    }
    & > *:not(:last-child):not(:first-child),
    & > *:not(:last-child):not(:first-child) input {
        border-radius: 0;
    }
    & > *:last-child:not(:first-child),
    & > *:last-child:not(:first-child) input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    & > *:first-child:not(:last-child),
    & > *:first-child:not(:last-child) input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.Connector--column {
    display: grid;
    width: max-content;
    & > *:not(:last-child),
    & > *:not(:last-child) input {
        margin-bottom: 0px;
    }
    & > *:not(:last-child):not(:first-child),
    & > *:not(:last-child):not(:first-child) input {
        border-radius: 0;
    }
    & > *:last-child:not(:first-child),
    & > *:last-child:not(:first-child) input {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    & > *:first-child:not(:last-child),
    & > *:first-child:not(:last-child) input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}
