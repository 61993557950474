$baseUrl: "/";
@import "_config";

.container {
    display: flex;
    justify-content: space-between;
    gap: $grid * 4;
    grid-column: 1 / -1;
    margin: -$grid (-$grid * 1.5) ($grid * 4);
    padding: $grid * 4;
    background-color: $color_catskill-white;

    h1 {
        margin-bottom: $grid * 3;
    }
}

.podcastForm {
    min-width: 160px;
}

.podcastControls {
    display: grid;
    grid-auto-flow: row;
    row-gap: $grid * 3;
}

.checkboxContainer {
    > p {
        margin: 5px 0 0 33px;
    }
}
.checkboxInput {
    font-size: $font-size_big;
    font-weight: $font-weight_semibold;
    color: $color_ui-w90;
    text-transform: uppercase;
    input {
        margin-right: 15px;
    }
}

.podmeContainer {
    display: flex;
    align-items: center;
    gap: .6rem;

    label {
        margin: 0;
    }

    .spinner {
        width: 15px;
    }
}

.submitButton {
    align-self: center;
    justify-self: end;
}

.playerSettings {
    min-width: 230px;
}

.playerSettingsInfo {
    margin-top: $grid * 3;
    margin-left: 2px;
}