$baseUrl: "/";
@import '_utils';

@mixin countdown {
    top: 92px;
    bottom: unset;
    left: unset;
    width: 170px;
    font-size: $font-size_large;
    height: 40px;
    border-left: 2px solid $color_ui-c30;
    display: flex;
    justify-content: center;
    align-items: center;
    > span span:first-child {
        letter-spacing: 0;
        font-size: $font-size_small;
        font-weight: bold;
        margin-bottom: -10px;
        text-shadow: none;
        color: $color_dark-grey;
    }
    > span span:last-child {
        color: $color_bluey-purple;
        letter-spacing: 0;
        text-shadow: none;
        font-weight: bold;
    }
}

.radio {
    color: $color_ui-w90;
    margin-bottom: $grid * 4;
}

.countdownToStart {
    @include countdown;
    top: 92px;
}

.countdownToEnd {
    @include countdown;
    top: 0;
    left: 168px;
    position: relative;
    margin-top: -37px;
}

.dateTime {
    margin-top: 20px;
}

.dateTimeFlightTimesEnd {
    min-height: 25px;
}

.flightTimesEndHeader {
    margin-top: 5px;
}

.binIcon {
    margin-left: -31px;
    margin-bottom: -92px;
    height: 20px;
    width: 20px;

    cursor: pointer;
    color: $color-dove-gray2;

    &:hover {
        color: $color_ui-w90;
    }
}
