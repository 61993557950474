$baseUrl: "/";
@import 'theme';
.initials {
    border-radius: 50%;
    background-color: $sru-powder-blue;
    color: $sru-white;
    width: $sru-grid * 4;
    height: $sru-grid * 4;
    font-size: 14px;
    line-height: $sru-grid * 4;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    &--small {
        font-size: 18px;
        width: $sru-grid * 5;
        height: $sru-grid * 5;
        line-height: $sru-grid * 5;
    }
    &--big {
        font-size: 24px;
        width: $sru-grid * 6;
        height: $sru-grid * 6;
        line-height: $sru-grid * 6;
    }
}
