$baseUrl: "/";
@import "_utils";

.inputSelector {
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: $grid * 2;
}

.backupEncoderCheckbox {
    margin-top: $grid * 2;
}
