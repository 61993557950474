$baseUrl: "/";
@import '_utils';

.movableVideoCard {
    margin: $grid;
    background-color: $color_white;
    cursor: move;
    pointer-events: all;
    border: 1px dashed transparent;
    transition:
        background-color 0.2s ease-out,
        border-color 0.2s linear;

    &:hover {
        border-color: $color-geyser;
        background-color: $color_ui-c10;
    }

    &:hover .overlay {
        background-color: $color_ui-c10;
        visibility: visible;
        pointer-events: auto;
    }
}

.preview,
.dragged {
    opacity: 0.5;
}
.obsolete {
    opacity: 0;
}
.overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $color_white;
    width: 100%;
    height: 100%;
    transition: background-color 0.2s ease-out;
    visibility: hidden;
    pointer-events: none;
}

.dragged {
    &:hover {
        border-color: transparent;
        background-color: $color_white;
    }

    &:hover &__overlay {
        display: none;
    }
}
