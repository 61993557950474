$baseUrl: "/";
@import "_config";
@import "_utils";
@include block(AssetsList) {
    padding: $grid * 4 $grid * 2 0;
    background-color: $color_white;

    &__header {
        display: grid;
        padding: $grid $grid * 2;
        margin-bottom: $grid * 4;
        background: $color_background-grey;
        grid-template-columns: 1fr auto auto auto;
        grid-column-gap: $grid * 2;
    }
    &__header--blank {
        background: $color_white;
        padding: 0;
    }
}
