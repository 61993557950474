$baseUrl: "/";
@import '_config';

.container {
    display: flex;
    flex-direction: column;

    @include media('>1200px') {
        flex-direction: row;
    }
}

.checkbox {
    margin-top: 4px;
    margin-bottom: 4px;
}
