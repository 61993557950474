$baseUrl: "/";
@import "_config";
@import "_utils";

.header {
    display: grid;
    grid-template-columns: minmax(300px, 1fr) auto 1fr;
    grid-column-gap: $grid * 4;
}

.heading {
    grid-column: span 3;
    margin-bottom: $grid * 3;
}

.dialog-select {
    margin-bottom: 200px;
}

.spinner {
    margin: 0 auto;
    display: block;
}

.error {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-auto-flow: column;
    grid-gap: $grid * 2;
}
