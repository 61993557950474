$baseUrl: "/";
.backupEncoder {
    transform: translate(0px, -24px);
}

.dropdownArrow {
    display: none;
}

.networkInfoDialogTrigger {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}
