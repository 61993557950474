$baseUrl: "/";
@import '_config';

.listItem {
    border-bottom: 1px solid $color-geyser;
}

.header {
    display: grid;
    grid-column-gap: $grid * 2;
    grid-template-columns: minmax(160px, 0.8fr) 1.2fr 140px ($grid * 4);
    padding: $grid * 0.5 0;
    line-height: 16px;
    cursor: auto;

    .headerDate {
        display: flex;
        align-items: center;
        font-weight: $font-weight_semibold;
    }

    .headerUser {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .headerArrow {
        color: $color_medium-grey;
        cursor: pointer;
    }
}

.content {
    display: grid;
    grid-column-gap: $grid * 2;
    grid-template-columns: minmax(160px, 0.8fr) 1.2fr 140px ($grid * 4);
    padding: $grid * 2 0;
}

.actionButtons {
    padding-right: $grid * 2;
    border-right: 1px solid $color-geyser;
}

.changesList {
    display: grid;
    grid-row-gap: $grid * 2;
    grid-column: 2 / 5;
}
