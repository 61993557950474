// http://chir.ag/projects/name-that-color
$color_catskill-white: #f3f7f9;
$color-geyser: #c9d9e0;
$color_black: #000;
$color_white: #fff;
$color_red: #f73b11;
$color_dark-red: #aa1729;
$color_guardsman-red: #cc0000;
$color_pomegranate: #e00000;
$color_punch: #db3737;
$color_red-violet: #cd0890;
$color_wisp-pink: #fce6e6;
$color_green: #689f38;
$color_salem: #0f9960;
$color_crete: #467a2c;
$color_yellow: #f0ad4e;
$color-powder-blue: #b4e3ea;
$color_brandy_punch: #d9822b;
$color_sunflower: #e8c931;
$sru-ronchi: #f0d447;
$color_buddha_gold: #bd9d00;
$sru-sahara: #bfa31a;
$color_dark-grey: #2a373d;
$color_dark-blue: #005c7a;
$color_dusty-gray: #979797;
$color_concrete: #f3f3f3;
$color_medium-blue: #007da3;
$color_mid-blue: #1c67b3;
$color_light-blue: #51c9e1;
$color_background-grey: #f3f5f5;
$color_background-dark-grey: #e7eae8;
$color_baltic_sea: #323033;
$color_light-grey: #c8d9e0;
$color_medium-grey: #607d8b;
$color_cloudy-blue: #b0c4cd;
$color_placeholder-grey: #aaaaaa;
$color_silver: #e4eaeb;
$color_silver2: #bdbdbd;
$color_mercury: #e6e6e6;
$color_greyish-brown: #4d4949;
$color_warm-grey: #757575;
$color_jagger: #29105c;
$color_daisy-bush: #421a91;
$color_bluey-purple: #673ab7;
$color_purple-heart: #673ab8;
$color_purple-navigation: #412160;
$color_iris: #7e57c2;
$color_amethyst: #9575cd;
$color_teal-80: #2ca6c2;
$color_teal: #4cbdd4;
$color_ui-c10: $color_catskill-white;
$color_ui-c30: #c7d8df;
$color_ui-w40: #e0e0e0;
$color_ui-w80: #616161;
$color_ui-w90: #424242;
$color_ui-w100: #212121;
$color-tamarillo: $color_dark-red;
$color-aqua-haze: #f4f6f9;
$color-black-haze: #f0f1f1;
$color-caper: #dcebb2;
$color-deep-cerulean: $color_medium-blue;
$color-dove-gray: #707070;
$color-dove-gray2: #646464;
$color-geyser: $color_ui-c30;
$color_wild-sand: #f5f5f5;
$color-gray: #8c8c8c;
$color-gray-nurse: #f0f2f0;
$color-mine-shaft: $color_ui-w100;
$color-orient: $color_dark-blue;
$color-outer-space: $color_dark-grey;
$color-regent-st-blue: #b2e3eb;
$color-scooter: $color_teal-80;
$color-tundora: $color_ui-w90;
$color-white: $color_white;
$color-boulder: $color_warm-grey;
$color_gull-gray: #90a4ae;
$color_mystic: #dbe6eb;
$color_mystic2: #dee7ee;
$color_gallery: #eeeeee;
$color_alabaster: #fafafa;
$color_alabaster2: #f8f8f8;
$color_gray-nurse: #e7ece6;
$color_fun-blue: #1d68b3;
$color_silver-chalice: #9e9e9e;
$color_catskill-white: #f3f7fa;
$color_alto: #e0dede;
$color_orinoco: #e3fbd7;
$color_corn-field: #fafbd7;
$color-black-alpha05: rgba(0, 0, 0, 0.5);
$color-white-alpha06: rgba(255, 255, 255, 0.6);
$color-white-alpha95: rgba(255, 255, 255, 0.95);
$color-gray-alpha70: rgb(131, 131, 131, 70%);
$color_jungle-mist: #bccfd7;
$color_scorpion: #4d4d4d;
$color_iron: #dcdddd;
$color_atlantis: #91ca48;
$color_deep_cerulean: #0088a3;
$color_botticelli: #c5d9e1;
$color_teal_blue: #054c5e;
$color_cornflower_blue: #d6e3e9;
$color-lavender: #A284E0;
