$baseUrl: "/";
@import '_utils';

.container {
    grid-area: adsPlacement;
}

.containerInner {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: $grid * 2;

    &--progress {
        grid-template-columns: auto max-content;
    }
}

.abortButtonContainer {
    justify-self: left;
}
