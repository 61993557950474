$baseUrl: "/";
@import 'theme';
.Spinner {
    vertical-align: middle;
    &--large {
        width: 80px;
    }
    &--big {
        width: 30px;
    }
    &--small {
        width: 25px;
    }
    &--compact {
        width: 20px;
    }
    &--compact {
    }
    &--folding path {
        opacity: 0.1;
    }
    path#{&}__item--dimmed {
        opacity: 0.1;
        fill: #49268b;
        stroke: #49268b;
    }
    path#{&}__item--dark {
        opacity: 1;
        stroke: #49268b;
        fill: #49268b;
    }
    path#{&}__item--normal {
        opacity: 1;
        stroke: #673ab7;
        fill: #673ab7;
    }
    &--light path#{&}__item--normal {
        stroke: #d6d6d6;
        fill: #d6d6d6;
    }
    &--light path#{&}__item--dark {
        stroke: #ffffff;
        fill: #ffffff;
        opacity: 0.8;
    }
    &--light path#{&}__item--dimmed {
        stroke: #ffffff;
        fill: #ffffff;
        opacity: 0.1;
    }
    &--spinning {
        animation: rotate 1s infinite steps(1, start);
    }
    &-label {
        margin-left: 5px;
        vertical-align: middle;
    }
}

@keyframes rotate {
    17% {
        transform: rotate(60deg);
    }
    34% {
        transform: rotate(120deg);
    }
    51% {
        transform: rotate(180deg);
    }
    68% {
        transform: rotate(240deg);
    }
    85% {
        transform: rotate(300deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
