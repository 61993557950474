$baseUrl: "/";
@import '_config';
@import '_functions';

.playlistCard {
    position: relative;
    font-size: $font-size_medium;
    margin-right: $grid;
    border: 0;
    box-shadow: 0 0 2px 1px $color_gallery;

    height: pxToEm(163);
    padding-top: 15px;
    padding-bottom: 5px;
}

.removeButton {
    display: none;

    @at-root .playlistCard:hover & {
        display: block;
    }
}

.addButtonTop,
.addButtonBottom {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
}
.addButtonTop {
    top: -2 * $grid;
}
.addButtonBottom {
    bottom: -2 * $grid;
}
