$baseUrl: "/";
@import '_config';

.container {
    display: grid;
    grid-template-columns: minmax(auto, 700px);
    margin-top: $grid * 3;
}

.createButton {
    color: $color_fun-blue;
    font-size: 14px;
    line-height: 18px;
    justify-self: end;
    margin-top: $grid;
}
