$baseUrl: "/";
@import '_utils';

.wrapper {
    font-size: $font-size_small;
    display: grid;
    gap: $grid * 0.5 $grid;
    grid-template-columns: min-content auto;
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
        "cpl-title cpl-value"
        "cps-title cps-value"
        "nol-title nol-value";

    dd {
        text-align: right;
        font-weight: $font-weight_semibold;
    }
}

.title {
    &::after {
        content: ":";
    }
    &.error {
        color: $color_red;
        font-weight: $font-weight_bold;
    }
}
