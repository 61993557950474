$baseUrl: "/";
@import "_utils";

.container {
    display: grid;
    grid-template-columns: 1.6fr 1.4fr;
    grid-gap: $grid * 3;

    input[readonly] {
        color: $color_warm-grey;
        background-color: $color_wild-sand;
    }
}

.streamKeyIcon {
    color: $color_alto;
    font-size: $font-size_big;
    font-weight: $font-weight_regular;
    &:hover {
        color: $color-gray;
        cursor: pointer;
    }
    svg {
        width: 20px;
        height: 20px;
    }
}
