$baseUrl: "/";
@import '_utils';

.wrapper {
    @include media('>=desktop') {
        display: grid;
        grid-template-areas:
            'livingPreview'
            'livingThumbnail'
            'posters';
    }
}

.greybox {
    grid-area: livingThumbnail;
    background-color: $color_background-grey;
    margin-bottom: $grid * 2;
    padding: $grid * 2;
    padding-bottom: 4px;

    @include media('>=desktop') {
        display: grid;
        grid-column-gap: $grid * 2;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 'suggestions frameSelector';
    }
}

.info{
    padding-top: 4px;
}

.uploader {
    grid-area: uploader;

    @include media('<desktop') {
        margin-top: $grid * 2;
    }
}

.poster {
    grid-area: poster;
}

.suggestions,
.frameSelector {
    @include media('<desktop') {
        margin-bottom: $grid * 4;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.suggestions {
    grid-area: suggestions;
}

.label{
    grid-area: label;
    margin-bottom: $grid;
}

.posters{
    grid-area: posters;

}

.posterContainer{
    display: grid;
    grid-column-gap: $grid * 2;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, max-content);
    grid-template-areas:
        'label label'
        'poster uploader';

    margin-bottom: 3.5 * $grid;
}
