$baseUrl: "/";
@import "_config";

.list {
    display: block;

    li {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: baseline;
        border-bottom: 1px solid $color-geyser;
        padding: $grid 0;
        max-width: 500px;
    }
}
