$baseUrl: "/";
@import '_utils';

.container {
    background-color: $color_alabaster;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $grid * 4;
    padding-top: 9vh;

    height: 100vh;
}

.streamLogo {
    width: 70px;
}

.caption {
    font-size: $font-size_super-large;
    font-weight: 600;
    padding-top: $grid * 4;
    padding-bottom: $grid * 0.5;
}

.instruction {
    font-size: $font-size_big;
    padding-bottom: $grid * 4;
}

.logIn {
    width: 100%;
}

.providers {
    min-width: 320px;
}

.enter {
    opacity: 0;
    margin-left: -300px;
}

.enterActive {
    opacity: 1;
    margin-left: 0;

    transition: all 0.4s;
}

.exit {
    opacity: 1;
    padding-left: 0;
}

.exitActive {
    opacity: 0;
    transition: all 0.4s;
    padding-left: 300px;
}
