$baseUrl: "/";
@import "_utils";

.advancedToggleButton {
  padding: 0 !important;
  margin: 0 0 #{-$grid} #{$grid};
  justify-self: start;
  border-width: 0 !important;
  color: inherit !important;
  font-size: $font-size_big;
  font-weight: $font-weight_regular;

  &::before {
      content: "❮";
      margin-right: $grid;
      padding: 0 2px;
      vertical-align: 3px;
      font-size: 0.9rem;
      display: inline-block;
      transform: rotate(270deg);
      transition: transform 0.2s;
  }

  &--expanded {
      color: $color_bluey-purple;
  }

  &--expanded::before {
      transform: rotate(90deg);
  }
}