@mixin positionAbsolute($top: 0, $right: 0, $bottom: 0, $left: 0) {
    position: absolute;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

@mixin flexboxCenter() {
    display: flex;
    align-items: center;
    justify-content: center;
}
@mixin spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
