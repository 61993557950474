$baseUrl: "/";
@import "_config";

.lead {
    font-size: 15px;
    margin: $grid * 4 0 $grid * 3;
}

.list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    grid-template-rows: repeat(4, 1fr);
    grid-auto-flow: column;
}
