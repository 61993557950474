$baseUrl: "/";
@import '_utils';

@keyframes gradient {
    0% {
        background-position: 200% 50%
    }
    100% {
        background-position: -100% 50%
    }
}

.progressBar {
    margin: $grid 0;
    height: 0.6em;
    width: 100%;
    background-color: $color_jungle-mist;
    border-radius: 0.6em;
    overflow: hidden;
}

.progressBarInner {
    width: 0;
    height: 100%;
    transition: width 1000ms linear;
    background: linear-gradient(-45deg, $color_bluey-purple 0%, $color_amethyst 10%, $color_bluey-purple 20%);
    background-size: 300% 300%;
    animation: gradient 5s ease infinite;
    animation-timing-function: linear;
}

.progressLabel {
    font-weight: $font-weight_semibold;
}
