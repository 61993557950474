$baseUrl: "/";
@import "_config";
@import "_utils";

@include block(asset-preview) {
    position: relative;
    width: 100%;
    line-height: 0;

    @include element(image) {
        position: absolute;
        width: 100%;
    }

    @include element(progress) {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0; left: 0;
        z-index: 100;
    }

    @include element(indicator) {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        height: $grid * 0.5;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.6);
        z-index: 110;
    }

    @include element(indicator-progress) {
        display: block;
        height: 100%;
        background-color: $color_medium-blue;
    }
}
