$baseUrl: "/";
@import '_config';

.assetScreen {
    padding: 0;
}

.spinner {
    display: grid;
    justify-items: center;
    align-items: center;
}
