$baseUrl: "/";
@import '_config';

.container {
    display: grid;
    grid-template-columns: 30px minmax(200px, auto) minmax(100px, auto);
    grid-template-rows: 20px auto;
    grid-template-areas: 'icon level date' 'icon message date';

    background-color: #e8ecec;
    padding: 10px 20px;
}

.icon {
    grid-area: icon;
    align-self: center;
    transform: scale(1.5);

    &--info {
        color: $color_crete;
    }

    &--alert {
        color: $color_buddha_gold;
    }

    &--error {
        color: $color_guardsman-red;
    }
}

.level {
    grid-area: level;
    letter-spacing: 0.7px;

    color: $color_guardsman-red;
    font-size: $font-size_primary;
    justify-self: start;

    text-transform: uppercase;

    &--info {
        color: $color_crete;
    }

    &--alert {
        color: $color_buddha_gold;
    }

    &--error {
        color: $color_guardsman-red;
    }
}

.message {
    grid-area: message;
    letter-spacing: 1px;
    color: $color_ui-w90;
    font-size: $font-size_medium;

    justify-self: start;
    text-align: start;
    align-self: center;
}

.date {
    grid-area: date;

    align-self: center;
    justify-self: end;
    font-weight: 600;
    font-size: $font-size_medium;
}
