$baseUrl: "/";
@import '_config';

:export {
    white: $color_white;
}

.container {
    display: grid;
    grid-template-columns: minmax(auto, 1436px);
}

.spinner {
    display: flex;
    margin: 150px auto 0 auto;
    justify-content: center;
}

.header {
    margin-bottom: 45px;
    display: grid;
    grid-template-columns: auto 180px;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 55px;
    grid-row-gap: 15px;
    padding-right: 20px;
}
.heading {
    grid-area: 1 / 1 / 2/ 3;
}

.searchIcon {
    opacity: 0.15;
}
