$baseUrl: "/";
@import '_utils';

.list {
    display: flex;
    flex-direction: column;
    overflow: auto;
    min-height: 350px;
    max-height: calc(70vh - 210px);
    padding: $grid * 1.5 0;
    scroll-behavior: smooth;
}
