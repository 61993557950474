$baseUrl: "/";
@import '_config';

.container {
    grid-area: categories;
    padding-bottom: $grid * 3;
}

.mainCategory {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas: 'label info' 'select select';

    p {
        grid-area: info;
        padding-left: $grid;
        font-size: 12px;
        text-transform: uppercase;
    }
    div {
        grid-area: select;
    }
}

.subcategories {
    padding: $grid 0;

    &:nth-child(2) {
        padding-top: $grid * 3;
    }
}

.categories {
    grid-area: select;
}
