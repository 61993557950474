$baseUrl: "/";
@import "_config";
@import "_utils";

.table {
  width: 100%;
  text-align: left;

  td {
    font-weight: $font-weight_semibold;
    vertical-align: middle;
  }



  th {
    font-weight: $font-weight_semibold;
    padding: $grid;
  }

  th,
  td {
    padding: $grid * 2 $grid;
  }

  .child-row {
    padding-left: $grid * 5;
  }

  tr {
    border-bottom: 1px solid $color_mystic;
  }

  tbody tr:hover {
    cursor: pointer;
    background: $color_alabaster2;
  }

  thead tr {
    padding-top: 0;
  }

  tr.disabled {
    color: $color_silver-chalice;
    opacity: 0.5;
  }
}

.dir-icon {
  color: $color_ui-c30;
  width: 30px;
  height: 30px;
}

.title-cell {
  font-size: $font-size_big;
}

.thumbnail-cell {
  width: 55px;
}


.thumbnail,
.no-image-icon {
  vertical-align: middle;
  width: 55px;
  height: 30px;
  object-fit: cover;
}

.no-image-icon {
  border: 1px solid $color_silver;
  display: inline-block;
  text-align: center;
  line-height: 32px;

  .icon {
    vertical-align: text-top !important;
    color: $color_silver2;
  }
}
