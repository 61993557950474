$baseUrl: "/";
@import "_utils";

.container {
    display: grid;
    grid-template-columns: 1.6fr 1.4fr;
    grid-template-rows: min-content auto;
    grid-gap: $grid * 3;
    background-color: $color_catskill-white;
    padding: $grid * 2;
    margin-bottom: -26px;
    min-height: 180px;

    &-one-column {
        grid-template-columns: 1fr;
    }

    input[readonly] {
        color: $color_warm-grey;
        background-color: $color_wild-sand;
    }
}

.sourceTypeSelector {
    grid-column: 1 / -1;
    grid-row: 1;
    display: grid;
    grid-template-columns: max-content auto;
    grid-row-gap: 4px;
    grid-column-gap: $grid;

    :not(:first-child) {
        grid-row-start: 2;
        font-weight: $font-weight_semibold;
    }
}

.clearIcon {
    color: $color_alto;
    font-size: $font-size_big;
    font-weight: $font-weight_regular;
    &:hover {
        color: $color-gray;
        cursor: pointer;
    }
    svg {
        width: 20px;
        height: 20px;
    }
}
