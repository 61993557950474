$baseUrl: "/";
@import '_utils';

.container {
    display: grid;
    grid-template-rows: 70px 1fr;
    grid-template-areas: 'player' 'toolbar';
    row-gap: $grid;

    border: 1px solid #E3EBEF;
    background-color: $color-white;
    padding: $grid;
}

.player {
    grid-area: player;
    aspect-ratio: 16 / 9;

    > video {
        margin: 0 auto;
        max-width: 124.44px;
        max-height: 70px;
    }
}

.toolbar {
    grid-area: toolbar;
    display: flex;
    gap: $grid;
    padding: 0 $grid * 0.5;
    font-size: $font-size_medium;
}

.actions {
    margin-left: auto;
    display: flex;
    gap: $grid * 0.5;
}

.durationIcon {
    vertical-align: bottom;
}

.actionIcon {
    scale: 120%;
    cursor: pointer;

    &:hover {
        color: #A284E0;
    }
}
