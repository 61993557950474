$baseUrl: "/";
@import "_utils";

.container {
    display: grid;
    grid-gap: #{$grid * 2};
}

.inputPreview {
    width: 129px;
    height: 73px;
    background-color: $color_catskill-white;
    box-sizing: content-box;
    padding: 5px;
}

.startTime {
    transition: max-height 0.3s linear;
    max-height: 100px;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-column-gap: $grid * 2;

    &--collapsed {
        max-height: 0;
    }
}
