$baseUrl: "/";
@import '_config';

.checkboxContainer {
    display: flex;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 2px;
}

.checkbox {
    margin: 0;
}

.infoIcon {
    margin-left: $grid;
    color: $color_silver2;
    transform: scale(1.3);
}
