$baseUrl: "/";
@import '_config';
@import '_utils';

.Countdown {
    font-size: 23px;
    color: $color_white;
    text-shadow: 0px 1px 2px $color_black;
    top: 0;
    bottom: 0;
    font-weight: $font-weight_semibold;
    left: 0;
    right: 0;
    margin: auto;
    width: max-content;
    height: max-content;
    position: absolute;
    &__inner {
        text-align: center;
        display: block;
        letter-spacing: 1.5px;
    }
    &__label {
        color: $color_white;
        width: auto;
        margin-bottom: 10px;
        letter-spacing: $grid * 0.25;
    }
}
