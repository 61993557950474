$baseUrl: "/";
@import '_utils';

.item {
    --resize-area-size: 9px;

    position: absolute;
    height: 60px;
    z-index: 15;
    user-select: none;
    overflow: hidden;
    box-sizing: border-box;

    & > * {
        pointer-events: none;
    }

    &:hover {
        cursor: grab;
    }

    &::before,
    &::after {
        width: var(--resize-area-size);
        height: 100%;
        content: "";
        display: block;
        position: absolute;
        top: 0;
        cursor: col-resize;
    }

    &::before {
        left: 0;
    }

    &::after {
        right: 0;
    }
}
