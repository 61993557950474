$baseUrl: "/";
@import "_config";
@import '_utils';
.af_ScreenContainer {
    padding: $grid * 9 $grid * 4;
    background-color: $color_white;
    box-shadow: 0 0 2px 2px $color_silver;
}

.af_ScreenContainer + .af_ScreenContainer {
    margin-top: $grid * 2;
}

.af_ScreenContainer__separator {
    margin: $grid * 6 $grid * -4;
}
