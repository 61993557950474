$baseUrl: "/";
@import '_config';

.image {
    min-width: 500px;
    min-height: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.aspectRatio {
    margin: 0 auto 0 0;
}

.ratioButton {
    height: 2em;
    padding: 4px 12px;
}

.ratioButton:hover {
    z-index: 1;
    cursor: pointer;
}

.ratioButton:first-child {
    margin-right: -1px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.ratioButton:last-child {
    margin-left: -1px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
