$baseUrl: "/";
@import 'theme';
.LabeledContainer {
    display: grid;
    grid-template-columns: auto;
    align-content: start;
    .Label--default {
        margin-bottom: $sru-grid * 2 - 4;
    }
}

.Label {
    margin-bottom: $sru-grid * 2 - 4;
}

.LabeledContainer--horizontal {
    grid-template-columns: max-content auto;
    .Label {
        justify-self: right;
        display: grid;
        align-content: center;
        margin-right: $sru-grid;
        margin-bottom: 0;
        &--big {
            height: $sru-grid * 6;
        }
        &--small {
            height: $sru-grid * 4;
        }
        &--compact {
            height: $sru-grid * 3;
        }
    }
}
