$baseUrl: "/";
@import "_utils";

@include block(HighlightForm) {
    display: grid;
    grid-gap: $grid;
    align-items: start;
    margin-bottom: $grid;
    max-width: 100%;

    grid-template-columns: 80px repeat(3, max-content);

    &__title-input {
        grid-column: 1 / -2;
        grid-row: 2;
        min-width: 200px;
    }

    &__title-button {
        grid-column: auto / span -1;
        grid-row: 2;
    }

    @include media(">=1400px") {
        grid-template-columns: 80px max-content minmax(auto, 300px) max-content;

        &__title-input {
            grid-column: auto;
            grid-row: auto;
        }

        &__title-button {
            grid-column: auto;
            grid-row: auto;
        }
    }

    &--error > :nth-child(n + 5) {
        grid-row: span 1;
        grid-column: 1 / -1;
    }
}
