$baseUrl: "/";
@import '_utils';

.container {
    margin: -8px -12px 32px;
    padding: $grid * 4;
    background-color: $color_catskill-white;
    grid-column: 1 / -1;
    display: grid;
    grid-gap: $grid * 4;
    grid-template-columns: 416px 0 auto min-content;
    grid-template-areas: 'adsPlacement separator liveRuntime submitButton';
    position: relative;

    &::before {
        grid-area: separator;
        content: '';
        display: block;
        border-left: 1px solid #dee8ec;
    }

    @include media('<desktop') {
        grid-row-gap: $grid * 2;
        grid-template-columns: auto min-content;
        grid-template-areas:
            'liveRuntime liveRuntime'
            'adsPlacement submitButton';

        &::before {
            display: none;
        }
    }
}

.submitButton {
    grid-area: submitButton;
    align-self: end;
    justify-self: end;
}

.loadingButton {
    width: 200px;
}
