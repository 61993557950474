$baseUrl: "/";
@import '_config';

.container {
    border-bottom: 1px solid $color-geyser;
}

.content {
    padding-bottom: $grid * 2;
    padding: ($grid * 4.5) ($grid * 2);
    background-color: $color_background-grey;
}

.properties {
    padding-top: $grid;
}

.doubleCol {
    display: grid;
    grid-column-gap: $grid * 2;
    grid-template-columns: 1fr 1fr;
}
