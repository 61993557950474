$baseUrl: "/";
@import '_utils';

.videoCard {
    min-width: 400px;
    padding: $grid * 2;
    position: relative;
}

.controls {
    min-width: 75px;
    display: flex;
    justify-content: flex-end;
}

.controlButtons {
    display: none;
    gap: $grid;

    @at-root .videoCard & {
        margin: 0;
    }
    @at-root .videoCard:hover & {
        display: flex;
    }
}

.pin {
    margin-right: $grid;

    @at-root .videoCard:hover & {
        display: none;
    }
}

.addButtonTop,
.addButtonBottom {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    > span {
        display: block;
    }
}
.addButtonTop {
    top: -2 * $grid;
}
.addButtonBottom {
    bottom: -2 * $grid;
}

.category {
    font-style: italic;
    padding-left: $grid;
    color: $color_scorpion;
}

.newsroom {
    font-weight: 700;
    color: $color_scorpion;
}
