$baseUrl: "/";
@import "_utils";

.container {
    width: inherit;
    height: inherit;
}

.spinner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image {
    display: block;
    max-width: 100%;
}
