$baseUrl: "/";
@import '_config';

.rule {
    display: inline-block;
    background: $color_corn-field;
    padding: $grid * 0.5 $grid $grid * 0.5 28px;
    margin: $grid * 0.5 0;
    border-radius: 12px;
    font-size: 16px;
    line-height: 18px;
    position: relative;

    &::before {
        content: '';
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 8px;
        background-color: $color-white;
        background-repeat: no-repeat;
        background-image: url('data:image/svg+xml,%3Csvg width="16" height="16" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M8 16c-4.4183 0-8-3.5817-8-8s3.5817-8 8-8 8 3.5817 8 8-3.5817 8-8 8zM8 3c-.5523 0-1 .4477-1 1v5c0 .5523.4477 1 1 1s1-.4477 1-1V4c0-.5523-.4477-1-1-1zm0 8c-.5523 0-1 .4477-1 1s.4477 1 1 1 1-.4477 1-1-.4477-1-1-1z" fill="%23E8C931" fill-rule="evenodd"/%3E%3C/svg%3E');
        position: absolute;
        left: 5px;
        top: 5px;
    }

    &::first-letter {
        text-transform: uppercase;
    }

    &--valid {
        background-color: $color_orinoco;
    }

    &--critical {
        background-color: $color_wisp-pink;
    }

    &--valid::before {
        background-image: url('data:image/svg+xml,%3Csvg width="16" height="16" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M8 16c-4.4183 0-8-3.5817-8-8s3.5817-8 8-8 8 3.5817 8 8-3.5817 8-8 8zm3.2477-12.5188l-3.059 5.9222L4.398 7.129a.923.923 0 1 0-.9498 1.583l4.6152 2.7692a.923.923 0 0 0 1.2881-.3547l3.5167-6.7613a.923.923 0 0 0-1.6206-.884z" fill="%23467A2C" fill-rule="evenodd"/%3E%3C/svg%3E');
    }

    &--critical::before {
        background-image: url('data:image/svg+xml,%3Csvg width="16" height="16" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M8 16c-4.4183 0-8-3.5817-8-8s3.5817-8 8-8 8 3.5817 8 8-3.5817 8-8 8zM8 3c-.5523 0-1 .4477-1 1v5c0 .5523.4477 1 1 1s1-.4477 1-1V4c0-.5523-.4477-1-1-1zm0 8c-.5523 0-1 .4477-1 1s.4477 1 1 1 1-.4477 1-1-.4477-1-1-1z" fill="%23cc0000" fill-rule="evenodd"/%3E%3C/svg%3E');
    }
}
