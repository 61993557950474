$baseUrl: "/";
@import '_utils';

$timeline-size: 1px;

.ruler {
    --timeline-color: #{$color_silver-chalice};
    --timeline-background-color: #{$color_white};

    height: 25px;
    grid-area: ruler;
    position: relative;
    background-color: var(--timeline-background-color);
    background-position-y: 100%;
    background-repeat: repeat-x;
    background-image:
        linear-gradient(90deg, var(--timeline-color) 0px, var(--timeline-color) $timeline-size, transparent $timeline-size),
        linear-gradient(var(--timeline-background-color) 40%, transparent 40%),
        linear-gradient(90deg,
            transparent 20%,
            var(--timeline-color) 20%,
            var(--timeline-color) calc(20% + $timeline-size),
            transparent calc(20% + $timeline-size),
            transparent 40%, var(--timeline-color) 40%,
            var(--timeline-color) calc(40% + $timeline-size),
            transparent calc(40% + $timeline-size),
            transparent 60%,
            var(--timeline-color) 60%,
            var(--timeline-color) calc(60% + $timeline-size),
            transparent calc(60% + $timeline-size),
            transparent 80%,
            var(--timeline-color) 80%,
            var(--timeline-color) calc(80% + $timeline-size),
            transparent calc(80% + $timeline-size)
        );
}

.units {
    grid-area: ruler;
    position: relative;
}

.unit {
    color: var(--timeline-color);
    font-size: 12px;
    position: absolute;
    content-visibility: auto;
    transform: translateX(-50%);
    user-select: none;

    //&:first-child {
    //    transform: translateX(-$grid);
    //}
}
