$baseUrl: "/";
@import '_utils';

.container {
    display: grid;
    grid-gap: $grid * 2;
}

.source {
    background-color: $color_catskill-white;
    padding: $grid * 2;
}

.awsInfo {
    text-align: center;
}

.awsInfoIcon {
    margin-right: 15px;
}

.awsInfoHeader {
    font-weight: 600;
    vertical-align: middle;
}

.sourceInput {
    display: grid;
    grid-gap: $grid * 3;
}

.startTime {
    transition: max-height 0.3s linear;
    max-height: 100px;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-column-gap: $grid * 2;

    &--collapsed {
        max-height: 0;
    }
}
