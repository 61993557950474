$baseUrl: "/";
@import '_config';
@import '_utils';

.update-notification-window {
    position: absolute;
    right: 0;
    padding: $grid * 2;
    width: 100%;
    min-width: max-content;
    box-shadow: 0 0 $grid * 0.5 0 $color_ui-w40;
    background: $color_alabaster;
    border: 1px solid $color_ui-c30;
    border-radius: $grid * 0.5;
    overflow: hidden;
    top: calc(100% + 10px);
    z-index: 20;

    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-areas: 'text button' 'text button';

    &:hover {
        background-color: $color_mystic;
        cursor: pointer;
    }
}

.update-notification {
    padding: $grid 0;
}
.button {
    grid-area: button;
    align-self: center;
    padding: $grid;

    &:hover {
        background-color: transparent;
    }
}

.message {
    font-size: $grid * 2;
    color: #000000;
    padding: $grid * 0.5 0;

    &--violet {
        color: #673ab8;
        font-size: 14px;
    }
}
