$baseUrl: "/";
@import "_config";

.container {
    display: grid;
    grid-template-rows: repeat(2, min-content);
    grid-gap: $grid * 3;
}

.form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 430px;
    height: 32px;
}

.notificationsSentInfo {
    display: grid;
    grid-template-columns: repeat(3, min-content);
    grid-gap: $grid * 3;
    align-items: center;
    white-space: nowrap;

    li {
        display: flex;
        align-items: center;

        svg {
            margin-right: $grid;
            width: 25px;
            height: 25px;
        }
    }
}

.customDate {
    display: flex;
    align-items: center;
}

.customDateToggle {
    color: $color_mid-blue;
    cursor: pointer;
}

.binIcon {
    margin-left: $grid * 2;
    height: 20px;
    width: 20px;
    cursor: pointer;
    color: $color-dove-gray2;

    &:hover {
        color: $color_ui-w90;
    }
}

.dateTimeMerged > :last-child {
    margin: 0;
}
