$baseUrl: "/";
@import "_config";

.container {
    padding: $grid * 2 $grid * 2 ($grid);
    border: 1px solid #c9d9e0;
    border-top: none;
}

.header {
    margin-bottom: $grid;
}

.label {
    display: inline-block;
    color: $color_warm-grey;
    font-size: $font-size_small;
    font-weight: $font-weight_semibold;
    letter-spacing: $grid * 0.25;
    text-transform: uppercase;
    margin: $grid * 0.5 auto;
}

.loader {
    position: absolute;
}

.togglerIcon {
    transition: all 0.3s;
    transform: rotate(180deg) scale(1.2);
    position: relative;
    bottom: 2px;
    margin-right: $grid * 0.5;

    &--hidden {
        transform: scale(1.2);
    }
}

.togglerLabel {
    display: inline-block;
    width: 40px;
}

.list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $grid * 0.5;

    & > * {
        margin: 0 $grid * 0.5 $grid * 0.5 0;
    }

    &--hidden {
        display: none;
    }
}

.tagIcon {
    margin-left: 4px;
    margin-right: 0;
    vertical-align: -1px;
}
