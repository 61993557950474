$baseUrl: "/";
@import '_config';
@import '_utils';

.container {
    display: grid;
    grid-template-columns: max-content auto 1fr;
    grid-gap: $grid * 2;
 
}
.searchLabel {
    display: grid;
    grid-template-columns: auto 1fr;
    padding-top: $grid * 2;
    max-width: 300px;
    grid-gap: $grid;
}

.query {
    position: relative;
    top: -4px;
}

.filtersContainer {
    padding-top: $grid * 2;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: $grid;
}

.filters {
    position: relative;
    top: -4px;
    display: flex;
    flex-wrap: wrap;

    > * {
        margin: 0 4px 4px 0;
    }
}

.strikethroughText {
    text-decoration: line-through;
}
