$baseUrl: "/";
@import "_config";
@import "_utils";

@include block(snapshots-source) {
    @include element(overlay) {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba($color_black, .4);
        z-index: 100;
    }

    @include element(info) {
        color: $color_white;
    }

    @include element(preview) {
        @include modifier(loading) {
            filter: brightness(3);
        }
    }

    @include element(sprite) {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
}
