$baseUrl: "/";
@import "_config";

.container {
    display: grid;
    grid-auto-flow: row;
    gap: $grid * 3;
    padding-bottom: $grid * 2;
    padding: ($grid * 4.5) ($grid * 2);
    background-color: $color_background-grey;
}

.header {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-content: center;

    > * {
        border-left: 1px solid $color-geyser;
    }
    > *:last-child {
        border-right: 1px solid $color-geyser;
    }

    .item {
        padding: $grid * 2 $grid;
        text-align: center;
    }
    .label {
        margin-bottom: $grid;
        width: auto;
        white-space: nowrap;
    }
}

.history {
    .row {
        display: grid;
        grid-column-gap: $grid;
        padding: $grid * 1.5 0;
        grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 200px);
        border-bottom: 1px solid $color-geyser;

        > *:nth-child(2) {
            font-weight: $font-weight_semibold;
        }
        > *:nth-child(3) {
            overflow: hidden;
            text-overflow: ellipsis;
            justify-self: right;
        }
    }
}

.streamUrl {
    padding-top: $grid;

    .label {
        margin-bottom: $grid * 2;
    }
    .url {
        max-width: 450px;
        span {
            word-wrap: break-word;
        }
    }
}

.liveEventUrl {
    padding-top: $grid;

    .label {
        margin-bottom: $grid * 2;
    }
    .urls {
        display: grid;
        grid-auto-flow: row;
        gap: $grid;
    }
}
