$baseUrl: "/";
@import "_utils";

@include block(FormattedTime) {
    &[role="button"] {
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}
