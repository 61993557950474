$baseUrl: "/";
@import '_config';

.icon {
    cursor: default;

    &:hover {
        transform: scale(1.2);
    }
}

.content {
    min-width: $grid * 20;
    max-width: $grid * 40;
    text-align: left;
    padding: $grid 0 $grid * 2 $grid * 2;
}

.heading {
    margin: $grid 0 $grid #{-$grid};
    font-size: 16px;
    font-weight: 600;
}

.headingIcon {
    display: inline-block;
    margin-right: 0.7em;
    vertical-align: -11px;
}
.tooltip {
    display: block !important;
}
