$baseUrl: "/";
@import '_config';

.container {
    padding: $grid * 4 $grid * 2;
    background: $color_background-grey;
    display: grid;
    grid-column-gap: $grid * 2;
    grid-template-columns: 1fr 1fr;
    input:read-only {
        color: $color_placeholder-grey;
    }
}
