$baseUrl: "/";
@import 'theme';

$circle-size: 1.2em;
$circle-padding: 2px;
$circle-outline: 1px;
$circle-highlighted: 2px;

.Radio {
    margin-right: 1.2rem;
    display: inline-block;
    line-height: 1.2rem;
    cursor: pointer;
    user-select: none;
    vertical-align: top;

    &:hover .input:not(:disabled) {
        border-color: $sru-iris;
    }
}

.disabled {
    cursor: not-allowed;
}

.error .input {
    border-color: $sru-dark-red;
}

.highlighted .input {
    border: $circle-highlighted solid $sru-yellow;
}

.labelled .input {
    margin-right: 0.4rem;
}

.input {
    appearance: none;
    display: inline-block;
    user-select: none;
    position: relative;
    border: $circle-outline solid $sru-jagger;
    border-radius: 50%;
    width: $circle-size;
    height: $circle-size;
    vertical-align: text-bottom;
    cursor: pointer;

    &:checked::after {
        content: '';
        border-radius: 50%;
        background-color: $sru-jagger;
        position: absolute;
        top: $circle-padding;
        right: $circle-padding;
        bottom: $circle-padding;
        left: $circle-padding;
    }

    &:disabled {
        border-color: $sru-geyser;
        cursor: not-allowed;
    }

    &:disabled:checked::after {
        background-color: $sru-geyser;
    }
}
