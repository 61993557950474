$baseUrl: "/";
@import "_config";
@import "_utils";

@include block(player) {
    background-color: #f3f7f9;
    position: relative;
    text-align: center;
    $blockClass: &;

    // override global admin styles for font-size
    .svp-playback-rate svg text {
        font-size: 13px;
    }

    &__wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__poster {
        max-width: 100%;
        vertical-align: middle;
    }

    &__poster--noPoster {
        opacity: 0;
    }

    &__audio {
        height: 256px;
    }

    &__placeholder {
        position: relative;
        width: 100%;
        padding-top: 56.25%;
    }

    &__placeholder::after {
        content: attr(data-placeholder);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color_silver-chalice;
        font-size: $font-size_big;
        font-weight: $font-weight_semibold;
        text-shadow: 0 0 3px $color_light-grey;
    }

    @include modifier (expandHeight) {
        height: 100%;
        #{$blockClass}__poster {
            height: 100%;
        }
    }
}
