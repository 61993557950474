$baseUrl: "/";
@import "_utils";
@include block(AvailableVideoList) {
    display: flex;
    flex-direction: column;
    padding: 2px;
    background-color: $color_background-grey;
    position: relative;
    flex-grow: 1;
    &__empty {
        padding: $grid * 2 $grid;
        text-align: center;
        svg {
            opacity: 0.15;
            margin: 0 $grid $grid 0;
        }
    }
    &__list {
        overflow-x: hidden;
        overflow-y: auto;
        max-height: inherit;
        margin: 0;
        flex-grow: 1;
    }
    &__spinner {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        height: 100%;
    }
}
