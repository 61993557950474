$baseUrl: "/";
@import '_utils';

@include block(Thumbnail) {
    background-color: $color-gray-nurse;
    display: block;
    overflow: hidden;
    position: relative;

    &[role='button'] {
        cursor: pointer;

        &::before {
            content: '';
            display: none;
            height: 100%;
            width: 100%;
            background-color: rgba(255, 255, 255, 0.25);
        }

        &:hover {
            &:before {
                display: block;
            }
        }
    }
}
