$baseUrl: "/";
@import 'theme';
:global {
    .sru_Select {
        & &__input {
            font-size: 16px;
        }
        & &__control,
        & &__control:hover {
            flex-wrap: nowrap;
            background: $sru-white;
            border: 1px solid $sru-ui-c30;
            border-radius: $sru-grid * 0.5;
            box-shadow: inset 0 0 4px 1px $sru-gallery, 0 0 2px 1px $sru-gallery;
            min-height: $sru-grid * 6;
            overflow: hidden;
        }
        & &__label {
            padding-right: 4px;
        }
        & &__single-value {
            font-size: 16px;
            line-height: 19px;
            text-overflow: ellipsis;
            color: #333;
        }
        & &__value-container {
            overflow: hidden;
        }
        :global(div.sru_Select) &__placeholder {
            font-size: 16px;
            line-height: 16px;
            margin-top: 0;
            color: #aaa;
        }
        & &__option--is-focused {
            background: #e6f0f5;
            color: $sru-white;
        }
        & &__option--is-selected {
            background: $sru-mystic;
            color: $sru-white;
        }
        & &__option:active {
            background: #c2cbd0;
        }
        & &__menu-list {
            padding: 0;
        }
        & &__group-heading,
        & &__option {
            font-size: 16px;
            line-height: 16px;
            color: #666;
            font-weight: 700;
            padding: $sru-grid * 2;
            margin-bottom: 0;
            text-transform: none;
        }
        & &__option--is-disabled {
            color: $sru-gull-gray;
            cursor: not-allowed;
        }
        & &__group &__option {
            padding: $sru-grid * 2 $sru-grid * 2 $sru-grid * 2 $sru-grid * 3;
            font-weight: 400;
        }
        & &__option {
            font-weight: 400;
        }
        &.sru_Select--split &__option {
            border-bottom: 1px solid $sru-ui-c30;
        }
        &.sru_Select--split .sru_Select__menu-list > *:last-child,
        &.sru_Select--split .sru_Select__menu-list > *:last-child &__option:last-child {
            border-bottom: 0;
            border-radius: 0 0 $sru-grid * 0.5 $sru-grid * 0.5;
        }
        & &__group {
            padding: 0;
        }
        &.sru_Select--split &__dropdown-indicator {
            border-top-right-radius: $sru-grid * 0.25;
            border-bottom-right-radius: $sru-grid * 0.25;
            background: $sru-white;
            border-left: 1px solid $sru-ui-c30;
            box-shadow: 0 0 4px 1px $sru-gallery;
            min-height: 46px;
            height: 100%;
        }
        &.sru_Select--split &__control--opened &__dropdown-indicator {
            background: $sru-cloudy-blue;
        }
        &.sru_Select--highlighted &__control {
            margin: -1px;
            border: 2px solid $sru-yellow;
        }
        & &__multi-value__remove {
            padding: 0 2px;
            margin-right: 2px;
        }
        & &__value-container--is-multi {
            padding: 2.5px 8px;
        }
        & &__value-container--is-multi > * {
            margin: 1.5px;
        }
        & &__indicator {
            color: $sru-ui-w90;
        }
        & &__clear-indicator {
            padding-top: 0;
            padding-bottom: 0;
            color: $sru-alto;
        }
        & &__clear-indicator:hover {
            color: #8c8c8c;
            cursor: pointer;
        }
        & &__dropdown-indicator:hover {
            color: black;
        }
        & &__dropdown-indicator {
            padding: 0;
        }
        & &__arrow {
            transform: rotate(180deg);
            width: 26px;
            height: 100%;
            transition: transform 0.2s linear;
            margin: 0 7px;
            align-self: center;
        }
        & &__control--opened &__arrow {
            transform: rotate(0deg);
        }
        & &__multi-value__remove:hover {
            color: $sru-white;
            background: $sru-amethyst;
            border-radius: 2px 0 0 2px;
        }
        & &__multi-value__remove:hover,
        & &__multi-value__remove:active {
            color: $sru-white;
            background-color: $sru-bluey-purple;
            border-radius: 2px 0 0 2px;
        }
        & &__multi-value__remove:active {
            background-color: $sru-jagger;
        }
        & &__control--is-disabled,
        & &__control--is-disabled &__label,
        & &__control--is-disabled &__indicators,
        & &__control--is-disabled &__dropdown-indicator {
            background: $sru-ui-c30;
            box-shadow: none;
            color: $sru-gull-gray;
        }
        & &__control--is-disabled &__label,
        & &__control--is-disabled &__placeholder,
        & &__control--is-disabled &__single-value {
            font-weight: 600;
            color: $sru-gull-gray;
        } // ------ SMALL -------
        &.sru_Select--is-disabled.sru_Select--split &__dropdown-indicator {
            border-left: 1px solid $sru-gull-gray;
        } // ------ SMALL -------
        &.sru_Select--small &__control,
        &.sru_Select--small &__control:hover {
            min-height: $sru-grid * 4;
            border-radius: $sru-grid * 0.25;
        }
        &.sru_Select--small.sru_Select--split &__dropdown-indicator,
        &.sru_Select--small.sru_Select--split &__control--opened &__dropdown-indicator {
            min-height: 30px;
        }
        &.sru_Select--small &__arrow {
            margin: 0 $sru-grid * 0.5;
        }
        &.sru_Select--small &__group-heading,
        &.sru_Select--small &__option {
            padding: $sru-grid;
        }
        &.sru_Select--small &__group &__option {
            padding: $sru-grid $sru-grid $sru-grid $sru-grid * 2;
        } //
        // ------ COMPACT -------
        //
        &.sru_Select--compact &__control,
        &.sru_Select--compact &__control:hover {
            min-height: $sru-grid * 3;
            border-radius: $sru-grid * 0.25;
        }
        &.sru_Select--compact &__value-container {
            padding: 0 $sru-grid 1px;
        }
        &.sru_Select--compact &__arrow {
            width: 20px;
            margin: 0 3px;
        }
        &.sru_Select--compact &__value-container > * {
            margin: 0 3px 0 0;
            padding: 0 4px 0 0;
        }
        &.sru_Select--compact &__label {
            height: 20px;
            margin-top: 1px;
            font-size: 13px;
            line-height: normal;
        }
        &.sru_Select--compact.sru_Select--split &__dropdown-indicator,
        &.sru_Select--compact.sru_Select--split &__control--opened &__dropdown-indicator {
            min-height: 22px;
        }
        &.sru_Select--compact &__group-heading,
        &.sru_Select--compact &__option {
            padding: $sru-grid;
        }
        &.sru_Select--compact &__group &__option {
            padding: $sru-grid $sru-grid $sru-grid $sru-grid * 2;
        }
    }
}
