$baseUrl: "/";
@import "_config";
@import '_utils';
@include block(OrderDropdown) {
    &__button {
        border: 1px solid $color_black;
        border-radius: 50%;
        height: $grid * 6;
        width: $grid * 6;
        background: $color_white;
        border: 1px solid $color_cloudy-blue;
        &--expanded {
            border-color: $color_daisy-bush;
        }
    }
    & div.af_OrderDropdown__item.af_OrderDropdown__item {
        line-height: 48px;
        padding: 0 $grid * 2;
        background: $color_white;
        &:before {
            vertical-align: sub;
            content: '';
            border: 1px solid $color_jagger;
            width: 20px;
            border-radius: 50%;
            height: 20px;
            outline: 2px;
            display: inline-block;
            margin-right: 14px;
        }
        &:hover,
        &--selected {
            &:before {
                border: 5px solid $color_jagger;
            }
        }
        &:first-child {
            cursor: default;
            padding-left: $grid * 6;
            font-size: 13px;
            font-weight: $font-weight_semibold;
            &:before {
                display: none;
            }
        }
        &:nth-child(odd) {
            background: $color_alabaster;
        }
    }
    &__button:hover {
        border: 1px solid $color_iris;
    }
    &__icon {
        color: $color_bluey-purple;
        &:hover {
            color: $color_iris;
        }
        &:active {
            color: $color_daisy-bush;
        }
    }
}
