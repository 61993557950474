$baseUrl: "/";
@import '_utils';

.dropzone {
    z-index: 1;

    border: 1px dashed $color_dusty-gray;
    outline-offset: -1px;
}

.hovered {
    outline: 2px dashed $color_bluey-purple;
    outline-offset: -2px;
}

.disabled {
    display: none;
}

.fixed {
    display: none;
    outline: $grid solid $color_bluey-purple;
    outline-offset: -$grid;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.visible {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.content {
    height: 100%;
}

.icon {
    margin-bottom: $grid * 2;
}

.label {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.02em;
}

.link {
    color: $color_bluey-purple;
    cursor: pointer;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
