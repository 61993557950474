$baseUrl: "/";
// Use css version instead of scss due to the issue
// @see https://github.com/DominicTobias/react-image-crop/issues/429
@import '~react-image-crop/dist/ReactCrop.css';
@import '_utils';

.ReactCrop {
    &__image {
        max-height: fit-content;
    }
    &__crop-selection {
        border: 2px solid $color-white;
    }

    &__crop-selection::after,
    &__crop-selection::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border: 1px solid $color-white;
    }

    &__crop-selection::before {
        left: 33%;
        right: 33%;
        border-top-width: 0;
        border-bottom-width: 0;
    }

    &__crop-selection::after {
        top: 33%;
        bottom: 33%;
        border-left-width: 0;
        border-right-width: 0;
    }

    &--active &__crop-selection,
    &--active &__crop-selection::after,
    &--active &__crop-selection::after {
        border-color: $color-white-alpha06;
    }

    .ord-nw,
    .ord-ne,
    .ord-se,
    .ord-sw {
        max-width: 20px;
        max-height: 20px;
        width: 50%;
        height: 50%;
        border: 5px solid $color-white;
        background-color: unset;
    }

    .ord-nw {
        margin-top: -4px;
        margin-left: -4px;
        border-right-width: 0;
        border-bottom-width: 0;
    }

    .ord-ne {
        margin-top: -4px;
        margin-right: -4px;
        border-left-width: 0;
        border-bottom-width: 0;
    }

    .ord-se {
        margin-bottom: -4px;
        margin-right: -4px;
        border-left-width: 0;
        border-top-width: 0;
    }

    .ord-sw {
        margin-bottom: -4px;
        margin-left: -4px;
        border-right-width: 0;
        border-top-width: 0;
    }

    &__drag-handle.ord-n,
    &__drag-handle.ord-e,
    &__drag-handle.ord-s,
    &__drag-handle.ord-w {
        display: none;
    }
}
