$baseUrl: "/";
@import '_utils';

.container {
    display: flex;
    gap: $grid;
}

.button {
    height: 22px;
    color: grey;

    &:disabled {
        color: lightgrey;
    }
}

.slider {
    position: relative;
}

.steps {
    position: absolute;
    height: 7px;
    top: 8px;
    left: 4px;
    right: 4px;
    display: flex;

    &::before {
        content: "";
        position: absolute;
        height: 1px;
        top: 3px;
        left: 0;
        right: 0;
        background-color: lightgrey;
    }

    span {
        border-left: 1px solid lightgrey;
        flex-grow: 1;
    }

    span:last-child {
        border-right: 1px solid lightgrey;
    }
}

.input {
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 10rem;

    --thumb-size: 9px;
    --track-height: 7px;

    &:focus {
        outline: none;
    }
    &::-webkit-slider-runnable-track {
        background-color: transparent;
        border-radius: 0;
        height: var(--track-height);
        transform: translateY(3px);
    }
    &::-moz-range-track {
        background-color: transparent;
        border-radius: 0;
        height: var(--track-height);
        transform: translateY(2.5px);
    }
    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        margin-top: -1px;

        background-color: $color_bluey-purple;
        height: var(--thumb-size);
        width: var(--thumb-size);
        border-radius: 50%;
    }
    &::-moz-range-thumb {
        -moz-appearance: none;
        border: none;
        border-radius: 50%;
        background-color: $color_bluey-purple;
        transform: translateY(2.5px);
        height: var(--thumb-size);
        width: var(--thumb-size);
    }
    &:focus::-webkit-slider-thumb {
        border: 0;
        outline: 0;
        outline-offset: 0;
    }
    &:focus::-moz-range-thumb {
        border: 0;
        outline: 0;
        outline-offset: 0;
    }
}
