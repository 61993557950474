$baseUrl: "/";
@import "_config";
.af_SearchForm {
    grid-area: search;
    &__connector {
        grid-template-columns: 1fr auto;
    }
    &__input .af_form-input {
        background-color: $color_alabaster;
        border-color: $color_ui-w40;
        box-shadow: inset 0 0 2px 2px $color_gray-nurse;
    }
    & &__button {
        left: -1px;
        border-color: $color_ui-w40;
    }
    &__icon {
        color: $color_black;
    }
}
