$baseUrl: "/";
@import '_utils';

.container {
    margin-top: $grid * 2;
    padding: 0 $grid;
    display: grid;
    grid-template-columns: repeat(4, max-content);
    grid-gap: $grid;

    div + .input {
        margin-left: $grid * 5;
    }
}

.input {
    width: 103px;

    input + span {
        position: absolute;
        white-space: nowrap;
    }
}
