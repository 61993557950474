$baseUrl: "/";
@import '_config';

.container {
    margin-top: 20px;
    grid-area: podme;
    display: grid;
    grid-auto-flow: row;
    gap: $grid * 2;
}

.header {
    display: flex;
    align-items: center;
    gap: .8rem;
}

.questionMark {
    margin: 0;
}

.divider {
    margin: 0;
}

.form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 'mainCheckbox rssCheckbox' 'category category';
    gap: $grid * 3;
}

.mainCheckboxContainer {
    grid-area: mainCheckbox;
    display: flex;
    align-items: center;
    gap: .8rem;

    label {
        margin: 0;
    }
}

.spinner {
    width: 15px;
}

.rssCheckbox {
    grid-area: rssCheckbox;
}

.category {
    grid-area: category;
}
