$baseUrl: "/";
@import '_config';
@import '_utils';

.filtersDialog {
    max-width: 785px;
}

.form {
    padding: $grid * 4 70px $grid * 2;

    & > * {
        margin-bottom: $grid * 3;
    }
}

.rowFilters {
    display: flex;

    &:hover {
        z-index: 1;
    }
    > div:last-child {
        margin-left: 30px;
    }
}

.publishDates {
    display: flex;
}

.publishDateAfter {
    margin-right: $grid * 2;
}

.durationInputs {
    display: grid;
    grid-template-columns: 116px 116px auto;
    grid-column-gap: $grid * 2;
}

.checkbox {
    line-height: 3.2rem;
}

.pairedFilters {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: $grid * 3;

    align-items: center;
}

.categoriesLabel > span {
    align-self: center;
    padding-top: $grid * 2;
}

.label {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: $grid;
    color: $color_ui-w80;
}

.labelSmallMargin {
    margin-bottom: 14px;
}

.labelZeroMargin {
    margin-bottom: 0px;
}

.tagsLabel > span {
    align-self: center;
    padding-top: $grid * 2;
}

.horizontalFilters{
    display: flex;
    justify-content:flex-start;
}

.marginLeft{
    margin-left: 80px;
}