$baseUrl: "/";
@import '_config';

.container {
    white-space: nowrap;
    margin-top: $grid * 2;
}

.inner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    span {
        flex-grow: 1;
    }
}

.input {
    padding: $grid * 0.5 $grid;
    height: unset;
}

.link:hover {
    text-decoration: underline;
    color: $color_mid-blue;
}

.regenerateIcon {
    color: $color_alto;
    &:hover {
        color: $color-gray;
        cursor: pointer;
    }
    svg {
        width: 100%;
        height: 100%;
    }
}
