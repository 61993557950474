$baseUrl: "/";
@import "_config";

.errorType {
    color: $color_guardsman-red;
}

.warningIcon {
    position: relative;
    top: -1px;
}
