$baseUrl: "/";
@import '_config';

.inputs-list {
    display: grid;
    grid-gap: $grid * 3;
}

.inputs-row {
    display: grid;
    grid-gap: $grid;

    grid-template-columns: minmax(100px, 1fr) max-content;

    :last-child {
        grid-area: 2 / span 2;
    }
}

.add-button.add-button.add-button {
    margin-top: $grid;
    justify-self: right;
    padding: 0;
}
