$baseUrl: "/";
@import '_utils';

.schedules {
    display: grid;
    grid-gap: $grid * 2;
    grid-template-areas: 'assetSchedule encoderSchedule';
    grid-template-columns: 1fr 1fr;
}

.assetSchedule {
    grid-area: assetSchedule;
}

.encoderSchedule {
    grid-area: encoderSchedule;
    transition: max-height 0.8s;
    max-height: 0;
    overflow: hidden;

    &--visible {
        max-height: 180px;
    }
}

.field {
    transition: max-height 0.7s;
    max-height: 100px;
    margin-top: $grid * 2;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-column-gap: $grid * 2;

    &--collapsed {
        max-height: 0;
    }
}

.dvrWarning {
    color: $color_brandy_punch;
    margin-top: 10px;
}

.scheduleError {
    color: $color_guardsman-red;
    margin-bottom: 10px;
    a {
        text-decoration: underline;
    }
}
