$baseUrl: "/";
@import '_config';
@import '_utils';

.logo {
    grid-area: logo;
    background: center center/contain no-repeat;
    background-color: transparent;
    display: inline-block;
    height: $grid * 6;
    width: $grid * 10;
    font-size: 0;
    cursor: pointer;

    @include media('>tablet') {
        width: $grid * 10;
        height: $grid * 6.5;
    }
}

.newsroomSelectDialog {
    max-width: 400px;
    padding-bottom: 55px;
    border-radius: 8px;

    & > :first-child {
        background: url(#{$baseUrl}assets/stream-logo.svg) right 32px center/35px no-repeat;
    }
}

.dialogHeading {
    font-weight: 600;
    font-size: 23px;
}
