$baseUrl: "/";
@import '_utils';

.container {
    display: grid;
    grid-template-columns: 1.6fr 1.4fr;
    grid-gap: $grid * 3;
    margin-top: $grid * 2;
   
    input[readonly] {
        color: $color_warm-grey;
        background-color: $color_wild-sand;
    }
}