$baseUrl: "/";
@import "_utils";

$numberOfColumns: 3;
$numberOfRows: 4;
$itemWidth: 96px;
$itemHeight: 54px;
$containerWidth: #{$itemWidth * $numberOfColumns + $grid * 2 * ($numberOfColumns - 1)};
$containerHeight: #{$itemHeight + $grid * 2};
$expandedContainerHeight: #{$itemHeight * $numberOfRows + $grid * 2 * $numberOfRows};

.container {
    display: grid;
    grid-column-gap: $grid * 2;
    grid-template-columns: 2fr 1fr;
    grid-template-areas:
        "title links"
        "list  list";

    @include media(">=desktop") {
        grid-area: suggested-thumbnails;
    }
}

.title {
    grid-area: title;
}

.list {
    grid-area: list;
}

.thumbnails {
    display: flex;
    flex-flow: row wrap;
    max-height: $containerHeight;
    overflow: hidden;
    transition: max-height 0.4s ease-out;
    justify-content: space-between;

    &.expanded {
        max-height: $expandedContainerHeight;
    }

    .thumbnail {
        margin-bottom: $grid * 2;
    }
}

.links {
    grid-area: links;
    text-align: right;
    cursor: pointer;
}

.noImages {
    margin: $grid * 1.5 0;
}
