$baseUrl: "/";
@import '_utils';

@keyframes spin {
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.player {
    display: inline-block;
    position: relative;
    background-color: $color_black;

    video {
        display: block;
    }

    &--paused:not(&--loading) .playIcon {
        opacity: 1;
    }

    &:hover:not(&--paused):not(&--loading) .pauseIcon {
        opacity: 1;
    }

    &--loading .loaderIcon {
        opacity: 1;
    }

    &:not(&--loading):not(&--empty) {
        cursor: pointer;
    }

    &--empty .outlineStopIcon {
        opacity: 1;
    }
}

.playIcon,
.pauseIcon,
.loaderIcon,
.outlineStopIcon {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    transition: opacity 0.1s;
    font-size: 400%;
    filter: drop-shadow(0 0 2px $color-black-alpha05);

    path {
        fill: $color-white-alpha95;
    }
}

.loaderIcon {
    font-size: 300%;
    animation: spin 1.5s linear infinite;
}

.outlineStopIcon {
    margin-left: -4px;
}

.emptyVideo {
    grid-area: player;
    width: 250px;
    aspect-ratio: 16/9;
    background-color: $color_baltic_sea;
}
