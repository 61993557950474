$baseUrl: "/";
@import '_utils';

.info {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.spinner {
    margin-top: $grid * 5;
    width: 100px;
}

.instruction {
    font-size: 26px;
    margin-top: $grid * 6;
    margin-bottom: $grid * 3;
}
