$baseUrl: "/";
@import '_utils';

.container {
    display: flex;
    grid-area: timeline;
    width: 100%;

    &:not(.intersecting) .time:last-child {
        overflow: hidden;
    }
}

.time {
    text-align: left;
    flex-grow: 1;
    height: 25px;
    border-left: 1px solid lightgrey;
    color: grey;
    font-size: 12px;
    box-sizing: border-box;
    padding: 0 $grid * 0.5 $grid;

    &:last-child {
        white-space: nowrap;
        flex-shrink: 1;
    }
}
