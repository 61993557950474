$baseUrl: "/";
@import "_utils";

.wrapper {
    display: grid;
    grid-template: max-content / auto;

    @include media(">=desktop") {
        grid-area: poster;
    }

    &:hover .toolbox {
        opacity: 1;
    }
}

.image {
    grid-column: 1;
    grid-row: 1;
    display: block;
    width: 100%;
    pointer-events: none;
    &--highlighted {
        border: 2px solid $color_yellow;
    }
}

.spinner {
    grid-column: 1;
    grid-row: 1;
    display: flex;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.7);
}

.toolbox {
    opacity: 0;
    grid-column: 1;
    grid-row: 1;
    height: 100%;
    padding: $grid;
    display: flex;
    align-items: flex-end;
    font-size: 1.2em;
    color: #fff;
    align-self: end;
    background-image: linear-gradient(
            transparent 60%,
            rgba(0, 0, 0, 0.5) 80%,
            rgba(0, 0, 0, 0.7) 95%
    );
    transition: opacity 0.2s ease;
}

.toolbox > li {
    padding: $grid * 0.5;
    cursor: pointer;
}
