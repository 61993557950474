$baseUrl: "/";
@import '_config';

.row {
    display: flex;
    gap: $grid;
    justify-content: space-between;
}

.button {
    min-width: max-content;
}

.input {
    width: 100%;
}
