$baseUrl: "/";
@import "_utils";

.radio {
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
}

.input {
    margin: 0;
    position: absolute;
    left: 0;
}

.label {
    font-size: $font-size_big;
    font-weight: $font-weight_semibold;
    margin-bottom: $grid;
    display: block;
}
