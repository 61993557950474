$baseUrl: "/";
@import '_config';
@import '_utils';

.dropdown-content {
    max-width: 350px;

    &--problematic {
        max-width: 380px;
    }
}

.caption {
    margin: $grid 0 $grid * 2;
}

.static-item:hover {
    background: none !important;
}

.keep-running-button {
    display: flex;
    justify-content: flex-end;
    padding-top: $grid * 2;
    &:hover {
        background: none;
    }
}
