$baseUrl: "/";
@import "_config";

.spinner {
    display: flex;
    justify-content: center;
}

.error {
    padding: 0;
    text-align: center;
}

.list {
    width: 100%;
}

.header {
    display: grid;
    grid-column-gap: $grid * 2;
    grid-template-columns: minmax(160px, 0.8fr) 1.2fr 140px ($grid * 4);
    padding-bottom: $grid * 1.5;
    border-bottom: 1px solid $color-geyser;
}
