$baseUrl: "/";
@import '_utils';

.customFiltersContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.customFiltersLabel {
    text-align: center;
}

.customFilters {
    margin-top: $grid * 3;
    align-self: self-start;
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: $grid * 3;
}

.date {
    width: 50%;
}

.checkbox{

    line-height: 2.3rem;
}