$baseUrl: "/";
@import 'theme';

.CopyToClipboard {
    position: relative;
    padding-right: 2px;

    svg {
        position: absolute;
        visibility: hidden;
        vertical-align: bottom !important;
        margin-left: 4px;
        background-color: $sru-white;
    }

    &:hover {
        font-weight: 600;
        padding-right: 0;
        cursor: copy;
    }

    &:hover svg {
        visibility: visible;
    }

    &--copied:hover {
        font-weight: 400;
        cursor: default;
    }
}
