$baseUrl: "/";
@import "_config";
@import "_utils";
@include block(InlineStatusFilters) {
  display: none;
  margin-bottom: $grid * 3;
  &__element {
    display: inline-block;
    margin: 0 $grid * 2;
    font-size: $font-size_big;
    font-weight: $font-weight_semibold;
    color: $color_purple-heart;
    line-height: 25px;
    text-transform: capitalize;
    cursor: pointer;
    &:hover {
      color: $color_daisy-bush;
    }
    &--active {
      background-color: $color_daisy-bush;
      color: $color_white;
      border-radius: 2px;
      padding: 0 $grid;
      &:hover {
        color: $color_white;
      }
    }
  }
  @include media('>tablet') {
    display: inline-block;
    font-size: 0;
  }
}
