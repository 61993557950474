$baseUrl: "/";
@import '_config';

.inputs-row {
    display: grid;
    grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr) max-content max-content;
    grid-gap: $grid;
}

.metadata-spinner,
.metadata-error {
    margin-bottom: $grid;
    display: inline-block;
}
