$baseUrl: "/";
@import "_utils";

.wrapper {
    margin: $grid 0;
}

.content {
    margin: $grid 0;
}

.button {
    width: max-content;

    input {
        display: none;
    }
}
