$baseUrl: "/";
@import '_utils';

.dropzone {
    aspect-ratio: 1;
    min-height: 110px;
    border: 1px dashed $color-lavender;
    border-radius: $grid;
    position: relative;
    z-index: 10;
    display: grid;
    justify-content: center;
    align-content: center;

    &:hover {
        background-color: $color_alabaster2;
    }
}
