$baseUrl: "/";
@import "_config";

.container {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    padding: 0 0 $grid * 2;
    grid-row-gap: $grid * 2;
}

.inputsRow {
    display: grid;
    grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr) max-content;
    grid-gap: $grid;
}

.addButton {
    justify-self: right;
}
