$baseUrl: "/";
@import '_config';

.dialog {
    max-width: 900px;
}

.heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.headingInfo {
    font-size: $font-size_big;
    font-weight: $font-weight_regular;

    .date {
        font-weight: $font-weight_semibold;
    }
}

.container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $grid * 5;
    align-items: start;
    padding: $grid * 5;
}

.inlineLabel {
    font-weight: $font-weight_semibold;

    &:not(:first-of-type) {
        margin-left: $grid;
    }
}

.purpleLabel {
    color: $color_bluey-purple;
    font-weight: $font-weight_semibold;
}

.main,
.additional,
.other {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: $grid * 2;
}

.description {
    line-height: $grid * 2;
}

.tags,
.stories,
.subtitles,
.chapters,
.cuePoints {
    display: flex;
    flex-wrap: wrap;
    gap: $grid * 0.5;

    .item {
        display: grid;
        align-content: start;
        justify-items: center;
        gap: $grid * 0.5;
        padding: $grid * 0.5;
        border: 1px solid $color_cloudy-blue;
    }
}

.tagItem {
    display: flex;
    align-items: center;
    gap: $grid * 0.5;
}

.subtitles {
    display: grid;
    grid-auto-flow: row;
    gap: $grid;
}

.streamUrl {
    word-wrap: break-word;
    word-break: break-all;
    white-space: pre-wrap;
    line-height: 18px;
}

.image {
    display: block;
    width: 100%;
}

.metadata {
    display: grid;
    grid-template-columns: max-content minmax(0, 1fr);
    column-gap: $grid * 3;
    row-gap: $grid * 1.5;

    .key {
        font-weight: $font-weight_semibold;

        &.highlighted {
            position: relative;

            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 4px;
                left: -14px;
                width: 6px;
                height: 6px;
                background-color: $color_yellow;
                border-radius: 50%;
            }
        }
    }
    .value {
        word-wrap: break-word;
        word-break: break-all;

        .copy:hover {
            font-weight: $font-weight_regular;
        }
    }
}
