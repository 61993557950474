$baseUrl: "/";
@import '_utils';
@import '_mixins';

.advanced {
    display: block;
}

.advanced > * {
    margin-bottom: $grid * 2;
}

.advanced > :not(:first-child) {
    transition:
        max-height 0.3s ease-in 0.15s,
        margin-top 0.3s ease-in 0.15s,
        margin-bottom 0.3s ease-in 0.15s,
        opacity 0.15s ease-out 0.3s;
}

.advanced .type--selected {
    transition:
        max-height 0.3s ease-in 0.15s,
        margin-top 0.3s ease-in 0.15s,
        margin-bottom 0.3s ease-in 0.15s,
        padding-left 0.3s ease-in,
        opacity 0.15s ease-out 0.3s;

    :first-child {
        transition: 0.2s opacity 0.2s ease-out;
    }
}

.advancedContainer {
    max-height: 1000px;
    opacity: 1;
    transition:
        max-height 1s ease-out,
        opacity 0.2s ease-out 0.2s;
}

.advancedContainer.advancedContainer--expanded {
    transition:
        max-height 1s ease-out,
        opacity 0.1s ease-out 0.3s;
}

.advancedContainer.advancedContainer--hidden {
    max-height: 0;
    opacity: 0;
    transition:
        max-height 0.3s ease-out,
        opacity 0.1s ease-out;
    pointer-events: none;
}

.advanced:not(.advanced--expanded) > :not(:first-child):not(.type--selected) {
    margin-top: 0;
    margin-bottom: 0;
    max-height: 0;
    opacity: 0;
    transition:
        max-height 0.3s ease-out,
        margin-top 0.3s ease-out,
        margin-bottom 0.3s ease-out,
        opacity 0.1s ease-out;
}

.advanced:not(.advanced--expanded) .type--selected {
    padding-left: 0;
    cursor: auto;
    transition:
        max-height 0.3s ease-out,
        margin-top 0.3s ease-out,
        margin-bottom 0.3s ease-out,
        padding-left 0.3s ease-in 0.1s,
        opacity 0.1s ease-out;

    :first-child {
        opacity: 0;
        pointer-events: none;
        transition-delay: 0.1s;
    }
}

.advanced:not(.advanced--touched) .type--selected {
    transition: none;

    :first-child {
        transition: none;
    }
}

.type.type span {
    font-weight: 400;
}

.grid {
    width: 320px;
    background-color: $color_catskill-white;
    padding: 5px 10px;
    margin-bottom: $grid * 3;
}

.channelsMap {
    width: 100%;
    text-transform: uppercase;
    font-weight: $font-weight_semibold;
    font-size: $font-size_medium;
    text-align: center;
    background-color: $color_catskill-white;
    td,
    th {
        padding: 0.5em 0;
        vertical-align: middle;
    }
    td:last-child {
        border-left: 1px solid $color_ui-c30;
    }
    tr {
        border-bottom: 1px solid $color_ui-c30;
    }
    tr:last-child,
    :first-child {
        border-bottom: none;
    }
    div {
        margin: 0;
    }
}
