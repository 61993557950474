$baseUrl: "/";
@import '_config';
@import '_utils';
@import '_mixins';
:export {
    purpleHeart: $color_purple-heart;
    silver2: $color_silver2;
    white: $color_white;
}

.form {
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: minmax(600px, 900px);
    grid-row-gap: $grid * 4;
    grid-template-areas:
        'meta'
        'list';
}

.meta {
    display: grid;
    gap: $grid * 4;
    grid-area: meta;
    padding: $grid * 4;
    background-color: $color_catskill-white;
    font-size: $font-size_big;
    font-weight: 600;
}

.metaRow {
    font-weight: 100;
    display: grid;
    grid-template-columns: 250px max-content max-content;
    justify-content: space-between;
}

.criteria {
    display: flex;
    gap: $grid * 6;
}

.searchButton {
    background-color: $color_purple-heart !important;
    border: 0 !important;
    border-radius: 0;

    &:hover {
        background-color: $color_iris !important;
    }
}

.order button {
    width: 35px;
    height: 35px;
}

.search {
    display: flex;
    flex-direction: row;
}

.durationContainer {
    align-content: start;
    font-weight: 100;
}

.duration {
    display: flex;
    margin-top: 9px;
}

.icon {
    margin-right: $grid * 2;
}

.formInputs {
    font-weight: 100;
}

.type {
    display: flex;
    margin-top: 6px;
}

.notFound {
    display: grid;
    justify-content: center;
    text-align: center;
    font-size: $font-size_large;
    padding-top: $grid * 4;

    img {
        padding-top: $grid * 2;
    }
}

.travolta {
    width: 15vw;
    min-width: max-content;
    justify-self: center;
}

.spinner {
    display: flex;
    justify-content: center;
}
