$baseUrl: "/";
@import '_config';

.container {
    display: flex;
    flex-wrap: wrap;
    gap: $grid;
    flex-basis: 150px;
    flex-grow: 2;
}

.selector {
    min-width: 160px;
}
