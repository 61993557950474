$baseUrl: "/";
@import '_utils';

.video {
    width: 100%;
    margin-bottom: $grid * 4;

    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-areas: 'video details';
    grid-column-gap: $grid * 3;
}

.details {
    grid-area: details;
    margin-top: $grid * 2;
}

.caption {
    font-weight: 600;
    font-size: $font-size_large;
    margin-bottom: $grid * 1.5;
}

.duration {
    margin-left: 0.5rem;
    font-weight: $font-weight_semibold;
}

.durationIcon {
    vertical-align: bottom;
}

.buttons {
    display: flex;
    flex-wrap: wrap;
    margin-top: $grid * 2.5;
    gap: $grid * 2;
}
