$baseUrl: "/";
@import '_utils';

.wrapper {
    width: 400px;
    display: grid;
    gap: $grid * 4;
    grid-template-columns: auto max-content;
    grid-template-areas: 'select logo';
}

.logo {
    width: 80px;
    margin-top: -12px;
    grid-area: logo;
}

.select {
    grid-area: select;
}
