$font-family_SourceSansPro: 'Source Sans Pro', sans-serif;

$font-size_primary: 14px;
$font-size_small: 12px;
$font-size_medium: 13px;
$font-size_regular: 15px;
$font-size_big: 16px;
$font-size_large: 18px;
$font-size_extra-large: 20px;
$font-size_super-large: 23px;

$font-weight_regular: 400;
$font-weight_semibold: 600;
$font-weight_bold: 700;
$font-weight_black: 900;
