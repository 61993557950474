$baseUrl: "/";
@import "_config";
@import "_utils";

.container {
  font-size: $font-size_big;
  font-weight: $font-weight_semibold;
}

.icon {
  vertical-align: middle;
  margin: 1px $grid;
}
