$baseUrl: "/";
@import "_utils";

.placeholder {
    width: 100%;
    height: 100%;
    color: $color-tundora;
    background-color: $color_background-grey;
    position: relative;
}

.spinner, .label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
