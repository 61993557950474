$baseUrl: "/";
@import '_utils';

.dialog {
    overflow: visible;
}

.container {
    display: grid;
    grid-gap: $grid * 2;
}

.schedules {
    display: grid;
    grid-gap: $grid * 2;
    grid-template-areas: 'assetSchedule encoderSchedule';
    grid-template-columns: 1fr 1fr;
}

.assetSchedule {
    grid-area: assetSchedule;
}

.encoderSchedule {
    grid-area: encoderSchedule;
}

.startTime {
    transition: max-height 0.3s linear;
    max-height: 100px;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-column-gap: $grid * 2;

    &--collapsed {
        max-height: 0;
    }
}
