$baseUrl: "/";
@import '_utils';

.container {
    grid-area: liveRuntime;
}

.controls {
    display: flex;
    flex-wrap: wrap;
    padding-left: -$grid * 2;

    > * {
        margin-left: $grid * 2;
    }
}

.runtimeControls > div[class*="LabeledContainer"]:not(:first-child) button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.runtimeControls > div[class*="LabeledContainer"]:not(:last-child) button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 0;
}
