$baseUrl: "/";
@import '_utils';
@import '_config';

.checkmark {
  margin: 0px 2px 0px 4px;
  color: $color_salem;
}

.enter {
  opacity: 0;
}

.enterActive {
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}

.exit {
  opacity: 1;
}

.exitActive {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}
