$baseUrl: "/";
@import "_utils";

.AssetCreationButton {
    justify-self: end;
    grid-area: buttons;

    &--buttons {
        display: grid;
        grid-template-columns: repeat(2, max-content);
        grid-gap: inherit;

        @include media("<=950px") {
            display: none;
        }
    }

    &--dropdown {
        @include media(">950px") {
            display: none;
        }
    }
}

