$baseUrl: "/";
@import '_utils';

.container {
    text-transform: uppercase;
    display: flex;
    color: $color_iris;
    font-size: $font-size_big;
    font-weight: $font-weight_semibold;
    border-bottom: 2px solid $color_mystic;
}
