$baseUrl: "/";
@import '_config';
@import '_utils';

.ready {
    grid-area: notifications;
    display: flex;
    flex-direction: row;
    background-color: #f3f7f9;
    margin-bottom: $grid * 2;
    padding: $grid * 4;

    font-size: $font-size_big;
    font-weight: 600;

    &:hover {
        cursor: pointer;
    }
}

.message {
    font-size: $font-size_big;
    padding-right: $grid * 2;
    margin: 0;
}
