$baseUrl: "/";
@import '_utils';

@include block(AssetTabs) {
    &__list {
        display: flex;
        margin-bottom: $grid * 2;
        padding-bottom: $grid * 2;
        color: $color_iris;
        font-size: $grid * 2;
        font-weight: $font-weight_semibold;
        overflow-x: auto;
    }

    &__tab {
        padding: $grid * 2 $grid;

        @include media(">=desktop") {
            padding: $grid * 2 $grid * 2 - 2;
        }
    }

    &__tab > a,
    &__tab > a:hover {
        color: $color_iris;
        text-decoration: none;
        white-space: nowrap;
    }

    &__tab--active {
        border-bottom: 4px solid $color_purple-navigation;
        color: $color_purple-navigation
    }
}
