$baseUrl: "/";
@import 'theme';
.CollapsibleContent {
    .ArrowIcon {
        transform: rotate(180deg);
    }
    &--expanded > .Header > .ArrowIcon {
        transform: rotate(0deg);
    }
    &--cover > .Header {
        padding-top: $sru-grid * 1.5;
    }
    &--cover > .Header > .Label {
        color: $sru-mid-blue;
    }
    &--cover#{&}--expanded > .Header {
        display: none;
    }
    &--cover#{&}--expanded > .Content {
        padding-top: $sru-grid * 1.5;
    }
    &--cover > .Header, &--customHeader > .Header {
        height: auto;
    }
}

.Header {
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    height: $sru-grid * 4.5;
}

.Label {
    margin: 0;
}
.ArrowIcon {
    transition: transform 0.2s linear;
    height: $sru-grid * 4;
    width: $sru-grid * 4;
    padding: 0 $sru-grid * 0.5;
}
