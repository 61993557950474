$baseUrl: "/";
@import '_utils';

.dialog {
    max-width: 900px;
}

.center {
    display: flex;
    justify-content: center;
    margin-bottom: $grid * 4;
}

.tabsContainer {
    padding-bottom: 0;
}

.editButton {
    padding: 0 $grid * 3;
}
