$baseUrl: "/";
.EmbedPreviewIframe {
    position: relative;
    grid-area: preview;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;

    &__iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    
        &--hidden {
            visibility: hidden;
        }
    }
}
