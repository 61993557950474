$baseUrl: "/";
@import "_utils";

@include block(MoodSelect) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__option {
        display: block;
    }

    &__emoji {
        transition: transform 0.3s ease-in-out;
        transform: scale(0.8);
        font-size: 0.8em;
        cursor: pointer;
    }

    &__emoji:hover {
        transform: scale(1.4);
    }

    &__emoji--inactive {
        filter: grayscale(0.9) hue-rotate(150deg);
    }

    &--highlighted {
        border: 2px solid $color_yellow;
        border-radius: 3px;
    }
}
