$baseUrl: "/";
.wrapper {
    &:not(.disabled) {
        cursor: move;
    }
}

.isDragging {
    opacity: 0.5;
}
