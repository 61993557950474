$baseUrl: "/";
@import '_config';

.container {
    display: block;
    padding: $grid * 2;
    font-size: $font-size_super-large;
    line-height: 30px;
    text-align: center;
    padding: 100px 0;
}

.searchIcon {
    opacity: 0.15;
}
