$baseUrl: "/";
@import '_config';
@import '_utils';

.VideoCard {
    &:hover {
        background: $color_alabaster2;
    }

    position: relative;
    transition: background 100ms linear;
    display: grid;
    padding: $grid;
    grid-gap: $grid * 2;
    grid-template-columns: 124px 1fr 1fr;
    background-color: $color-white;

    &--hasFailed {
        border: 1px solid $color_guardsman-red !important;
    }

    @include media('<=small') {
        grid-template-columns: 124px 1fr !important;
    }
}

.header {
    display: grid;
    grid-gap: $grid * 2;
    grid-template-columns: 124px 1fr 1fr;
    font-weight: $font-weight_semibold;
    padding: 0 $grid $grid $grid;
    border-bottom: 1px solid $color_mercury;

    @include media('<=small') {
        display: none;
    }
}

.header-details {
    grid-template-columns: 4fr 2fr 3fr 2fr;
    grid-auto-flow: column;
    grid-auto-columns: 2fr;
    display: grid;
    grid-gap: $grid * 2;
    font-weight: $font-weight_semibold;

    > *:first-child {
        padding-left: $grid * 2;
    }
}

.createdByHeader {
    text-align: center;
}

.icon {
    color: $color_silver2;
    margin-right: $grid;
}

.details {
    color: $color_ui-w100;
    display: grid;
    grid-template-columns: 1fr 0.2fr auto;
    grid-template-areas: 'type icon controls' 'title icon controls' 'notes icon controls' 'meta icon controls';
    z-index: 1;
    &.details--compact {
        grid-template-areas: 'title icon controls' 'notes icon controls' 'meta icon controls';
    }

    &--row {
        grid-template-columns: auto 1fr 0.5fr;
        grid-template-areas: 'type type icon' 'title title icon' 'notes notes icon' 'meta controls icon';
    }

    &--row.details--compact {
        grid-template-areas: 'title title icon' 'notes notes icon' 'meta controls icon';
    }
}

.type {
    grid-area: type;

    &--big {
        @include media('>=small') {
            font-size: $font-size_big;
            color: $color_ui-w90;
        }
    }
}

.title {
    grid-area: title;
    font-weight: $font-weight_semibold;
    margin: $grid 0 2px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 17px;
    font-size: $font-size_big;
    display: flex;
    &--big {
        @include media('>=small') {
            font-size: $font-size_large;
            margin: 0;
        }
    }

    &--compact {
        margin: 0 0 2px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.controls {
    grid-area: controls;
    align-self: center;

    > *:not(:last-child) {
        margin-bottom: $grid;
    }

    &--row {
        align-self: initial;
        margin-top: $grid * 0.5;
    }
}

.errorIcon {
    grid-area: icon;
    align-self: center;
    justify-self: center;
    color: $color_guardsman-red;
    transform: scale(1.7);

    &--compact {
        transform: scale(1.3);
    }
}

.link {
    display: block;
}

.notes {
    grid-area: notes;
    font-size: $font-size_big;
    font-style: italic;
    line-height: 20px;

    @include media('<=small') {
        display: none;
    }
}

.meta {
    grid-area: meta;
    margin-top: $grid * 0.5;
    font-size: $font-size_small;

    > * {
        display: inline-block;
        margin-right: $grid * 2;
        margin-top: $grid * 0.5;
    }

    &--big {
        @include media('>=small') {
            font-size: $font-size_primary;
        }
    }

    .icon {
        position: relative;
        top: -1px;
    }

    @include media('<phone-large') {
        display: none;
    }

    &-extended {
        @include media('>small') {
            display: none;
        }
    }
}

.VideoCard--simple {
    padding: $grid * 0.5;
    box-shadow: 0 0 2px 1px $color_gallery;

    .meta-extended {
        display: inline-block;
    }
}

.additionals {
    display: grid;
    align-items: center;
    align-self: start;
    grid-gap: $grid * 2;
    min-height: $grid * 9;
    grid-template-columns: 4fr 2fr 3fr 2fr;
    grid-auto-columns: 2fr;
    grid-auto-flow: column;

    @include media('<=small') {
        display: none;
    }

    > *:first-child {
        content: '';
        border-left: 1px solid $color_concrete;
        padding: $grid $grid $grid $grid * 2;
    }
}

.date {
    align-items: center;
    display: grid;
    grid-template-columns: auto 1fr;
}

.author {
    justify-self: center;
}

.provider {
    overflow: hidden;
    text-overflow: ellipsis;
}

.input-info:before {
    content: '';
    width: 3px;
    height: 3px;
    border-radius: 50%;
    margin: $grid * 0.5 $grid $grid * 0.5 $grid;
    background: $color_ui-w90;
    display: inline-block;
}
