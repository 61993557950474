$baseUrl: "/";
@import '_utils';
@import 'screens/NewsroomScreen/PlaylistsScreen/PlaylistsFormItems.module.scss';
.container {
    position: relative;

    &:hover {
        .button {
            display: block;
        }
    }
}

.videoCard {
    min-width: 400px;
    padding: $grid * 2;
    margin: 1px;
    position: relative;
}

.removeButton {
    display: none;
}

.button {
    display: none;

    position: absolute;
    right: 25px;
    top: 45px;
}

.playlist {
    font-size: $font-size_medium;
    margin-right: $grid;
    border: 0;
    box-shadow: 0 0 2px 1px $color_gallery;

    &:hover {
        padding-right: 80px;
    }
}

.button:hover ~ .playlist {
    padding-right: 80px;
    background-color: $color_alabaster2;
}

.newsroom {
    font-weight: 700;
    color: $color_scorpion;
}

.category {
    font-style: italic;
    padding-left: 10px;
    color: $color_scorpion;
}

.search {
    padding-bottom: 0;
}

.scroll {
    max-height: 400px;

    overflow-x: hidden;
    overflow-y: auto;
}

.staticItems {
    padding-top: 0;
    padding-bottom: 0;
    max-height: 400px;

    overflow-x: hidden;
    overflow-y: auto;
}

.dialog {
    max-width: 630px !important;
    overflow: initial;
}

.smallSpinner {
    @include spinner;
    padding-top: 10px;
}
