$baseUrl: "/";
@import "_config";

.caption {
    grid-area: caption;
    justify-self: end;
    z-index: 1;
    letter-spacing: $grid * 0.125;
}

.label {
    color: $color-boulder;
    letter-spacing: $grid * 0.25;
    text-transform: uppercase;
    font-weight: $font-weight_semibold;
    margin-right: $grid;
}
