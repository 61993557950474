$baseUrl: "/";
@import '_utils';

.item {
  --alpha: 0.9;

  overflow: hidden;
  border: 1px solid $color-white;
  padding: 5px 15px;
  border-radius: 5px;
  background-color: rgba($color-white, 0.9);
  white-space: pre;
  filter: drop-shadow(0 0 2px rgba(33, 33, 33, 0.1));
  display: flex;
  align-items: center;
  line-height: 18px;
  transition: background 100ms linear, border-color 100ms linear;

  &-highlighted {
    border-color: $color_sunflower;
    background-color: rgba($color_corn-field, var(--alpha));
  }

  &:hover,
  &-active {
    --alpha: 0.7;
    background-color: rgba($color-white, var(--alpha));
  }

  &:hover#{&}-highlighted,
  &-active#{&}-highlighted {
    background-color: rgba($color_corn-field, var(--alpha));
  }
}
