$baseUrl: "/";
@import "_utils";

.dvrInfo {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    align-items: center;
    gap: $grid * 0.5;
    margin-top: $grid * 3;
}

.audioCheckbox {
    margin-top: $grid * 3;
}