$baseUrl: "/";
@import '_utils';

.notification {
    padding: 0 $grid * 4;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $color_corn-field;
    color: $color_buddha_gold;
    font-weight: $font-weight_semibold;
}

.layout {
    display: grid;
    grid-template-columns: 5fr 4fr;
    grid-template-areas: "form player";
    grid-gap: $grid * 4;
}

.player {
    grid-area: player;
}

.form {
    grid-area: form;
}
