$baseUrl: "/";
@import '_utils';

.dropzone {
    height: 230px;
}

.burnInLogo {
    margin-top: 4 * $grid;
}

.tabsContainer {
    padding-bottom: 0;
}

.fileTypes{
    margin-bottom: $grid*1.5;
}