$baseUrl: "/";
@import "_config";
@import "_utils";

@include block(image) {
    display: inline-block;
    position: relative;
    line-height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    width: 100%;

    @include element(item) {
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;
        width: 100%;
    }

    @include modifier(ratioless) {
        position: static;
        padding-bottom: 0;
        overflow: auto;

        @include element(item) {
            position: static;
            top: auto; left: auto; right: auto; bottom: auto;
        }
    }
}
