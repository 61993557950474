$baseUrl: "/";
@import "_utils";

.spinner {
    display: flex;
    justify-content: center;
}

.error {
    display: grid;
    grid-auto-flow: row;
    gap: $grid;
    justify-content: center;
    justify-items: center;
    padding: $grid * 2 0;
}
