$baseUrl: "/";
@import "_config";

.optionsSpinner {
    display: block;
    text-align: center;
    margin: 10px auto;
}

.selectSpinner {
    padding: 0 $grid * 0.5;
    > * {
        vertical-align: sub;
        width: $grid * 2;
    }
}

.tagIcon {
    margin-right: 4px;
    vertical-align: -2px;
}
