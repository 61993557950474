$baseUrl: "/";
@import "_utils";

@mixin hiddenButton {
    visibility: hidden;
    pointer-events: none;
    user-select: none;
}

.container {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-template-areas: "confirm cancel";
    grid-column-gap: $grid;

    &--dropdown#{&}--size-big {
        margin-right: -$grid * 6 !important;

        .regularButton {
            margin-right: $grid * 6;
        }
    }

    &--dropdown#{&}--size-small {
        margin-right: -$grid * 4 !important;

        .regularButton {
            margin-right: $grid * 4;
        }
    }

    &--dropdown#{&}--size-compact {
        margin-right: -$grid * 3 !important;

        .regularButton {
            margin-right: $grid * 3;
        }
    }

    &--dropdown#{&}--confirm {
        position: relative;
        z-index: 1;
    }

    &--connected {
        grid-column-gap: 0;

        .regularButton--expanded,
        .confirmButton {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }

        .cancelButton {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
        }
    }
}

.regularButton {
    grid-row: 1;
    grid-column: 1 / -1;
    justify-self: left;

    &--expanded {
        justify-self: unset;
    }

    &--hidden {
        @include hiddenButton;
    }
}

.confirmButton {
    grid-area: confirm;

    &--hidden {
        @include hiddenButton;
    }
}

.cancelButton {
    grid-area: cancel;

    &--hidden {
        @include hiddenButton;
    }
}
