$baseUrl: "/";
@import '_config';
.row {
    border-bottom: 2px solid $color_concrete;
}

.row-highlighted {
    animation: highlightListItem 1000ms ease-in;
}

.notes {
    max-width: $grid * 40;
    padding: $grid;
    white-space: pre-line;
    text-align: left;
}

.notes-container {
    display: block !important;
    text-align: center;
}

.notes-icon {
    color: $color_medium-blue;
}

.spinner {
    display: block;
    text-align: center;
    padding: $grid * 2;
}

.no-results-info {
    display: block;
    padding: $grid * 2;
    font-size: $font-size_super-large;
    line-height: 30px;
    text-align: center;
    padding: 100px 0;
}

.search-icon {
    opacity: 0.15;
}

@keyframes highlightListItem {
    0% {
        background-color: transparent;
    }
    30% {
        background-color: $color_silver;
    }
    100% {
        background-color: transparent;
    }
}
