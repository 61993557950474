$baseUrl: "/";
@import "_config";
@import '_utils';

@include block(NewsroomSelectScreen) {
  margin: ($grid * 5) auto;
  max-width: 320px;
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: $grid * 2;
    padding: $grid;
    border-bottom: 1px solid $color_light-grey;
    font-size: $font-size_extra-large;
    font-weight: $font-weight_semibold;
    line-height: $grid * 4;
    &::after {
      background: url(#{$baseUrl}assets/stream-logo.svg) center center/contain no-repeat;
      content: '';
      display: inline-block;
      width: $grid * 4;
      height: $grid * 4;
      margin-left: auto;
    }
  }
}
