$baseUrl: "/";
@import '_config';

.EmbedDialog {
    max-width: 1100px;

    &__copyButton {
        transition: background 0.4s;
        width: 120px;

        &--copied,
        &--copied:hover {
            transition-duration: 0.2s;
            background: $color_salem;
        }
    }
}
