$baseUrl: "/";
@import "_config";

.progressBar {
    grid-area: progress;
    margin-top: $grid * 0.25;
    height: $grid * 0.5;
    background-color: transparent;
    transition: background-color 3s cubic-bezier(0.63, 0.08, 1, 0.1);

    &--upload {
        background-color: $color-regent-st-blue;
        transition: width 1s linear;
    }

    &--transcode, &--convert {
        background-color: $color-caper;
        transition: width 1s linear;
    }
}
