$baseUrl: "/";
@import '_mixins';
@import '_utils';

@mixin previewTexts($visible: false) {
    @if $visible == true {
        transition-delay: 0.4s;
        opacity: 1;
        max-height: unset;
    } @else {
        transition-delay: 0.0s;
        opacity: 0;
        max-height: 0;
    }
}

@mixin previewCover($visible: false) {
    @if $visible == true {
        opacity: 1;
        pointer-events: all;
    } @else {
        opacity: 0;
        pointer-events: none;
    }
}

.playerContainer {
    &--controls {
        background-color: $color_background-grey;
        padding: 0 $grid * 0.5 $grid * 0.5;
        border: 1px solid $color_background-dark-grey;

        .playerPreview {
            display: flex;
            flex-wrap: wrap;
            aspect-ratio: 2.38;
            overflow: hidden;
        }

        .encoderPreview {
            flex-basis: 75%;
            padding-left: $grid * 0.5;
            text-align: right;
        }

        .livePreview,
        .encoderPreview {
            position: relative;
            transition: flex-basis 0.3s linear;
            display: flex;
            flex-direction: column;
            align-items: stretch;
        }

        .livePreview {
            flex-basis: 25%;
            padding-right: $grid * 0.5;

            .previewCover {
                @include previewCover(true);
            }
            .previewCaption,
            .previewInfo {
                @include previewTexts(true);
            }
        }

        .encoderPreview {
            .previewCover {
                @include previewCover();
            }
            .previewCaption,
            .previewInfo {
                @include previewTexts();
            }
        }
    }

    &--controls#{&}--livePreview {
        .livePreview {
            flex-basis: 75%;

            .previewCover {
                @include previewCover();
            }
            .previewCaption,
            .previewInfo {
                @include previewTexts();
            }
        }

        .encoderPreview {
            flex-basis: 25%;

            .previewCover {
                @include previewCover(true);
            }
            .previewCaption,
            .previewInfo {
                @include previewTexts(true);
            }
        }
    }
}

.playerState {
    padding: $grid * 2 $grid;
    text-align: right;
}

.livePreviewPlayer {
    position: relative;
}

.previewCover {
    @include positionAbsolute(0, 0, unset, 0);
    z-index: 101;
    aspect-ratio: 16/9;
    cursor: pointer;
    transition: opacity 0.1s linear 0.0s;
}

.previewCaption {
    flex: 1;

    > h4 {
        display: inline-block;
        margin-top: $grid;
        padding: $grid;
        font-size: $font-size_primary;
        border-bottom: 1px solid $color_light-grey;
        cursor: pointer;
    }
}

.previewInfo {
    font-size: $font-size_small;

    > p {
        width: 90%;
        margin: $grid;
        padding-bottom: $grid;
        border-bottom: 1px solid $color_light-grey;
    }
}

.previewCaption,
.previewInfo {
    transition: opacity 0.1s ease-out 0.0s;
    opacity: 0;
    overflow: hidden;
}

.previewInfoIcon {
    margin: 0 $grid;
    color: $color-dove-gray2;
    font-size: $font-size_large;
}

.sourceIndicator {
    margin: 0 0.4rem;
    color: $color_dusty-gray;
    font-size: $font-size_small;
    vertical-align: -0.1rem;
    border-radius: 50%;
    box-shadow: $color_placeholder-grey 0 0 5px;
    transition: color 0.1s;

    &--active {
        color: $color_atlantis;
    }
}

.playerShortcuts {
    display: flex;
    justify-content: flex-end;
    margin-top: $grid * 0.5;
}
