$baseUrl: "/";
@import '_config';

.container {
    margin: $grid 0;
}

.label {
    margin-bottom: $grid * 2;
}
