$baseUrl: "/";
@import '_config';

.content {
    display: flex;
    flex-direction: column;
    padding: ($grid * 4.5) ($grid * 2);
    background-color: $color_background-grey;
    gap: $grid * 2;
}

.doubleCol {
    display: grid;
    grid-column-gap: $grid * 2;
    grid-template-columns: 1fr 1fr;
}
