$baseUrl: "/";
@import "_utils";

.StreamDetails {
    margin-top: $grid;
    margin-bottom: -$grid;
    min-height: 30px;
    font-size: $font-size_big;
}

