$baseUrl: "/";
@import "_config";
@import "_utils";

@include block(tag) {
    & + & {
        &::before {
            content: ", ";
        }
    }

    @include modifier(error) {
        font-style: italic;
    }
}
