$baseUrl: "/";
@import '_config';
@import '_utils';

@mixin barPosition {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
}

@include block(video-navigation-container) {
    display: flex;
}

@include block(video-navigation) {
    $height: $grid * 7;
    $border-size: 1px;
    $border: $border-size solid $color-geyser;

    display: flex;
    position: relative;
    height: $height;

    &--fill {
        flex: 1;
        border-bottom: $border;
    }

    &--scrollable {
        overflow-x: auto;

        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
            background-color: inherit;
        }

        &::-webkit-scrollbar-track:horizontal {
            border: 2px solid $color-geyser;
        }

        &::-webkit-scrollbar-thumb:horizontal {
            border: 2px solid $color-scooter;
        }
    }
    &__tab-spinner {
        margin-right: $grid;
        padding: 2px;
    }

    &__tab {
        display: flex;
        position: relative;
        cursor: pointer;
        color: $color-outer-space;
        background: $color-black-haze;
        padding: 0 $grid * 3;
        border: $border;
        border-left: none;

        &:hover {
            background: $color-aqua-haze;
            color: $color-outer-space;
            text-decoration: none;
            transition: 300ms;
        }

        &--active {
            border-bottom: 1px solid $color-white;
            background: $color-white;
            color: $color-orient;

            &:after {
                @include barPosition;
                background: $color-scooter;
                height: $grid * 0.5;
            }

            &:hover {
                background: $color-white;
                color: $color-orient;
            }
        }

        &--error {
            &:after {
                @include barPosition;
                background: $color-tamarillo;
                height: $grid * 0.5;
            }
        }

        &-icon {
            overflow: visible;
            margin-right: $grid;
            rect {
                stroke: $color-mine-shaft;
            }
            path {
                fill: $color-mine-shaft;
            }
        }

        &--active &-icon {
            rect {
                stroke: $color-orient;
            }
            path {
                fill: $color-orient;
            }
        }

        &-content {
            display: flex;
            align-items: center;
            position: relative;
            white-space: nowrap;
        }

        &-header {
            text-transform: uppercase;
            padding-bottom: 3px;
            color: $color-tundora;
            letter-spacing: 1px;
            font-size: $grid * 1.5;
        }

        &--active &-header {
            color: $color-orient;
        }

        &-title {
            max-width: $grid * 20;
            line-height: 1.3;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 600;
        }

        &-progress {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            background-color: transparent;
            transition: background-color 3s cubic-bezier(0.63, 0.08, 1, 0.1);

            &--upload {
                background-color: $color-regent-st-blue;
                transition: width 1s linear;
            }

            &--transcode,
            &--convert {
                background-color: $color-caper;
                transition: width 1s linear;
            }
        }

        &-close {
            visibility: hidden;
            top: $grid * 0.75;
            right: $grid * 0.75;
            position: absolute;
            width: $grid * 1.5;
            height: $grid * 1.5;
            padding: $grid * 0.125;
            border-radius: 50%;

            &:hover {
                fill: $color-white;
                background: $color-deep-cerulean;
                transition: 300ms;
            }
        }

        &:hover &-close {
            visibility: visible;
        }
    }
}
