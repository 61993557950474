$baseUrl: "/";
@import '_config';
@import '_mixins';

.container {
    position: relative;
    overflow: hidden;
}

.hovered {
    .dropzone {
        z-index: 1;

        > * {
            visibility: visible;
        }
    }
}

.dropzone {
    height: max-content;
    min-height: 200px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.player {
    z-index: 1;
    position: relative;
    padding: $grid;
    background-color: transparent;
    text-align: center;
    display: block;
    margin: 0 auto;

    video {
        width: 100%;
    }
}
