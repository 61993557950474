$baseUrl: "/";
@import '_utils';
.next-video-container {
    position: relative;
    min-height: 118px;
}

.selected-videos-container {
    margin-bottom: $grid * 2;
    position: relative;
    .assets-container {
        min-height: 101px;
        max-height: 280px;
        overflow: hidden;
        overflow-y: auto;
    }
}

.search-container {
    background: $color_background-grey;
    padding: $grid;
    .assets-container {
        max-height: 280px;
    }
}

.heading {
    position: relative;
    padding-bottom: $grid * 1.5;
    color: $color_ui-w90;
    font-size: $font-size_big;
    font-weight: $font-weight_semibold;
}

.spinner {
    display: grid;
    position: absolute;
    align-content: center;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
}

.staticVideoCard {
    margin: $grid;
    background-color: $color_white;
    border: 1px dashed transparent;
}
