$baseUrl: "/";
@import '_config';

.highlighted {
    position: relative;

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 2px;
        left: -16px;
        width: $grid;
        height: $grid;
        background-color: $color_yellow;
        border-radius: 50%;
    }
}

.copy {
    &:hover {
        font-weight: $font-weight_regular;
    }
}
