$baseUrl: "/";
@import '_config';

.error {
    display: grid;
    justify-content: center;
    text-align: center;
    font-size: $font-size_large;
    img {
        padding-top: 40px;
    }
}

.travolta {
    width: 15vw;
    min-width: max-content;
    justify-self: center;
}
