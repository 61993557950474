$baseUrl: "/";
@import '_utils';

$accent-color: $color_bluey-purple;
$controls-height: 70px;

.player {
    background-color: unset !important;
    padding-bottom: $controls-height;

    div[class*="jwplayer"] {
        video::cue {
            font-size: $font-size_extra-large;
        }

        div[class*="jw-captions"],
        div[class*="jw-settings-submenu-button"],
        div[class*="jw-icon-pip"],
        div[class*="jw-icon-fullscreen"] {
            display: none;
        }

        div[class*="jw-wrapper"] {
            bottom: $controls-height;
            background-color: unset;
        }

        div[class*="jw-controls"] {
            overflow: unset;
        }

        div[class*="jw-controls-backdrop"] {
            background: unset;
        }

        div[class*="jw-controlbar"] {
            bottom: -$controls-height;
            visibility: visible !important;
            opacity: 1 !important;

            [class*="jw-text"] {
                color: $color_dark-grey;
                filter: drop-shadow(0 0 1px rgba($color_black, .2));
            }

            [class*="jw-svg-icon"] {
                fill: $accent-color;
                filter: drop-shadow(0 0 1px rgba($color_black, .3));

            }
        }

        [class*="jw-slider-container"] [class*="jw-rail"],
        [class*="jw-timesegment-background"] {
            background-color: $color_background-grey !important;
        }

        [class*="jw-slider-container"] [class*="jw-buffer"],
        [class*="jw-timesegment-buffered"] {
            background-color: rgba($accent-color, .3) !important;
        }

        [class*="jw-slider-container"] [class*="jw-progress"],
        [class*="jw-timesegment-progress"] {
            background-color: rgba($accent-color, .8) !important;
        }

        [class*="jw-knob"] {
            color: rgba($accent-color, .8) !important;
            background-color: $accent-color;
        }
    }
}
