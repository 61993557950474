$baseUrl: "/";
@import '_utils';

.instruction {
    font-size: $font-size_big;
    padding-bottom: $grid * 2;
    max-width: 430px;
    min-width: 400px;
    line-height: 23px;
    text-align: center;

    b {
        font-weight: 600;
    }
}

.error {
    font-size: $font-size_large;
    font-weight: 500;
}

.password {
    min-width: 370px;
}

.logIn {
    margin-top: $grid * 3;
    width: 100%;
}

.suggestion {
    text-align: center;
    margin-top: 20px;
}

.link {
    font-weight: 600;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.margin {
    margin-top: $grid * 2;
}
