$baseUrl: "/";
@import "_utils";

@include block(Playback) {
    display: grid;
    grid-gap: $grid;
    margin-bottom: $grid;
    grid-template-columns: repeat(auto-fill, minmax(max-content, 80px));

    &__input {
        grid-template-columns: 80px;
    }
}
