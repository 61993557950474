$baseUrl: "/";
@import '_config';
@import '_functions';

:export {
    silver2: $color_silver2;
}

.container {
    background-color: #f3f5f5;
    padding: $grid;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'previews meta';
}

.videoPreviews {
    grid-area: previews;

    display: grid;
    align-content: start;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(auto-fit, pxToEm(37));
    grid-gap: $grid * 0.5;

    &--big {
        grid-template-columns: 1fr;
        grid-template-rows: auto;

        align-content: center;
    }

    &--medium {
        grid-template-columns: 0.7fr;
        grid-template-rows: 1fr 1fr;
        justify-content: center;

        padding-top: 3px;
    }
}

.video {
    height: 36px;
}

.meta {
    height: 112px;

    grid-area: meta;
    margin-top: $grid*0.5;
    margin-left: $grid * 2.5;

    display: grid;
    grid-template-rows: 50px 1fr;
    grid-template-areas: 'title' 'info';
    grid-gap: pxToEm(20);
}

.name {
    grid-area: title;
    font-weight: 600;
    font-size: 15px;
    align-self: start;
    padding-top: $grid;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    text-overflow: ellipsis;

    &--centered {
        align-self: center;
    }
}

.info {
    font-size: 14px;

    align-self: end;
    padding-bottom: $grid;
}

.infoLength {
    padding-bottom: $grid*0.5;
    svg {
        margin-right: 15px;
        width: 17px;
        height: 17px;
    }
}

.infoDuration {
    svg {
        margin-right: 15px;
        width: 17px;
        height: 17px;
    }
}
