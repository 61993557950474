$baseUrl: "/";
@import 'theme';
.Button {
    display: block;
    border: none;
    cursor: pointer;
    text-overflow: ellipsis;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    background-color: $sru-bluey-purple;
    color: $sru-white;
    border-radius: 4px;
    height: $sru-grid * 6;
    padding: $sru-grid * 2 $sru-grid * 4;
    font-size: 16px;
    font-weight: 600;
    line-height: 14px;
    &:hover {
        background-color: $sru-iris;
    }
    &:active {
        background-color: $sru-daisy-bush;
    }
    &--selected,
    &--selected:hover,
    &--selected:active {
        cursor: default;
        background-color: $sru-jagger;
    }
    &--disabled,
    &--disabled:hover,
    &--disabled:active {
        background-color: $sru-ui-c30;
        cursor: not-allowed;
        color: $sru-gull-gray;
    }
    .Label {
        vertical-align: text-top;
    }
    &--small {
        height: $sru-grid * 4;
        padding: $sru-grid $sru-grid * 2;
        border-radius: 2px;
    }
    &.Button--small.Button--label-loading {
        padding: $sru-grid $sru-grid * 3 - 2;
    }
    &--compact {
        height: $sru-grid * 3;
        padding: $sru-grid * 0.5 $sru-grid;
        border-radius: 2px;
        line-height: 14px;
        font-size: 14px;
    }
    &.Button--compact.Button--label-loading {
        padding: 4px 20px;
    }
    &--loading,
    &--loading:hover,
    &--loading:active {
        background-color: $sru-daisy-bush;
        cursor: default;
    }
    .Spinner {
        margin-top: -7px;
        width: 30px;
    }
    &--small .Spinner {
        margin-top: -4px;
        width: 25px;
    }
    &--compact .Spinner {
        margin-top: -2px;
        width: 20px;
    }
    .Spinner--left {
        margin: -2px;
        position: absolute;
        left: 9px;
        width: 20px;
    }
    &--compact .Spinner--left {
        left: 8px;
        top: 8px;
        width: 11px;
    }
    &--small .Spinner--left {
        left: 8px;
        top: 11px;
        width: 14px;
    }
    &--big.Button--iconOnly.Button--iconOnly {
        height: $sru-grid * 6;
        width: $sru-grid * 6;
        padding: $sru-grid;
    }
    &--small.Button--iconOnly.Button--iconOnly {
        height: $sru-grid * 4;
        width: $sru-grid * 4;
        padding: $sru-grid * 0.5;
    }
    &--compact.Button--iconOnly.Button--iconOnly {
        height: $sru-grid * 3;
        width: $sru-grid * 3;
        padding: $sru-grid * 0.25;
    }
}

.Button--standard.Button {
    padding: $sru-grid * 2 - 1 $sru-grid * 4;
    background-color: $sru-white; // color: $sru-purple-heart; color: $sru-purple-heart;
    color: $sru-purple-heart;
    border: 1px solid $sru-cloudy-blue;
    &:hover {
        border-color: $sru-iris;
    }
    &:active {
        color: $sru-daisy-bush;
    }
    &--selected,
    &--selected:hover,
    &--selected:active {
        background-color: $sru-cloudy-blue;
        color: $sru-ui-w100;
        border-color: $sru-gull-gray;
    }
    &--disabled,
    &--disabled:hover,
    &--disabled:active {
        background-color: $sru-white;
        border-color: $sru-mystic;
        color: $sru-gull-gray;
    }
    &--small {
        padding: $sru-grid - 1 $sru-grid * 2;
    }
    &--compact {
        padding: $sru-grid * 0.5 $sru-grid;
    }
}

.Button--danger.Button {
    background-color: $sru-guardsman-red;
    color: $sru-white;
    &:hover {
        background-color: $sru-pomegranate;
    }
    &:active {
        background-color: $sru-dark-red;
    }
    &--selected,
    &--selected:hover,
    &--selected:active {
        background-color: $sru-tamarillo;
        color: $sru-white;
        border-color: $sru-tamarillo;
    }
    &--disabled,
    &--disabled:hover,
    &--disabled:active {
        background-color: $sru-ui-c30;
        color: $sru-gull-gray;
    }
}

.Button--warning.Button {
    background-color: $sru-sunflower;
    color: $sru-white;
    &:hover {
        background-color: $sru-ronchi;
    }
    &:active {
        background-color: $sru-anzac;
    }
    &--selected,
    &--selected:hover,
    &--selected:active {
        background-color: $sru-hokey-pokey;
        color: $sru-white;
        border-color: $sru-hokey-pokey;
    }
    &--disabled,
    &--disabled:hover,
    &--disabled:active {
        background-color: $sru-ui-c30;
        color: $sru-gull-gray;
    }
}

.Button--notice.Button {
    background-color: $sru-white;
    color: $sru-sahara;
    border: 1px solid $sru-cloudy-blue;

    &:hover {
        border: 1px solid $sru-sunflower !important;
    }
    &:active {
        background-color: $sru-white;
    }
    &--selected,
    &--selected:hover,
    &--selected:active {
        background-color: $sru-geyser;
        color: $sru-old-gold;
        border-color: $sru-hokey-pokey;
    }
    &--disabled,
    &--disabled:hover,
    &--disabled:active {
        background-color: $sru-ui-c30;
        color: $sru-gull-gray;
    }
}
