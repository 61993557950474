$baseUrl: "/";
@import '_config';
@import 'theme';

.container {
    display: grid;
    grid-template-areas: 'fields images' 'others images';
    grid-template-columns: minmax(320px, 600px) minmax(400px, 600px);
    grid-template-rows: auto 1fr;
    align-items: start;
    column-gap: 12%;
    row-gap: $grid * 4;
    margin-top: $grid * 8;
}

.fields {
    grid-area: fields;
    display: grid;
    grid-template-columns: minmax(320px, 600px);
    row-gap: $grid * 3;
}

.others {
    grid-area: others;
    display: grid;
    gap: $grid * 2;
}

.images {
    grid-area: images;
    display: grid;
    row-gap: $grid * 4;
    height: min-content;
}

.archiveButton {
    justify-self: start;
    margin-top: $grid * 2;
}

.saveButton {
    justify-self: end;
}

.dropzone {
    height: 200px;
}

.preview {
    display: flex;
    flex-direction: column;
}

.deletePreview {
    margin-top: $grid * 1.5;
    max-width: 80px;
    text-align: right;
    align-self: flex-end;
}
