$baseUrl: "/";
@import "_utils";

.stoppingInfo {
    margin-top: $grid;
    margin-left: $grid * 0.5;

    p {
        margin-bottom: 5px;
    }
}
