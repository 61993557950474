$baseUrl: "/";
@import '_utils';

.checkbox {
    margin-bottom: 40px;
    > div {
        margin-left: 30px;
    }
    > p {
        margin-top: 5px;
        margin-left: 33px;
    }
}
.checkboxInput {
    font-size: $font-size_big;
    font-weight: $font-weight_semibold;
    color: $color_ui-w90;
    input {
        margin-right: 15px;
    }
}

.dateTime {
    margin-top: 20px;
}

.distributeAfter {
    display: flex;
    flex-direction: column;
    gap: $grid;
}

.synchronize {
    align-self: self-start;
}
