$baseUrl: "/";
@import "_utils";

.poster {
    max-width: 100%;
}

.audio {
    height: 256px;
}

.highlighted {
    border: 2px solid $color_yellow;
}
