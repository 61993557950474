$baseUrl: "/";
@import '_utils';

.tab {
    cursor: pointer;
    margin-right: $grid * 3;
    padding: $grid 0;
    margin-bottom: -3px;
    white-space: nowrap;

    &:hover {
        color: $color_iris;
        white-space: nowrap;
    }
    &--active {
        border-bottom: 4px solid $color_purple-navigation;
        color: $color_purple-navigation;
    }

    &--disabled {
        color: $color_gull-gray;

        &:hover {
            color: $color_gull-gray;
            cursor: no-drop;
        }
    }
}

.tooltip {
    transform: translateY($grid * 2);
    padding: calc($grid/2) $grid;
}
