$baseUrl: "/";
@import 'theme';
.Input {
    display: block;
    width: 100%;
    border: 1px solid $sru-ui-c30;
    border-radius: $sru-grid * 0.5;
    box-shadow: inset 0 0 4px 1px $sru-gallery, 0 0 2px 1px $sru-gallery;
    color: $sru-ui-w90;
    padding: $sru-grid * 2 - 2 $sru-grid * 2; // subtracting 2px (border) due to border-box box sizing
    font-size: 16px;
    height: $sru-grid * 6;
    &--error {
        border-color: $sru-dark-red;
    }
    &--small {
        height: $sru-grid * 4;
        border-radius: $sru-grid * 0.25;
        padding: $sru-grid - 2 $sru-grid; // subtracting 2px (border) due to border-box box sizing
    }
    &--compact {
        height: $sru-grid * 3;
        border-radius: $sru-grid * 0.25;
        padding: $sru-grid * 0.5 - 2 $sru-grid * 0.5; // subtracting 2px (border) due to border-box box sizing
    }
    &--disabled {
        background-color: $sru-ui-c30;
        cursor: not-allowed;
        color: $sru-gull-gray;
        font-weight: 600;
        box-shadow: none;
    }
    &--highlighted {
        border: 2px solid $sru-yellow;
    }
    &--textarea {
        height: auto;
    }
}

.InputWrapper {
    position: relative;
    display: block;
    > :last-child {
        position: absolute;
        bottom: 0;
        margin: auto $sru-grid;
        width: $sru-grid * 3 - 4;
        height: $sru-grid * 3 - 4;
        top: 0;
    }
    &--small > :last-child,
    &--compact > :last-child {
        width: $sru-grid * 2;
        height: $sru-grid * 2;
    }
    &--compact > :last-child {
        width: $sru-grid * 2 - 2;
        height: $sru-grid * 2 - 2;
    }
    &--leftIcon {
        .Input {
            padding-left: $sru-grid * 5;
        }
        .Input--small {
            padding-left: $sru-grid * 4;
        }
        .Input--compact {
            padding-left: $sru-grid * 3 + 2;
        }
    }
    &--rightIcon {
        > :last-child {
            right: 0;
        }
        .Input {
            padding-right: $sru-grid * 5;
        }
        .Input--small {
            padding-right: $sru-grid * 4;
        }
        .Input--compact {
            padding-right: $sru-grid * 3 + 2;
        }
    }
}
