$baseUrl: "/";
@import '_utils';

.slider {
    grid-area: primary;
    width: 100%;
    position: relative;
}

.selector {
    --resize-area-size: 9px;

    pointer-events: none;
    position: absolute;
    top: 20px;
    width: 0;
    border-width: 1px var(--resize-area-size);
    border-style: solid;
    border-color: $color_bluey-purple;
    border-radius: 3px;
    height: 60px;
    z-index: 25;

    &::before,
    &::after {
        width: 3px;
        height: $grid * 2.5;
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border-width: 0 1px;
        border-style: solid;
        border-color: $color_white;
        z-index: 20;
    }

    &::before {
        left: -6px;
    }

    &::after {
        right: -6px;
    }
}
