$baseUrl: "/";
@import "_utils";

.questionMark {
    width: 10px;
    height: 10px;
    margin-left: 0.5em;
    display: inline-block;
    vertical-align: -0.1em;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 10 10'%3E%3Cdefs%3E%3Cpath id='a' d='M0 0h10v10H0z'/%3E%3Cmask id='b' maskContentUnits='userSpaceOnUse' maskUnits='userSpaceOnUse'%3E%3Cpath d='M0 0h10v10H0z'/%3E%3Cuse fill='%23fff' xlink:href='%23a'/%3E%3C/mask%3E%3C/defs%3E%3Cuse fill='none' xlink:href='%23a'/%3E%3Cg mask='url(%23b)'%3E%3Cpath fill='%23424242' fill-rule='evenodd' d='M5 0a5 5 0 100 10A5 5 0 005 0zm.559 5.874c0-.434.191-.613.48-.884.338-.315.799-.747.798-1.627 0-.497-.172-.913-.497-1.206-.321-.287-.777-.44-1.32-.44-1.037 0-1.752.608-1.823 1.548l-.024.313c-.006.08.053.149.132.157l.796.08a.147.147 0 00.161-.136l.024-.33c.012-.16.04-.54.727-.54.733 0 .733.411.733.546 0 .428-.19.606-.478.876-.338.317-.801.752-.801 1.643v.321c0 .081.066.147.147.147h.797a.147.147 0 00.148-.147v-.32zm-.57 2.409a.671.671 0 11.002-1.343.671.671 0 01-.002 1.343z'/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
}
