$baseUrl: "/";
@import '_utils';

.greybox {
    background-color: $color_background-grey;
    margin-bottom: $grid * 2;
    padding: $grid * 2 $grid $grid * 2;
}
.list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(132px, 1fr));
    grid-gap: $grid;
    background: $color_background-grey;
    padding: $grid;
}
.preview-hover {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 100;
}

.embed-button {
    padding: 7px;
}

.preview-container {
    position: relative;
}

.highlight {
    cursor: pointer;
    background: $color_white;
    position: relative;
    padding: $grid * 0.5;
    display: grid;
    grid-template-rows: auto 1fr auto;
    box-shadow: 0 0 2px 1px #eee;

    &:hover {
        background-color: $color_alabaster2;

        .preview-hover {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .embed-button,
        .delete-button {
            margin: 5px;
        }

        .delete-button {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .deleteIcon {
            width: 2vw;
        }
    }
}

.time {
    display: flex;
    align-items: center;
    svg {
        color: $color_silver2;
        margin-right: $grid * 0.5;
    }
}

.title {
    font-size: $font-size_medium;
    font-weight: $font-weight_semibold;
    display: block;
    margin: $grid 0;
    overflow: hidden;
    text-overflow: ellipsis;
}
