$baseUrl: "/";
@import '_utils';

.contactContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    line-height: 28px;
    margin-top: 35px;
    font-size: $font-size_big;
}

.contact {
    text-align: left;
    font-size: $font-size_regular;
    margin-left: 140px;
}

.icon {
    margin-right: $grid;
}

.link {
    &:hover {
        color: $color_teal_blue;
        text-decoration: underline;
    }
}
