$baseUrl: "/";
@import '_config';

.EmbedForm {
    display: grid;
    grid-template-columns: min-content auto;
    grid-template-rows: repeat(2, max-content);
    grid-template-areas:
        'preview attributes'
        'options options';
    grid-gap: $grid * 2;

    &__playerSize {
        display: flex;
        flex-direction: column;
    }

    &__optionsWrapper {
        display: flex;
        align-items: center;
    }

    &__options {
        grid-area: options;
        margin-top: 10px;

        :first-child {
            margin-bottom: 0;
        }
    }

    &__embedCode {
        flex-grow: 1;
        margin-top: $grid * 2;

        textarea {
            height: 100%;
            min-height: 150px;
            resize: none;
        }
    }
    &__startTime {
        width: 95px;
    }

    &__embedUrl {
        grid-column: 1 / -1;

        textarea {
            height: 100%;
            min-height: 20px;
            resize: none;
            padding: 0;
        }
    }
}

.copy-icon {

    svg {
        margin-right: 3px;
        vertical-align: bottom !important;
    }
    &:hover {
        cursor: copy;
        font-weight: $font-weight_bold;
    }
    &:active {
        font-weight: $font-weight_bold;
    }
}
