$baseUrl: "/";
@import '_config';
@import '_functions';

:export {
    silver2: $color_silver2;
}

.item {
    font-size: pxToEm(12);
    padding: pxToEm(8, 12);
    background: $color_background-grey;
    display: grid;
    align-items: end;
    grid-template: 'preview preview' max-content 'id additional' / 1fr 1fr;
    height: pxToEm(122, 12);
}
.itemPreview {
    grid-area: preview;
}

.itemId {
    grid-area: id;
}

.itemAdditional {
    grid-area: additional;
    justify-self: end;
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: pxToEm(2);

    span {
        display: flex;
        align-items: center;
        gap: pxToEm(2);
    }

    svg {
        height: pxToEm(15);
        width: pxToEm(15);
    }
}
