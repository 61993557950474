$baseUrl: "/";
@import '_utils';

.nextVideoList {
    border: 2px dashed $color-geyser;
}

.isPlaylist {
    border: 2px dashed $color_bluey-purple;
}

.hovered .list--filled {
    padding-bottom: 98px;
}

.info {
    margin: $grid * 4 auto;
    max-width: 400px;
    color: $color_dusty-gray;
}

.infoTitle {
    margin: 0;
    font-weight: bold;
    line-height: 1.6em;
    color: $color_dusty-gray;
}

.disableNextVideo {
    background: $color_background-grey;
    border: none;
}

.infoDisableNextVideo {
    margin-top: 10px;
}

.infoContent {
    margin: 0;
    line-height: 1.2em;
    text-align: justify;
}

.loopedInfo {
    padding: $grid;
    text-align: center;
}

.loadMore {
    display: block;
    width: calc(100% - 14px);
    margin: $grid * 0.5 auto $grid;
}
