@import '_config';
@import '_utils';
@import '_mixins';

.container {
    background-color: $color_catskill-white;
    margin-bottom: $grid * 2;
    padding: $grid * 4;
    grid-area: list;
    display: grid;
    gap: $grid * 4;
}

.controls {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-template-areas: 'noResults saveButton';
    align-items: center;
    justify-content: space-between;
}

.saveButton {
    justify-self: end;
    grid-area: saveButton;
}

.noResults {
    display: flex;
    gap: $grid;
    grid-area: noResults;
    font-size: $font-size_small;
    font-weight: $font-weight_semibold;
    letter-spacing: 0.05em;
}

.addVideo {
    background-color: $color_alabaster;
    height: 120px;
    border: 1px solid $color_mercury;
    position: relative;
}

.addButton {
    @include positionAbsolute;
    margin: auto;
}

.staticItems {
    position: relative;
    padding-top: 0;
    padding-bottom: $grid * 4;
}

.spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.enter {
    opacity: 0;
    max-height: 0px;
    padding: 0 16px;
}

.enterActive {
    opacity: 1;
    max-height: 200px;
    padding: 0;

    transition: all 0.8s;
}

.exit {
    opacity: 1;
    max-height: 200px;
}

.exitActive {
    opacity: 0;
    max-height: 0px;
    padding: 0 16px;
    transition: all 0.8s;
}
