$baseUrl: "/";
@import '_utils';

.marker {
    pointer-events: none;
    height: calc(100px + $grid);
    width: 1px;
    background-color: lightgray;
    position: absolute;
    top: 25px;
    font-size: 12px;
    z-index: 1;
}
