$baseUrl: "/";
@import '_config';
@import '_utils';
@include block(OptionsButtonContainer) {
    grid-column: 4/4;
}

@include block(OptionsButton) {
    position: relative;
    padding-right: $grid * 9 !important;
    display: none;
    @include media('>small') {
        display: block;
    }
    &__arrow-icon {
        position: absolute;
        top: 0;
        right: 0;
        border-left: 1px solid $color_cloudy-blue;
    }
    &:hover &__arrow-icon {
        border-color: $color_iris;
    }
    &:active &__arrow-icon {
        color: $color_daisy-bush;
    }
    &__arrow-icon > * {
        height: $grid * 6;
        width: $grid * 6;
        padding: $grid * 1.5;
        transition: transform 0.2s linear;
    }
    &--expanded &__arrow-icon > * {
        transform: rotate(180deg);
    }
    &.af_OptionsButton--expanded,
    &--expanded &__arrow-icon {
        border-color: $color_daisy-bush;
    }
}
