$baseUrl: "/";
@import '_config';

.container {
    margin: -$grid -12px $grid * 3;
    background-color: $color_background-grey;
    grid-area: notifications;
}

.headerContainer {
    padding: 12px 16px;
}
.header {
    font-weight: 600;
    color: $color_ui-w90;
}

.icon {
    vertical-align: middle;
    margin-right: 16px;
}

.notifications {
    margin: auto;
    border-top: 1px solid $color_ui-c30;
    padding: $grid * 2 $grid 0 0;
    width: 95%;
    max-height: 130px;
    padding-bottom: 30px !important;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(450px, auto));
    grid-gap: $grid;

    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: $grid * 2;
}
