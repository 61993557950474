$baseUrl: "/";
@import "_config";

.container {
    display: grid;
    margin-bottom: $grid * 2;
    padding: $grid * 2 $grid $grid * 2;
    max-width: 100%;
    grid-gap: $grid;
    grid-template-columns: 80px repeat(2, max-content);
    align-items: start;
    background-color: $color_background-grey;
}

.error {
    grid-row: 2;
    grid-column: 1 / -1;
}
