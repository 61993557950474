$baseUrl: "/";
@import '_config';
@import '_mixins';

.progress {
    background: transparent;
    transition: background-color 3s cubic-bezier(0.63, 0.08, 1, 0.1);
    @include positionAbsolute($right: null);

    &--transcode {
        background-color: $color-caper;
        transition: width 1s linear;
    }
}
