$baseUrl: "/";
// configuration and basic setup
@import '~react-tippy/dist/tippy.css';
@import "_config";
@import "_utils";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html {
    overflow-y: scroll;
    box-sizing: border-box;
}

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    color: inherit;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
    font-weight: $font-weight_regular;
    letter-spacing: 0;
    box-sizing: border-box;
    background-color: $color_background-grey;
    color: $color_dark-grey;
    font-family: $font-family_SourceSansPro;
    font-size: $font-size_primary;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

a {
    text-decoration: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* Blueprint resets */

ol,
ul {
    margin: 0;
    padding: 0;
}

:focus {
    outline: none;
}

strong {
    font-weight: $font-weight_black;
}

button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

h1,
h2 {
    font-size: 23px;
    font-weight: $font-weight_semibold;
    line-height: 32px;
    margin: 0 0 10px;
    padding: 0;
}

h2 {
    font-size: 18px;
    line-height: 24fpx;
    margin: 0 0 $grid;
}

h5 {
    font-size: 17px;
    font-weight: $font-weight_semibold;
    color: #182026;
    line-height: .9em;
    margin: 0 0 10px;
    padding: 0;
}

hr {
    border: none;
    border-bottom: 1px solid rgba(16, 22, 26, .15);
    margin: 20px 0;
}

p {
    margin: 0 0 10px;
    padding: 0;
}

.heading {
    border-bottom: 1px solid $color-geyser;
    padding-bottom: 5px;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: transparent;
}

::-webkit-scrollbar-track:vertical {
    border-right: 8px solid rgba(0, 0, 0, .07);
}

::-webkit-scrollbar-track:horizontal {
    border-bottom: 8px solid rgba(0, 0, 0, .07);
}

::-webkit-scrollbar-thumb:vertical {
    border-right: 8px solid rgba(0, 0, 0, .25);
}

::-webkit-scrollbar-thumb:horizontal {
    border-bottom: 8px solid rgba(0, 0, 0, .25);
}

::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}

#app main {
    @include media(">tablet") {
        margin: 0 $grid;
    }
}

.redux-icon {
    vertical-align: middle;
}
