$baseUrl: "/";
@import '_config';

.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.message {
    font-size: $font-size_big;
    line-height: 22px;

    b {
        font-weight: 700;
    }
}
