$baseUrl: "/";
@import '_utils';

.label {
    margin-bottom: $grid;
}

.disableNextVideoCheckbox {
    margin-left: auto;
}

.enableNextVideoCheckbox {
    margin-top: 10px;
}
