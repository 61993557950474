$baseUrl: "/";
@import '_config';

.groupContainer {
    border-bottom: 1px solid $color-geyser;
}

.groupContent {
    display: grid;
    grid-auto-flow: row;
    gap: $grid * 2;
    padding: ($grid * 4.5) ($grid * 2);
    background-color: $color_background-grey;
}
