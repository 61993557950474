$baseUrl: "/";
@import "_config";

.label {
    > span {
        text-transform: inherit;
    }
}
.content {
    min-height: 170px;
}

.dialogSection {
    padding-top: $grid * 3;
    padding-bottom: $grid * 3;
}

.assetMetadata {
    margin-bottom: $grid * 2;
}

.title {
    font-size: 23px;
    font-weight: $font-weight_regular;
    line-height: 32px;
    padding: 0;
    margin-top: -$grid;
}
