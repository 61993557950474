$baseUrl: "/";
@import '_config';

.container {
    position: relative;
    overflow: hidden;
}

.image {
    width: 100%;
    z-index: 1;
    position: relative;
    padding: $grid * 2;
}

.dropzone {
    height: 200px;
}

.hidden {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    z-index: 0;
    background: rgba(255, 255, 255, 0.6);

    > * {
        visibility: hidden;
    }
}

.hovered {
    .dropzone {
        z-index: 1;

        > * {
            visibility: visible;
        }
    }
}
