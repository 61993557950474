$baseUrl: "/";
@import '_utils';

.findVideo {
    grid-template-columns: 1fr;
}

.searchButton {
    background-color: $color_purple-heart !important;
    border: 0 !important;
    border-radius: 0;

    &:hover {
        background-color: $color_iris !important;
    }
}

.filtersContainer {
    padding-top: $grid * 2;
}

.filters {
    background-color: $color_background-grey;
    padding: $grid * 3;
}

.newsroomSelect {
    z-index: 101;
}
