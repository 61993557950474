$baseUrl: "/";
@import '_utils';

.videoConfirmation {
    font-size: 1.2em;
}

.videoConfirmation:not(:last-child) {
    margin-bottom: $grid * 3;
}

.languageSelectors {
    display: grid;
    grid-template-columns: min-content;
}

.subtitle {
    display: grid;
    grid-template-columns: auto minmax(180px, max-content);
    grid-column-gap: $grid * 2;
    align-items: baseline;
    padding: $grid * 0.5 0;
}

.leavePreview {
    display: flex;
    align-items: center;
    font-size: $font_size_regular;
}

.optionList{
    padding-top: $grid;
    padding-bottom: $grid*3;
}
