$baseUrl: "/";
@import '_utils';


.optionList{
    display: flex;
    flex-wrap: wrap;

    & > * {
        margin-right: 1em;
    }

    & > :first-child {
        width: 100%;
        margin: 0 0 #{$grid * 2} 0;
    }
}

.burnInLogo{
  padding-top: $grid;  
  padding-bottom: $grid * 3 ;  
}
