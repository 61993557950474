$baseUrl: "/";
@import '_utils';

.container {
    padding-top: $grid * 4;
    border-top: 1px solid $color-geyser;
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-gap: $grid;
}
