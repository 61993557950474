$baseUrl: "/";
@import "_config";

.container {
    &--labeled {
        display: grid;
        grid-template-areas: 'datePicker timePicker' 'notification notification';
        grid-template-columns: auto max-content;
        grid-column-gap: $grid * 2;
    }

    &--compact {
        .dateInput,
        .dateContainer {
            width: 105px;
        }

        .timeInput,
        .timeContainer {
            width: 70px;
        }
    }

    &--small {
        .dateInput,
        .dateContainer {
            width: 120px;
        }

        .timeInput,
        .timeContainer {
            width: 90px;
        }
    }

    &--big {
        .dateInput,
        .dateContainer {
            width: 135px;
        }

        .timeInput,
        .timeContainer {
            width: 105px;
        }
    }
}

.dateInput, .timeInput {
    display: inline-block;
}

.dateInput,
.dateContainer {
    grid-area: datePicker;
}

.timeInput,
.timeContainer {
    grid-area: timePicker;
}

.notification {
    margin: $grid 0;
    grid-area: notification;

    &--error {
        color: $color_guardsman-red;
    }

    &--warning {
        color: $color_brandy_punch;
    }
}
