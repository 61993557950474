$baseUrl: "/";
@import "_utils";
@include block(Clips) {
    &__form {
        display: flex;
        flex-wrap: wrap;
        & > * {
            margin-right: $grid;
            margin-bottom: -$grid;
        }
        & > *:last-child {
            margin-right: 0;
        }
        background-color: $color_background-grey;
        margin-bottom: $grid * 2;
        padding: $grid * 2 $grid $grid * 3;
    }
    &__spinner {
        display: block;
        text-align: center;
        padding: $grid * 2 0;
    }
    &__grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: $grid;
        background-color: $color_background-grey;
        padding: $grid;
    }
    &__original-video {
        border: 1px solid $color_iris;
        box-shadow: none;
    }
    &__heading {
        grid-column-start: 1;
        grid-column-end: -1;
        color: $color_warm-grey;
        font-weight: $font-weight_semibold;
        font-size: 14px;
        display: block;
        margin: $grid * 2 0 $grid;
    }
    &__original-container {
        padding: $grid;
    }
    &__load-more-button {
        grid-column-start: 1;
        grid-column-end: -1;
    }
    &__info {
        background-color: $color_background-grey;
        margin-bottom: $grid * 2;
        padding: $grid * 2;
        font-size: 1.1em;
    }
}
