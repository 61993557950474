$baseUrl: "/";
@import '_config';

.content {
    background: #eee;
    padding: 36px 16px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: $grid $grid * 3;
    grid-template-areas:
        'categories categories'
        'author link'
        'email guid'
        'language ownerName'
        'copyright prependUrl'
        'type type'
        'explicit explicit'
        'adPlacement adPlacement'
        'divider divider'
        'spons airtime'
        'podme podme';
}

.type {
    grid-area: type;
}

.explicit {
    grid-area: explicit;
    margin-top: $grid;
    color: $color-tundora;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
}

.adPlacement {
    grid-area: adPlacement;
    margin-top: $grid * 3;
}

.adPlacementDivider {
    grid-area: divider;
    margin: $grid 0;
}

.spons {
    grid-area: spons;
    border-right: 1px solid $color-geyser;
}

.airtime {
    grid-area: airtime;
}
