$baseUrl: "/";
@import '_config';
@import '_utils';

@include block(header) {
    display: grid;
    margin: $grid $grid $grid * 2;
    grid-column-gap: $grid * 2;
    grid-row-gap: $grid * 2;
    grid-template-columns: max-content max-content 1fr max-content;
    grid-template-areas:
        'logo menu buttons avatar'
        'search search search search';

    @include media('>1140px') {
        grid-template-columns: min-content minmax(max-content, 1fr) minmax(auto, 500px) minmax(max-content, 1fr) min-content;
        margin: $grid * 2 $grid * 2 $grid * 4;
        grid-template-areas: 'logo menu search buttons avatar';
    }
}
