$baseUrl: "/";
@import 'theme';
.icon {
    color: $sru-bluey-purple;
    &--clearable {
        cursor: pointer;
    }
}

:global {
    .react-datepicker {
        font-family: inherit !important;
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--in-range,
    .react-datepicker__day--keyboard-selected {
        background-color: $sru-bluey-purple !important;
        border-radius: $sru-grid * 0.25;
    }
    .react-datepicker__time-list-item {
        height: 16px !important;
    }
    .react-datepicker__day--selected:hover,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--keyboard-selected:hover,
    .react-datepicker__time-list-item--selected:hover {
        background-color: $sru-iris !important;
    }
    .react-datepicker__today-button {
        border-radius: 0 0 4px 4px;
    }
    .react-datepicker__time-list-item--selected {
        background-color: $sru-bluey-purple !important;
    }
    .react-datepicker__time-list {
        padding-right: 0 !important;
    }
}
