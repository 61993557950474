$baseUrl: "/";
@import '_config';

.inlineCollapsible {
    display: inline-block;
    margin-right: $grid * 4;

    &--expanded {
        display: block;
        margin: 0;
    }
}
