$baseUrl: "/";
@import "_utils";

.container {
    border-bottom: 1px solid $color-geyser;
}

.content {
    display: grid;
    gap: $grid * 2;
    grid-template-columns: 1fr 1fr max-content;
    grid-template-rows: repeat(2, max-content);
    grid-template-areas: "seasonLabel episodeLabel ." "seasonField episodeField fillButton";
    padding: $grid * 4 $grid * 2;
    background: $color_background-grey;
}

.seasonLabel {
    grid-area: seasonLabel;
}

.episodeLabel {
    grid-area: episodeLabel;
}

.seasonField {
    grid-area: seasonField;
}

.episodeField {
    grid-area: episodeField;
}

.fillButton {
    grid-area: fillButton;
}
