$baseUrl: "/";
@import '_utils';

.push {
    display: grid;
    grid-template-columns: 1.6fr 1.4fr;
    grid-template-rows: min-content min-content;
    column-gap: $grid * 3;
    margin-top: $grid * 2;
    margin-bottom: $grid * 2;
}

.pushInfo {
    margin-top: $grid * 3;
    grid-column: 1/3;
    text-align: center;

    span {
        margin-left: 10px;
        vertical-align: middle;
    }
}