$baseUrl: "/";
@import '_config';
@import '_utils';

@include block(NewsroomsListSpinner) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    display: grid;
    height: 100vh;
    justify-content: center;
    align-content: center;
}

@include block(NewsroomsListSpinnerInternal) {
    display: flex;
    justify-content: center;
}

@include block(NoAccess) {
    font-size: $font-size_large;
    line-height: $grid * 3;
    text-align: justify;

    &__travolta {
        width: 15vw;
        min-width: max-content;
    }
}

@include block(NewsroomsList) {
    border: 1px solid $color_light-grey;
    max-height: 80vh;
    overflow-y: auto;

    &__search {
        grid-template-columns: 1fr;
    }

    &__element {
        border-bottom: 1px solid $color_white;
        border-top: 1px solid $color-white;

        &:not(:first-child) {
            border-top: 1px solid $color_light-grey;
        }

        &:hover {
            border-top: 1px solid $color_medium-blue;
            border-bottom: 1px solid $color_medium-blue;
        }
    }

    &__link {
        display: block;
        padding: 0 ($grid * 2);
        border: 1px solid $color_white;
        background-color: $color_white;
        color: $color_dark-grey;
        line-height: $grid * 7.5;
        font-size: $font-size_big;

        &:hover {
            text-decoration: none;
            color: $color_medium-blue;
        }
    }

    &__logo {
        height: $grid * 6.5;
        width: $grid * 6.5;
        padding-right: $grid * 2;
        vertical-align: middle;
    }
}
