$baseUrl: "/";
@import "_config";
.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
    display: block;
    margin-top: $grid;
    position: absolute;
    color: $color_ui-w90;
    font-size: $font-size_big;
    line-height: $grid * 4;
    background-color: $color_alabaster;
    border: 1px solid $color_ui-c30;
    border-radius: $grid*0.5;
    z-index: 3;
    box-shadow: 0 0 4px 0 $color_ui-w40;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 16px 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 0 16px;
}

.react-autosuggest__suggestion--highlighted {
    background: $color_mystic;
}

.react-autosuggest__suggestion--focused {
    background-color: #0c7eaf;
    color: #fff;
}

.af_suggestion-row {
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
}

.af_suggestion-row__highlight {
    color: $color_teal-80;
    font-weight: bold;
}

.react-autosuggest__suggestion--focused .af_suggestion-row__highlight {
    color: #120000;
}