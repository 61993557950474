$baseUrl: "/";
@import '_utils';

.toolbar {
    display: grid;
    margin: 0 $grid $grid * 2;
    grid-template-areas: 'duration clear . timeline . scale';
    grid-template-columns: max-content max-content auto max-content auto 205px;
    gap: $grid * 2;

    .clearButton {
        background-color: $color_alabaster;
    }
}

.center {
    display: flex;
    justify-content: center;
    margin-bottom: $grid * 4;
}

.noImages {
    display: flex;
    justify-content: center;
    padding: $grid;
}

.duration {
    width: 105px;
    grid-area: duration;
}

.durationInput {
    background-color: $color_alabaster;
    font-size: $font-size_medium;
    box-shadow: unset;
}

.clear {
    grid-area: clear;
}

.clearButton {
    display: flex;
}

.toggle {
    grid-area: timeline;
}

.scale {
    grid-area: scale;
}

.controls {
    height: 105px;
    overflow: hidden;
}
