$baseUrl: "/";
@import "_config";

.input::placeholder {
  color: $color_placeholder-grey;
}

.container {
  :global {
    .react-autosuggest__suggestions-container {
      max-height: 130px;
      overflow-y: auto;
    }
    .react-autosuggest__suggestions-list {
      padding: 0;
    }
  }
}
