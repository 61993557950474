$baseUrl: "/";
@import '_config';

:export {
    white: $color_white;
}

.form {
    display: grid;

    grid-template-columns: 1fr 2fr;
    grid-column-gap: $grid * 3;

    @include media('<=desktop') {
        grid-row-gap: $grid * 2;
        grid-template-columns: minmax(500px, 1fr);
        grid-template-rows: 0.5fr 0.5fr;
    }
}

.connector {
    grid-template-columns: 1fr;
}

.filters {
    background-color: $color_catskill-white;
    padding-left: 1.2em;

    display: flex;

    align-items: center;
    justify-content: space-between;

    @include media('<=1200px') {
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}

.labels {
    display: grid;
    align-items: center;
    margin-right: $grid * 2;
}

.select {
    min-width: 300px;
}

.type {
    display: flex;
    align-items: center;
    min-width: 410px;
}
