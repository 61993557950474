$sru-grid: 8px;
// http://chir.ag/projects/name-that-color
$sru-white: #fff;
$sru-dark-red: #aa1729;
$sru-tamarillo: #8c1221;
$sru-guardsman-red: #cc0000;
$sru-pomegranate: #e00000;
$sru-punch: #db3737;
$sru-salem: #0f9960;
$sru-brandy_punch: #d9822b;
$sru-dark-grey: #2a373d;
$sru-dark-blue: #005c7a;
$sru-medium-blue: #007da3;
$sru-mid-blue: #1c67b3;
$sru-cloudy-blue: #b0c4cd;
$sru-warm-grey: #757575;
$sru-jagger: #29105c;
$sru-daisy-bush: #421a91;
$sru-bluey-purple: #673ab7;
$sru-purple-heart: #673ab8;
$sru-iris: #7e57c2;
$sru-amethyst: #9575cd;
$sru-teal-80: #2ca6c2;
$sru-ui-c10: #f3f7f9;
$sru-ui-c30: #c7d8df;
$sru-ui-w40: #e0e0e0;
$sru-ui-w90: #424242;
$sru-ui-w100: #212121;
$sru-geyser: $sru-ui-c30;
$sru-gull-gray: #90a4ae;
$sru-mystic: #dbe6eb;
$sru-gallery: #eeeeee;
$sru-alabaster: #fafafa;
$sru-catskill-white: #f3f7fa;
$sru-alto: #e0dede;
$sru-powder-blue: #b4e3ea;
$sru-yellow: #f0ad4e;
$sru-sunflower: #e8c931;
$sru-ronchi: #f0d447;
$sru-anzac: #e2c73f;
$sru-hokey-pokey: #d1b529;
$sru-sahara: #bfa31a;
$sru-old-gold: #c7ae31;
$sru-tower-gray: #afbec5;
$sru-scorpion: #5d5d5d;
