$baseUrl: "/";
@import '_utils';

.field {
    padding: $grid * 2 $grid * 2;
    background: $color_white;
    box-shadow: 0 0 2px 1px $color_gallery;
    transition: background 100ms linear;
    position: relative;
    display: grid;
    gap: $grid;
    grid-template-columns: max-content 45px auto 80px;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
        "startTime stats text buttons"
        "endTime stats text buttons";

    &:hover {
        background: $color_alabaster2;
    }

    &:not(:hover) .buttons {
        display: none;
    }

    input, textarea {
        transition: 100ms box-shadow linear, 100ms border-color linear;
        &:not(:hover):not(:focus) {
            border-color: $color_gallery;
            box-shadow: none;
        }
    }

    textarea:not(:hover):not(:focus) {
        border-color: transparent;
    }

    &-highlighted {
        background-color: $color-aqua-haze;
    }
}

.startTime {
    grid-area: startTime;
    font-weight: 600;
}

.endTime {
    grid-area: endTime;
    font-weight: 600;
}

.startTimeInput,
.endTimeInput {
    width: 120px;
}

.stats {
    grid-area: stats;
}

.text {
    grid-area: text;
}

.textInput {
    height: 100%;
    resize: none;
    white-space: nowrap;
    text-align-last: center;

    &:not(:hover):not(:focus) {
        overflow: hidden;
    }
}

.deleteButton {
    grid-area: buttons;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttons {
    grid-area: buttons;
    display: flex;
    justify-content: center;
    align-items: center;
}

.insertButton {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate3d(-50%, -50%, 0);

    &.last {
        top: unset;
        bottom: 0;
        transform: translate3d(-50%, 50%, 0);
        z-index: 1;
    }
}
