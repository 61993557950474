$baseUrl: "/";
@import '_utils';

.tooltip {
  background-color: $color_alabaster;
  text-align: center;
  border-radius: 3px;
  padding: 5px;
  position: absolute;
  user-select: none;
  z-index: 1;
  font-size: $font-size_small;
  transform: translateX(-50%);
  top: 0;
  filter: drop-shadow(0 0 0.1rem rgba(33, 33, 33, 0.3));

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: $color_alabaster transparent transparent transparent;
  }


  &.marker {
    top: -25px;
  }
}
