$baseUrl: "/";
@import '_utils';

.advanced {
    display: block;
    min-height: 80px;
}

.advanced > * {
    margin-bottom: $grid * 2;
}

.advanced > :not(:first-child) {
    max-height: 100px;
    transition:
        max-height 0.3s ease-in 0.15s,
        margin-top 0.3s ease-in 0.15s,
        margin-bottom 0.3s ease-in 0.15s,
        opacity 0.15s ease-out 0.3s;
}

.advanced .qualityOption--selected {
    transition:
        max-height 0.3s ease-in 0.15s,
        margin-top 0.3s ease-in 0.15s,
        margin-bottom 0.3s ease-in 0.15s,
        padding-left 0.3s ease-in,
        opacity 0.15s ease-out 0.3s;

    :first-child {
        transition: 0.2s opacity 0.2s ease-out;
    }
}

.advancedContainer {
    max-height: 1000px;
    opacity: 1;
    transition:
        max-height 1s ease-out,
        opacity 0.2s ease-out 0.2s;
}

.advancedContainer.advancedContainer--expanded {
    transition:
        max-height 1s ease-out,
        opacity 0.1s ease-out 0.3s;
}

.advancedContainer.advancedContainer--hidden {
    max-height: 0;
    opacity: 0;
    transition:
        max-height 0.3s ease-out,
        opacity 0.1s ease-out;
    pointer-events: none;
}

.advanced:not(.advanced--expanded) > :not(:first-child):not(.qualityOption--selected) {
    margin-top: 0;
    margin-bottom: 0;
    max-height: 0;
    opacity: 0;
    transition:
        max-height 0.3s ease-out,
        margin-top 0.3s ease-out,
        margin-bottom 0.3s ease-out,
        opacity 0.1s ease-out;
}

.advanced:not(.advanced--expanded) .qualityOption--selected {
    padding-left: 0;
    cursor: auto;
    transition:
        max-height 0.3s ease-out,
        margin-top 0.3s ease-out,
        margin-bottom 0.3s ease-out,
        padding-left 0.3s ease-in 0.1s,
        opacity 0.1s ease-out;

    :first-child {
        opacity: 0;
        pointer-events: none;
        transition-delay: 0.1s;
    }
}

.advanced:not(.advanced--touched) .qualityOption--selected {
    transition: none;

    :first-child {
        transition: none;
    }
}

.dvrLength {
    margin-top: $grid;
    margin-left: 30px;
}

.optionList {
    display: flex;
    flex-wrap: wrap;
    margin-top: #{$grid * 2};

    & > * {
        margin-right: 1em;
    }

    & > :first-child {
        width: 100%;
        margin: 0 0 #{$grid * 2} 0;
    }
}

.paywallOption {
    min-width: 160px;
    margin-top: -2px;
}

.cuttingroomChannelOption {
    min-width: 290px;
    margin-top: -2px;
}

.additionalOptions {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
}

.livePreview {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.burnInLogo {
    padding-top: $grid;
}

.burnInLogoWarning {
    display: flex;
    align-items: center;
    height: $grid * 3;
    margin-left: -100px;
}
