$baseUrl: "/";
@import '_config';
@import '_utils';

.spinner {
    display: grid;
    justify-content: center;
    align-content: center;
}

.live-screen {
    display: grid;
    grid-gap: $grid * 2;
    grid-template-columns: minmax(500px, 6fr) minmax(500px, 5fr);

    @include media('<=desktop') {
        grid-template-columns: minmax(500px, 1fr);
    }
}

.live-and-was-live > :not(:first-child) {
    margin-top: $grid * 6;
}

.personal-lives {
    margin-bottom: $grid * 3;
    display: grid;
    grid-gap: $grid;
    grid-template-columns: repeat(auto-fit, minmax(550px, 5fr));
}

.current-live {
    margin-bottom: $grid * 4;
}

.text {
    text-align: center;
    margin-top: $grid * 3;
    font-size: $font-size_big;
}
