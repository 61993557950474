$baseUrl: "/";
@import 'theme';
.Label {
    display: block;
    color: $sru-ui-w90;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    height: 12px;
    width: max-content;
}
