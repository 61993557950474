$baseUrl: "/";
@import '_config';

.header {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto;
}

.content {
    display: grid;
    grid-auto-flow: row;
    gap: $grid * 2;
    padding-top: $grid * 2;
}

.addButton.addButton.addButton {
    justify-self: right;
    padding: 0;
}

.spinner {
    margin: -4px 0;
}
