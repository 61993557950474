$baseUrl: "/";
@import 'theme';
.Dropdown {
    position: relative;
    overflow: visible;
    height: 48px;
    max-width: max-content;
    &--small {
        height: $sru-grid * 4;
    }
    &--compact {
        height: $sru-grid * 3;
    }
    &--big .ArrowIcon {
        height: $sru-grid * 4;
        width: $sru-grid * 4;
    }
    &--small .ArrowIcon {
        height: $sru-grid * 3;
        width: $sru-grid * 3;
    }
    &--compact .ArrowIcon {
        height: $sru-grid * 2.5;
        width: $sru-grid * 2.5;
    }
    &--expanded .ArrowIcon {
        transform: rotate(0deg);
    }
    &--expanded .MenuItemsList {
        display: block;
    }
    &--small .ItemsContainer > .MenuItem,
    &--compact .ItemsContainer > .MenuItem {
        padding: $sru-grid $sru-grid * 2;
    }
}

.ArrowIcon {
    transform: rotate(180deg);
    transition: transform 0.2s linear;
}

.MenuItemsList {
    display: none;
    position: absolute;
    top: calc(100% + 2px);
    right: 0;
    width: 100%;
    min-width: max-content;
    z-index: 20; // &--arrow {
}

.ItemsContainer {
    box-shadow: 0 0 $sru-grid * 0.5 0 $sru-ui-w40;
    background: $sru-alabaster;
    border: 1px solid $sru-ui-c30;
    border-radius: $sru-grid * 0.5;
    overflow: hidden;
}

.MenuArrow {
    width: 0;
    position: absolute;
    top: -20px;
    height: 0;
    z-index: 0;
    border-color: transparent transparent $sru-ui-c30 transparent;
    border-style: solid;
    border-width: 10px;
    margin: 0 auto;
    left: 0;
    right: 0;
    &:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent transparent $sru-alabaster transparent;
        border-width: 10px;
        position: absolute;
        left: -10px;
        top: -8px;
    }
}

.MenuItem {
    position: relative;
    z-index: 0;
    width: 100%;
    border: 0;
    color: $sru-ui-w90;
    display: block;
    font-size: 16px;
    padding: $sru-grid * 2;
    text-align: left;
}

.MenuItem:not(:last-child) {
    border-bottom: 1px solid $sru-ui-c30;
}

.MenuItem:hover {
    background: $sru-mystic;
    cursor: pointer;
}
