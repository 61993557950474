$baseUrl: "/";
@import 'theme';
.Option {
    display: block;
    color: $sru-ui-w90;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    height: 12px;
    width: max-content;
    background: #fff;
    text-transform: none;
    border: 1px solid $sru-cloudy-blue;
    display: flex;
    height: 24px;
    color: $sru-bluey-purple;
    border-radius: 2px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    &--clickable {
        cursor: pointer;
        &:hover {
            border-color: $sru-iris;
            background-color: $sru-ui-c10;
            color: $sru-bluey-purple;
        }
        &:active {
            background-color: $sru-ui-c10;
            color: $sru-jagger;
            border-color: $sru-jagger;
        }
    }
    &Icon {
        display: flex;
        padding: 0 4px;
        & > * {
            align-self: center;
        }
    }
    &Icon--clickable {
        cursor: pointer;
        &:hover {
            color: #fff;
            background: $sru-bluey-purple;
        }
        &:first-child {
            border-radius: 2px 0 0 2px;
        }
        &:last-child {
            border-radius: 0 2px 2pxx;
        }
    }
    &Label {
        padding: 0 4px;
    }
}
