$baseUrl: "/";
@import '_utils';
@import '_config';

.container {
    display: grid;
    grid-template-columns: 1.6fr 1.4fr;
    grid-gap: $grid * 2;
}

.input,
.container {
    &:not(:last-child) {
        padding-bottom: $grid * 3;
    }
}

.dialogTriggerButton {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.dialogTriggerStatus {
    margin-top: 15px;
}
