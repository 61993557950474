$baseUrl: "/";
@import '_utils';

.container {
    padding: $grid;
}

.header {
    margin-bottom: $grid * 1.5;
    font-weight: $font-weight_semibold;
    text-transform: uppercase;
}

.list {
    display: grid;
    grid-auto-flow: row;
    gap: $grid * 0.5;

    li {
        display: flex;
        align-items: center;
        gap: $grid;
    }

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        border: 1px solid $color_cloudy-blue;
        border-radius: 3px;
        color: $color_bluey-purple;
        font-weight: $font-weight_semibold;
    }
}

.helpIcon {
    color: $color_warm-grey;
}
