$baseUrl: "/";
@import '_utils';

.instruction {
    font-size: 16px;
    text-align: center;
}

.userCard {
    font-size: 16px;
    margin-top: $grid * 3;
    border: 1px solid $color_ui-c30;
    box-shadow: 0 0 4px 1px $color_gallery;

    background: $color_white;
    border-radius: 4px;
}

.initials {
    grid-area: initials;
    align-self: center;
    justify-self: end;
}

.username {
    grid-area: username;
    font-weight: 600;

    &:hover {
        cursor: pointer;
    }
}

.email {
    grid-area: email;
    align-self: center;
}

.suggestion {
    grid-area: anotherAccount;
    margin-top: $grid;

    &:hover {
        cursor: pointer;
    }
}

.margin {
    margin-top: $grid;
}
.anotherAccount {
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-template-areas: 'defaultAvatar anotherAccount';
    grid-column-gap: $grid * 3;
    padding: $grid * 2 $grid * 2.5;
    transition: background-color 150ms;

    &:hover {
        background-color: $color_catskill-white;
        cursor: pointer;
    }
}

.defaultAvatar {
    grid-area: defaultAvatar;
    color: rgb(182, 182, 182);

    path {
        stroke-width: 1;
    }
}

.knownUser {
    display: grid;
    grid-template-rows: 0.5fr 0.5fr;
    grid-template-columns: 1fr 5fr;
    grid-template-areas: 'initials username' 'initials email';
    align-items: center;
    justify-content: center;

    grid-column-gap: $grid * 3;
    grid-row-gap: 4px;
    transition: background-color 150ms;

    padding: $grid * 2.5;
    padding-top: $grid * 3;

    &:hover {
        background-color: $color_catskill-white;
        cursor: pointer;
    }
}

.text {
    align-self: center;
}
