$baseUrl: "/";
@import "_config";
@import "_utils";

@include block(player) {
    background-color: #f3f7f9;
    position: relative;
    text-align: center;

    &__wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__poster {
        max-width: 100%;
    }
}

