$baseUrl: "/";
@import '_config';

:export {
    white: $color_white;
}

.container {
    padding: 15px $grid 30px 20px;
    background: $color_background-grey;
}
.search {
    padding: $grid 0 40px $grid;
}
.list {
    max-height: 280px;
    overflow-x: hidden;
    overflow-y: auto;
}
.playlist {
    cursor: move;
    font-size: 13px;
    margin-bottom: $grid;
    margin-right: $grid;
    border: 1px solid $color_mystic;
}

.spinner {
    display: flex;
    margin: 150px auto 0 auto;
    justify-content: center;
}
