$baseUrl: "/";
@import '_config';

.live-video-card {
    grid-gap: $grid * 4;
    padding: $grid * 2;

    &--to-publish {
        background-color: #fafafa;
    }
}

.enter {
    opacity: 0;
    max-height: 0px;
    padding: 0 16px;
}

.enterActive {
    opacity: 1;
    max-height: 200px;
    padding: 16px;
    transition: all 0.8s;
}

.exit {
    opacity: 1;
    max-height: 200px;
}

.exitActive {
    opacity: 0;
    max-height: 0px;
    padding: 0 16px;
    transition: all 0.8s;
}

.no-results {
    text-align: center;
    font-size: $grid * 2;
    margin-top: $grid * 2;
}

.camera-icon {
    opacity: 0.3;
}

.error-info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: $grid;
}

.retry-button {
    margin-left: $grid;
}
