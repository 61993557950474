$baseUrl: "/";
@import "_config";

.container {
    display: flex;
    justify-content: space-between;
    gap: $grid * 4;
    grid-column: 1 / -1;
    margin: -$grid (-$grid * 1.5) ($grid * 4);
    padding: $grid * 4;
    background-color: $color_catskill-white;

    h1 {
        margin-bottom: $grid * 3;
    }
}

.form {
    min-width: 160px;
}

.submitButton {
    align-self: center;
    justify-self: end;
}
