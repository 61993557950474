$baseUrl: "/";
@import 'theme';

.container {
    display: flex;
    font-size: 14px;
    align-items: center;
    column-gap: 10px;
    font-weight: 600;
    color: $sru-scorpion;
}

.label {
    display: inherit;
}

.offLabel:has(+ .label > .toggle),
.label:has(.toggle:checked) + .onLabel {
    color: $sru-bluey-purple;
}

.offLabel:has(+ .label > .toggle:checked) {
    color: $sru-scorpion;
}

.toggle {
    appearance: none;
    width: 50px;
    height: 26px;
    position: relative;
    border-radius: 20px;
    transition: background-color 400ms;
    transform: translateX(0);
    background-color: $sru-alabaster;

    border: 1px solid $sru-bluey-purple;

    &:hover {
        cursor: pointer;
    }

    &:before {
        content: '';
        position: absolute;
        width: 18px;
        height: 18px;
        top: 3px;
        left: 3px;
        border: 1px solid $sru-bluey-purple;
        border-radius: 50%;
        background-color: $sru-alabaster;
        transition: transform 500ms ease;
    }

    &:after {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        background-color: $sru-bluey-purple;
        border-radius: 50%;
        top: 4px;
        left: 4px;
        border: 1px solid $sru-alabaster;
        transition: transform 500ms ease;
    }

    &:disabled {
        border: 1px solid $sru-tower-gray;
        cursor: no-drop;

        &:before {
            border: 1px solid $sru-geyser;
        }

        &:after {
            background-color: $sru-tower-gray;
        }
    }

    &:checked {
        background-color: $sru-bluey-purple;
        border: 1px solid $sru-bluey-purple;

        &:before {
            border: $sru-alabaster;
            transform: translateX(24px);
        }

        &:after {
            background-color: $sru-alabaster;
            border: 1px solid $sru-bluey-purple;
            transform: translateX(24px);
        }
    }
}
