$baseUrl: "/";
@import '_utils';
@import '_mixins';

.wrapper {
    width: 100%;
    padding-top: calc(254 / 452 * 100%);
    color: $color-dove-gray;
    background-color: $color-gray-nurse;
    position: relative;
}

.content {
    @include positionAbsolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropzone {
    aspect-ratio: 1.6;
    min-height: 110px;
    border: 1.5px dashed $color-lavender;
    border-radius: $grid;
    position: relative;
    z-index: 10;
    display: grid;
    justify-content: center;
    align-content: center;

    &:hover {
        background-color: $color_alabaster2;
    }
}
