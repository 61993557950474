$baseUrl: "/";
@import "_utils";

.wrapper {
    display: grid;
    grid-gap: 10px;
    align-items: start;
    grid-template-columns: repeat(3, max-content);
    margin-bottom: $grid;
    max-width: 370px;
}
