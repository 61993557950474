$baseUrl: "/";
@import '_utils';

.wrapper {
    --container-margin: #{$grid};

    margin: 0 var(--container-margin);
    position: relative;

    &-resize {
        cursor: col-resize;
    }

    &-move {
        cursor: grabbing;
    }
}

.container {
    position: relative;
    padding: var(--container-margin) 0;
    overflow-x: scroll;
    overflow-y: hidden;
    display: grid;
    grid-template-columns: var(--container-margin) auto var(--container-margin);
    grid-template-areas: "paddingLeft content paddingRight";

    &::after,
    &::before {
        content: "";
        height: 100%;
        width: var(--container-margin);
    }

    &::after {
        grid-area: paddingRight;
    }

    &::before {
        grid-area: paddingLeft;
    }
}

.content {
    display: grid;
    grid-area: content;
    grid-template-rows: min-content auto;
    grid-template-areas: "ruler" "primary";
}
