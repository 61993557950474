$baseUrl: "/";
@import '_utils';

.instruction {
    font-size: 16px;
    padding-bottom: $grid * 4;
}

.element {
    border-width: 0;
    margin-bottom: $grid;
    list-style-type: none;
}

.providers {
    min-width: 320px;
}

.button {
    border: 1px solid $color_cloudy-blue;
    width: 100%;
    background: $color_white;
    line-height: 1;
    height: 90px;

    transition: border 150ms;

    &:hover {
        border-color: $color_medium-blue;
    }

    img {
        display: block;
        margin: auto;
    }
}
