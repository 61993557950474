$baseUrl: "/";
@import '_config';
@import '_utils';

.list {
    display: flex;
    flex-grow: 1;
}

.item {
    margin-right: 2px;

    &--rightHalf {
        margin-right: 0;
        margin-left: 0;
    }
}

.container {
    display: grid;
    grid-template-columns: 2fr 0.2fr;
    grid-gap: 2px;
}

.levels {
    display: grid;
    grid-template-rows: 1fr;
}

.value {
    color: $color_teal_blue;
    font-weight: $font-weight_semibold;
    text-transform: uppercase;
    min-width: 3rem;
    text-align: center;
}

.button {
    width: 100%;
    cursor: pointer;

    height: $grid;
    background-color: $color_cornflower_blue;
    position: relative;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;

    &--half {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }

    &:hover,
    &--half:hover {
        background-color: $color_bluey-purple;
    }
    &--active,
    &--half:active {
        background-color: $color_bluey-purple;
    }
}
