$baseUrl: "/";
@import '_config';

.field {
    display: grid;
    grid-auto-flow: row;
    gap: $grid;
}

.removeButton {
    justify-self: right;
}

.input {
    width: 100%;
}

.tooltipClickInfo {
    display: block;
    margin-top: $grid;
    font-size: $font-size_small;
}
