$baseUrl: "/";
@import "_utils";

.container {
  display: flex;
  justify-content: space-around;
  margin-bottom: $grid * 2;

}
.navigation-button {
  color: $color_iris;
  font-size: $grid * 2;
  font-weight: $font-weight_semibold;
  padding: $grid * 2;
  &--active {
      border-bottom: 4px solid $color_purple-navigation;
  }
}