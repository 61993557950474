$baseUrl: "/";
@import '_config';

.form {
    padding: $grid * 1.5;

    @include media('>tablet') {
        padding: $grid * 3 $grid * 3.5;
    }
}

.fieldsContainer {
    display: grid;
    grid-template-columns: auto;
    grid-template-areas: 'content' 'additional' 'other' 'preview';
    gap: $grid * 2;

    @include media('>tablet') {
        grid-template-columns: auto minmax(35%, min-content);
        grid-template-areas:
            'content additional'
            'preview preview'
            'other other';
    }
    @include media('>1200px') {
        grid-template-columns: auto 245px minmax(40%, min-content);
        grid-template-rows: auto 1fr;
        grid-template-areas:
            'content additional preview'
            'other additional preview';
    }
    @include media('>desktop') {
        gap: $grid * 4;
    }
}

.content {
    grid-area: content;
    display: grid;
    gap: $grid * 3;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
}

.additional {
    grid-area: additional;
    display: grid;
    gap: $grid * 3;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
}

.other {
    grid-area: other;

    & > * {
        border: 0;
    }

    & > *:not(:last-child) {
        border-bottom: 1px solid $color-geyser;
    }
}

.preview {
    grid-area: preview;
    max-width: calc(100vw - 24px);
}
