$baseUrl: "/";
@import "_utils";
@import "~@schibsted-svp/react-ui/lib/es/styles/theme";

$circle-size: 1.2em;
$circle-padding: 2px;
$circle-outline: 1px;

.Radio {
    margin-right: 1.2rem;
    display: inline-block;
    line-height: 1.2rem;
    cursor: pointer;
    user-select: none;
    vertical-align: top;

    &:hover &__input:not(:disabled) {
        border-color: $sru-iris;
    }

    &--disabled {
        cursor: not-allowed;
    }

    &--error &__input {
        border-color: $sru-dark-red;
    }

    &__input {
        appearance: none;
        display: inline-block;
        user-select: none;
        position: relative;
        border: $circle-outline solid $sru-jagger;
        border-radius: 50%;
        width: $circle-size;
        height: $circle-size;
        vertical-align: text-bottom;
        cursor: pointer;
    }

    &__input:checked::after {
        content: '';
        border-radius: 50%;
        background-color: $sru-jagger;
        position: absolute;
        top: $circle-padding;
        right: $circle-padding;
        bottom: $circle-padding;
        left: $circle-padding;
    }

    &__input:disabled {
        border-color: $sru-geyser;
        cursor: not-allowed;
    }

    &__input:disabled:checked::after {
        background-color: $sru-geyser;
    }

    &--labelled &__input {
        margin-right: 0.4rem;
    }
}

