$baseUrl: "/";
@import "_utils";

.logo {
    max-width: 100%;
    background-color: black;
}

.tooltip {
    max-width: 300px;
}
