$baseUrl: "/";
@import "_config";
@import '_utils';
.wrapper {
    color: $color_guardsman-red;
    background: $color_wisp-pink;
    padding: $grid * 2 $grid * 2 $grid * 2 $grid * 6;
    font-size: $font-size_big;
    letter-spacing: 1.83px;
    line-height: 20px;
    position: relative;
}
.clipboard {
    margin-top: 1rem;
}
.icon {
    position: absolute;
    transform: scale(1.3);
    left: 20px;
    top: 18px;
}
.confirm {
    color: $color_green;
}
