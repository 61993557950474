$baseUrl: "/";
@import "_utils";

.dialog {
    margin-top: 0;
    margin-bottom: 0;
    overflow: initial;
    position: relative;
    max-width: 645px;
    font-size: $font-size_big;
}

.header {
    font-weight: $font-weight_semibold;
    line-height: 28px;
    color: $color-mine-shaft;
    font-size: $font-size_super-large;
    position: sticky;
    top: 0;
    border-top-left-radius: $grid * 0.5;
    border-top-right-radius: $grid * 0.5;
    z-index: 1;
}

.tabsContainer {
    padding-bottom: 0;
}
