$baseUrl: "/";
@import '_config';
@import '_functions';

:export {
    silver2: $color_silver2;
}

.playlist {
    font-size: #{$baseEM}px;
    height: pxToEm(170);
    border-top: 2px solid $color_mystic;
    padding: pxToEm(20) pxToEm(20) pxToEm(13) pxToEm(10);
    display: grid;
    grid-template: 'info items controls' / pxToEm(360) auto max-content;
    grid-column-gap: pxToEm(15);
    background: $color_white;

    &--compact {
        height: pxToEm(145);
        padding: pxToEm(10) pxToEm(20) pxToEm(10) pxToEm(30);
        grid-template: 'info items controls' / pxToEm(270) auto max-content;
    }

    &:hover {
        background-color: $color_alabaster2;
    }
}

.deleteButton {
    display: none;

    @at-root .playlist:not(.playlist--not-hover):hover & {
        display: block;
    }
}

.info {
    grid-area: info;
    display: grid;
    grid-template: 'name name' pxToEm(55) 'labels labels' pxToEm(25) 'length duration' auto / pxToEm(70) auto;

    &--compact {
        grid-template: 'name name' pxToEm(55) 'labels labels' pxToEm(17) 'length duration' auto / pxToEm(70) auto;
    }
}
.infoName {
    grid-area: name;
    font-size: pxToEm(18);
    font-weight: 700;
    padding-left: pxToEm(3);
    padding-top: pxToEm(20);
}
.infoLength {
    grid-area: length;
    padding-top: pxToEm(24);
    padding-left: pxToEm(8);
    svg {
        margin-right: pxToEm(15);
        width: pxToEm(22);
        height: pxToEm(22);
    }
}

.infoDuration {
    grid-area: duration;
    font-size: 1em;
    padding-top: pxToEm(24);
    padding-left: pxToEm(35);
    svg {
        width: pxToEm(22);
        height: pxToEm(22);
        margin-right: pxToEm(6);
    }
}

.playlistItems {
    grid-area: items;
    display: grid;
    grid-template-columns: repeat(auto-fit, pxToEm(143));
    grid-template-rows: 100%;
    grid-gap: pxToEm(5);
    overflow: hidden;

    &--loading {
        grid-template-columns: 100%;
    }
}

.spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.labels {
    display: flex;
}

.label {
    grid-area: labels;

    width: min-content;
    height: $grid * 3;
    padding: 2px $grid * 2;
    color: $color-dove-gray2;
    background-color: $color_mystic2;

    font-family: arial;
    font-size: pxToEm(14);
    line-height: 20px;

    white-space: nowrap;
    margin-right: $grid;

    &--compact {
        padding: 0 10px;
        height: 19px;
        margin-right: $grid * 0.5;
        line-height: 19px;
    }
}

.playlistItemsList {
    grid-area: items;
    display: grid;
    grid-template-columns: repeat(auto-fit, pxToEm(286));
    grid-template-rows: 100%;
    grid-gap: pxToEm(5);
    overflow: hidden;

    &--loading {
        grid-template-columns: 100%;
    }
}

.controls {
    grid-area: controls;
    align-self: center;
}
