$baseUrl: "/";
@import '_config';
@import '_utils';

.container {
    position: relative;
}

.counter {
    position: absolute;
    right: -$grid * 0.5;
    bottom: -$grid * 0.5;
    background-color: $color_red-violet;
    border-radius: 50%;
    height: 28px;
    width: 28px;
    text-align: center;
    line-height: 28px;
    color: $color_white;
    transform: scale(0);
    transition: transform 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28);

    &--red {
        background-color: $color_guardsman-red;
    }
}

.visible {
    transform: scale(1);
}

.avatar {
    margin-left: $grid;
    grid-area: avatar;
    height: $grid * 6;
    width: $grid * 6;
    display: grid;
    cursor: pointer;
    align-content: center;
    justify-content: center;
    max-width: none;
}

.item:not(:last-child) {
    border: 0px;
}

.item:first-child {
    padding-top: $grid * 3 !important;
}

.item--padding-top {
    margin-top: $grid;
    padding-top: $grid !important;
}

.item:first-child:hover {
    margin-top: $grid * 2;
    padding-top: $grid !important;
}

.item:last-child:hover {
    margin-bottom: $grid * 2;
    padding-bottom: $grid !important;
}

.item:last-child {
    padding-bottom: $grid * 3 !important;
}

.static-item:hover {
    background: none;
}

.bottom-line {
    border-bottom: 1px solid $color_ui-c30 !important;
}

.dropdownArrow {
    margin-right: 0;
    left: auto;
    right: 20px;
}

.full-width {
    display: block;
    width: 100%;
}
