$baseUrl: "/";
@import '_utils';

.wrapper {
    position: relative;
    margin: 0 $grid;
}

.container {
    position: relative;
    overflow-x: scroll;
    display: grid;
    padding: $grid 0;
    user-select: none;
    grid-template-rows: min-content min-content;
    grid-template-columns: $grid min-content auto $grid;
    grid-template-areas: ". timeline . ."
                         ". thumbnailList thumbnailListRail paddingRight";

    &.create,
    &.resize {
        &,
        & .selector {
            cursor: col-resize;
        }
    }

    &.move {
        &,
        & .selector {
            cursor: grabbing;
        }
    }

    // draw thumbnail list rail when is too short
    &::before {
        content: "";
        display: block;
        grid-area: thumbnailListRail;
        background-color: $color_ui-c10;
    }

    // avoid skipping right padding in element with overflow set to scroll
    &::after {
        content: "";
        grid-area: paddingRight;
    }

    &.active {
        .slider:hover:not(.selected) {
            cursor: col-resize;
        }
    }
}

.thumbnailList {
    display: flex;
    grid-area: thumbnailList;
    background-color: $color_ui-c10;
}

.thumbnail {
    pointer-events: none;
    flex-shrink: 0;
    display: inline-block;
}

.slider {
    position: relative;
    display: grid;
    grid-area: thumbnailList;
    grid-template-areas: "leftTrimShape selector rightTrimShape";

    &:not(.selected) {
        .leftTrimShape,
        .rightTrimShape,
        .selector {
            visibility: hidden;
        }
    }
}

.leftTrimShape {
    background-color: $color-black-alpha05;
    grid-area: leftTrimShape;
}

.rightTrimShape {
    background-color: $color-black-alpha05;
    grid-area: rightTrimShape;
}

.selector {
    grid-area: selector;
    position: relative;
    z-index: 10;

    &:hover {
        cursor: grab;
    }

    &::before,
    &::after {
        position: absolute;
        content: "";
        display: block;
        height: $grid * 0.25;
        width: 100%;
        background-color: $color_bluey-purple;
    }

    &::before {
        top: -#{$grid * 0.25};
    }

    &::after {
        bottom: -#{$grid * 0.25};
    }
}

@mixin marker {
    position: absolute;
    display: flex;
    gap: 1px;
    top: -#{$grid * 0.25};
    bottom: -#{$grid * 0.25};
    width: $grid;
    background-color: $color_bluey-purple;
    align-items: center;
    justify-content: center;
    cursor: col-resize;

    &::before,
    &::after {
        content: "";
        display: block;
        width: 1px;
        height: $grid * 3;
        background-color: $color_white;
        pointer-events: none;
    }
}

.leftMarker {
    @include marker;
    left: -$grid;
    border-radius: #{$grid * 0.25} 0 0 #{$grid * 0.25};
}

.rightMarker {
    @include marker;
    right: -$grid;
    border-radius: 0 #{$grid * 0.25} #{$grid * 0.25} 0;
}

.timeMarker {
    pointer-events: none;
    height: calc(54px + $grid);
    width: 1px;
    background-color: lightgray;
    position: absolute;
    top: 25px;
    font-size: 12px;

    &:not(.visible) {
        visibility: hidden;
    }
}

.timeTooltip {
    background-color: $color_alabaster;
    text-align: center;
    border-radius: 3px;
    padding: 5px;
    position: absolute;
    user-select: none;
    z-index: 1;
    font-size: $font-size_small;
    transform: translateX(-50%);
    top: 0;
    filter: drop-shadow(0 0 0.1rem rgba(33, 33, 33, 0.3));

    &:not(.visible) {
        visibility: hidden;
    }

    &.current {
        top: -25px
    }

    &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: $color_alabaster transparent transparent transparent;
    }
}
