$baseUrl: "/";
@import '_config';
@import '_utils';

.warningPrimary button {
    background-color: $color_sunflower;

    &:hover {
        background-color: $sru-ronchi;
    }
}

.warningStandard button {
    color: $sru-sahara !important;

    &:hover {
        border: 1px solid $color_sunflower !important;
    }
}
