$baseUrl: "/";
@import 'theme';
.Checkbox {
    margin-right: 1.2rem;
    display: inline-block;
    line-height: 1.2rem;
    cursor: pointer;
    user-select: none;
    vertical-align: top;

    &:hover .input:not(:disabled) {
        border-color: $sru-iris;
    }
}

.disabled {
    cursor: not-allowed;
}

.error .input {
    border-color: $sru-dark-red;
}

.highlighted .input {
    margin: -1px;
    margin-right: 0.4rem;
    border: 2px solid $sru-yellow;

    &:checked {
        border-color: $sru-yellow;
    }
}

.input {
    appearance: none;
    display: inline-block;
    margin-right: 0.4rem;
    padding: 0.5em;
    line-height: 10px;
    border: 1px solid $sru-geyser;
    border-radius: 3px;
    vertical-align: -0.15em;
    background: $sru-white
        url("data:image/svg+xml;dataset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20'%3E%3Cpath d='M3,9l4,3l5,-9' stroke='%23FFF' stroke-width='1.5' stroke-linejoin='round' fill='none'/%3E%3C/svg%3E")
        no-repeat;
    background-size: 130% 130%;
    transition: background-color 100ms ease-out, border-color 100ms ease-out;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    position: relative;
    &:active,
    &:checked:active {
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 1px 3px rgba(0, 0, 0, 0.1);
    }
    &:checked {
        border-color: transparent;
        color: $sru-white;
        background-color: $sru-jagger;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 -15px 10px -12px rgba(0, 0, 0, 0.05),
            inset 15px 10px -12px rgba(255, 255, 255, 0.1);
    }
    &:disabled {
        border-color: $sru-geyser;
        background-color: $sru-geyser;
        cursor: not-allowed;
        color: $sru-gull-gray;
    }
    &:disabled:not(:checked) {
        background-image: none;
    }
}
