$baseUrl: "/";
@import 'theme';
.InputError {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    color: $sru-dark-red;
    padding: $sru-grid 0 0;
    display: block;
    text-transform: none;
    text-align: left;
}
