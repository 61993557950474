$baseUrl: "/";
@import "_utils";

@include block(Moodoji) {
    display: inline-block;
    position: relative;

    .emoji-mart-emoji > span {
        // get rid of empty space below the icon
        vertical-align: middle;
    }

    &--tooltip:after {
        pointer-events: none;
        transition: opacity 0.2s ease-in-out;
        padding: .2em .4em;
        display: inline-block;
        opacity: 0;
        content: attr(data-tooltip);
        position: absolute;
        margin-top: $grid * 0.5;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        background: rgba(52, 58, 62, .9);
        border-radius: 2px;
        color: $color-white;
    }

    &--tooltip:hover:after {
        opacity: 1;
    }
}
